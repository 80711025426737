import axios from "axios"

const domainName = window.location.href.split('.').slice(-2, -1)[0]
// const domainName = 'http://localhost.credityantra.com'.split('.').slice(-2, -1)[0]
let baseUrl =  process.env.REACT_APP_BASE_URL
if(domainName==='credityantra'){
    baseUrl= process.env.REACT_APP_BIJAY_BASE_URL
}
console.log(domainName, 'domain')
const axiosInstance = axios.create({
    baseURL : baseUrl
});

export default axiosInstance