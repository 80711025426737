import React from 'react'
import Card from './Card/Card'
import eLoan from '../../assets/images/e-loan.png'
import pLoan from '../../assets/images/p-loan.png'
import vLoan from '../../assets/images/v-loan.png'
import mLoan from '../../assets/images/m-loan.png'
import tLoan from '../../assets/images/t-loan.png'
import './index.css'

export default function LoanCards({handleLoanTypeForm, clientId}) {
  return (
    <>
    <div className='l-c-container'>
      <Card loanType= {clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Consumer Loan': 'Education Loan'} handleLoanTypeForm={handleLoanTypeForm} image={eLoan}/>
      {clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <>
      <Card loanType='Personal Loan' handleLoanTypeForm={handleLoanTypeForm}  image={pLoan}/>
      <Card loanType='Vehicle Loan' handleLoanTypeForm={handleLoanTypeForm} image={vLoan}/>
      </>
        }
    </div>
    {clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <div className='l-c-container'>
    <Card loanType='Mortgage Loan' handleLoanTypeForm={handleLoanTypeForm} image={mLoan}/>
    <Card loanType='Term Loan' handleLoanTypeForm={handleLoanTypeForm} image={tLoan}/>
  </div>}
  </>
  )
}
