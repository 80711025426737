import Sidebar from "../Sidebar"
import Topbar from "../Topbar"
import { useState, useEffect } from 'react';
import search from "../../assets/images/search.png"
import jwt_decode from 'jwt-decode';
import PaginationTable from "../status/PaginationTable";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axiosInstance from "../../helpers/axios";
import edit from "../../assets/images/edit.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ManageProducts from "./ManageProducts";
import {userActions} from '../../actions/user.actions';
import "./index.css"

import {
    Col,

    Input,

    Row,

  } from 'reactstrap';
  import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddProducts from "./AddProducts"
const Products = () =>{
    const [filterInstituteName, setFilterInstituteName] = useState('');
    const user = sessionStorage.getItem('user');
    const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowsPerPage, setrowsPerPage] = useState(15);
    const [totalFetchRows, setTotalFetchRows] = useState(null);
    const ProcessingFeeTypeArray = ["", "Flat", "Percentage"];
    const FacilityTypeArray = ["", "Dicounted Interest", "Standard Interest", "Hybrid", "Business To Business"];
    const InterestTypeArray = ["", "Per Annum", "Flat"];
    const prodstate = useSelector((state) => state?.Products?.res);
    const dispatch = useDispatch();
    const getprod = `/products?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    const [searchText, setSearchText] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    useEffect(()=>{
    
    
      if(searchText === ""){
        
        const getprod = `/products?perPage=${rowsPerPage}&pageNo=${currentPage}`;

        axiosInstance.get(getprod, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300)
        })
        .catch((error) => {
         
          console.error("Error fetching filtered data:", error);
        });
        }
        
    
    },[])
    const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }
  const handleInputChange = (event) => {

    if(event.target.value.length >= 4){
      setSearchText(event.target.value)
    
      const getprod = `/products?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}`;

  console.log("url",getprod)
    axiosInstance.get(getprod, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
  }
  else{
    setSearchText("")
    const getprod = `/products?perPage=${rowsPerPage}&pageNo=${currentPage}`;

    axiosInstance.get(getprod, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });



  }
};
const paginate = (pageNumber) => {


  console.log("Search Text", searchText)
  // setcurrentPage(pageNumber);
  if(searchText.length >= 4){

    const getprod = `/products?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${pageNumber}`;
    console.log("url",getprod)
  axiosInstance.get(getprod, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res) => {
    console.log("filtering",res)
    setTableData(res?.data?.data?.details);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);  
 
  })
  .catch((error) => {
    console.error("Error fetching filtered data:", error);
  });

  }
  else{
    const getprod = `/products?perPage=${rowsPerPage}&pageNo=${pageNumber}`;
  


  axiosInstance.get(getprod, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res) => {
  
    setTableData(res?.data?.data?.details);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);  
 
  })
  .catch((error) => {
    console.error("Error fetching filtered data:", error);
  });
  }
  
};
const [currentInstituteId,setCurrentInstituteId]=useState(null)
const [productModal, setProductModal] = useState(false);
const [selectedItem, setSelectedItem] = useState(null);
const toggleProductModal = (item) =>{ console.log(item);setSelectedItem(item);setProductModal(!productModal);setCurrentInstituteId(item.instituteId)};
const [isOpen,setIsOpen]=useState(true)
const [productStatus,setProductStatus]=useState(null)

const handleProductStatus=(e)=>{
 
  
  setProductStatus(e)
}
const handleProductStatusChange=(productId)=>{
const updateProductStatus=`/products/status?productId=${productId}&status=${productStatus}&updatedBy=${decode?.display_name}`

 const urlProductList=`/products/instituteId?instituteId=${currentInstituteId}`
axiosInstance.post(updateProductStatus,null,{
  headers:{
    Authorization:`Bearer ${user}`
  }
})
.then((res)=>{
  alert("Sucess")
  setTimeout(() => {
    window.location.reload()
  }, 500);})
.catch((err)=>{
  alert('Error', err)

})

}
const [activePage, setActivePage] = useState(15); 
const handlepageChange = (number) => {


  setIsLoading(true)
  setActivePage(number)
  setrowsPerPage(number);

  let queryParams = {}
  
 if(id){
  queryParams.instituteId=id
 }
  if(rowsPerPage && currentPage){
    queryParams.perPage = number;
    queryParams.pageNo = 1
  }
  axiosInstance.get('/products',{
    params:queryParams,
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res)=>{    
    setTableData(res?.data?.data?.details);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage); 
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 300);
  })
  .catch((error)=>console.log(error))

};
    return(
          <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'77%':'97%', overflow:"auto",marginLeft:isOpen?"22%":'0%',}}>
            <Topbar/>
            <div>
            <h1 style={{fontFamily:'Inter-Medium',fontSize:'1vw',fontWeight:'500',color:'#D32028',marginTop:'10px'}}>Products</h1>

            <Row>
       <div style={{ display: 'flex', flex: 'wrap',marginTop:'10px',flexDirection:'row',justifyContent:'space-between' }}>
         <div>
         <Col sm="12" md="10" lg="3">
         <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center',width:'250px' }}>
         <Input
           type="text"
           placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
           onChange={(event) => {
             getInsituteInfo(event.target.value);
             handleInputChange(event);
           }}
           style={{
              fontSize: '0.8vw',
             paddingLeft: '26px',
             height: '23px',
             fontFamily: 'Inter',
             backgroundImage: `url(${search})`,
             backgroundRepeat: 'no-repeat',
             backgroundPosition: '5px center',
             backgroundSize: '15px 15px',
             paddingRight: '5px', 
             borderColor:'#cccccc',
             borderStyle:'solid',
             borderRadius:"5px",
             borderWidth:'1px',
             height:'34px',
             marginTop:'11px',marginLeft:'3px'
           }}
         />
       </div>
         </Col>
         </div>
         <div>
         <Col sm="12" md="10" lg="3" >
           <AddProducts/>
         </Col>
         </div>
         {/* <div>
         <Col sm="12" md="10" lg="3" >
           <ManageProducts/>
         </Col>
         </div> */}
         
       </div>
     </Row>
     <Modal isOpen={productModal} size="xl">
        <ModalHeader toggle={()=>toggleProductModal(selectedItem)}>Manage Product</ModalHeader>
        <ModalBody>
        {selectedItem &&
        <div style={{boxShadow:'0px 0px 5px 0px rgb(0,0,0.3,0.6)',padding:'7px',marginBottom:'8px',marginLeft:'5px',marginRight:'5px'}}>
        <div style={{display:'flex',paddingLeft:'2em'}}>
          <select  onChange={(e)=>handleProductStatus(e.target.value)}>
            <option name="Change Status" value="Change Status">Change Status</option>
            <option name="In-Active" value={0}>In-Active</option>
            <option name="Active" value={1}>Active</option>
          </select>
                    <Button style={{height:'2.5em',marginLeft:'1em'}} onClick={()=>handleProductStatusChange(selectedItem.productId)}>Submit</Button>
        </div>
        <Accordion style={{marginBottom:'1em',marginTop:'10px',boxShadow:'0px 0px 2px 2px #F0F0F0',margin:'2em'}} key={selectedItem.productId}>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel2a-content"
   id="panel2a-header"
 >
   <Typography><span style={{display:'flex',justifyContent: 'space-between',}}><h5 style={{color:"#d32028",width:'14em'}}>Product ID: {selectedItem.name} </h5> <h5 style={{width:'14em'}}>Tenure: {selectedItem.tenure}</h5><h5 style={{width:'14em'}}>Status: {selectedItem.status?"ACTIVE":"IN-ACTIVE"}</h5></span></Typography>
 </AccordionSummary>
 <AccordionDetails>
   <Typography>
    <div style={{display:'flex',justifyContent:'space-around'}}>
   
     <div  style={{display:'block'}}>
     <p><strong>Advance EMI :</strong> {selectedItem.advanceEmis}</p>
     <p><strong>Interest Type :</strong> {selectedItem.facilityType===1||selectedItem.facilityType===5?InterestTypeArray[selectedItem.discountedInterestType]:selectedItem.facilityType===2?InterestTypeArray[selectedItem.standardInterestType]:selectedItem.facilityType===3?`${InterestTypeArray[selectedItem.discountedInterestType]}`:null}</p>
     <p><strong>Facility Type :</strong> {FacilityTypeArray[selectedItem.facilityType]}</p>
     <p><strong>Tenure :</strong> {selectedItem.tenure}</p>
     </div>
     <div style={{display:'block'}}>
     <p><strong>Rate :</strong> {selectedItem.facilityType===1||selectedItem.facilityType===5?selectedItem.discountedInterestPercentage:selectedItem.facilityType===2?selectedItem.standardInterestPercentage:selectedItem.facilityType===3?`${selectedItem.discountedInterestPercentage} student share : ${selectedItem.studentShare}`:null}</p>
     <p><strong>GST (yes/no) :</strong> {selectedItem.processingfeeValueGST===0?"No":`Yes ${selectedItem.processingfeeValueGST}`}</p>
     <p><strong>Processing Fee :</strong> {selectedItem.processingfeValueIncludingGST}  ({ProcessingFeeTypeArray[ selectedItem.processingfeeType]}) </p>
     <p><strong>Advance EMI :</strong> {selectedItem.advanceEmis}</p>
     </div>
 </div>
     <div style={{display:'block',backgroundColor:'#FFF8F4',boxShadow:'0px 0px 2px 2px  #F9D8D6',borderRadius:'10px',padding:'1em',width:'50%',marginLeft:'20%'}}>
       <div style={{display:'block'}}>
         <ul style={{display:'flex',justifyContent:'space-between',textDecoration:'none',listStyle:'none'}}> 
           <li style={{width:'10em',color:'#d32028',fontWeight:'bold'}}>Disburse Month</li>
           <li style={{width:'5em'}}>{selectedItem.disbursementMonth1}</li>
           <li style={{width:'5em'}}> {selectedItem.disbursementMonth2}</li>
           <li style={{width:'5em'}}> {selectedItem.disbursementMonth3}</li>
           <li style={{width:'5em'}}> {selectedItem.disbursementMonth4}</li>
       </ul>
        <ul style={{display:'flex',justifyContent:'space-between',listStyle:'none'}}> 
           <li style={{width:'10em',color:'#d32028',fontWeight:'bold'}}>Percentage</li>

             <li style={{width:'5em'}}> {selectedItem.disbursementMonth1Percentage}</li>
               <li style={{width:'5em'}}> {selectedItem.disbursementMonth2Percentage}</li>
               <li style={{width:'5em'}}> {selectedItem.disbursementMonth3Percentage}</li>
               <li style={{width:'5em'}}> {selectedItem.disbursementMonth4Percentage}</li>
       </ul>
    
         
       </div>

      
     </div>
   </Typography>
 </AccordionDetails>
</Accordion>
        </div>
         }
                </ModalBody>
       
      </Modal>
     {isLoading ? (
       <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
       <CircularProgress />
     </Box>
     ):(
<>
<div className='tables'  style={{marginTop:'20px'}}>
     <table >
           <thead className='table-heading' style={{width:'100%'}} >
             <tr >
              
               <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter" ,width:'12em',borderTopLeftRadius:'8px'}} id="insName">
             <span style={{marginLeft:'10px'}}> Product</span> 
               </th>

               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'14em',paddingLeft:'10px'}} id="coursename">
               { decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute'}
               </th>

               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'8em',paddingLeft:'10px'}} id="coursename">
               Tenure
               </th>
               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'9em'}} id="interest">
               Subvention
               </th>
               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'9em'}} id="interest">
               Standard Int.
               </th>
               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'9em'}} id="interest">
               Student Share
               </th>
               <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'6em',paddingLeft:'10px'}} id="pf">
               PF
               </th>
               <th className="thead-text"  style={{  fontSize: '0.9vw',width:'9em',cursor:'pointer',fontFamily:"Inter" }} id="advEmi">
                 Advance EMIs
               </th>
               <th className="thead-text"  style={{  fontSize: '0.9vw',width:'8em',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                   Interest Type
               </th>
               <th className="thead-text"  style={{  fontSize: '0.9vw',width:'8em',cursor:'pointer',fontFamily:"Inter",borderTopRightRadius:'8px' }} id="status">
                   <span style={{marginLeft:'10px'}}> Status</span> 
               </th>
               <th className="thead-text"  style={{  fontSize: '0.9vw',width:'8em',cursor:'pointer',fontFamily:"Inter",borderTopRightRadius:'8px' }} id="status">
                   <span style={{marginLeft:'10px'}}> Actions</span> 
               </th>

              
               {/* <th className="thead-text"  style={{  fontSize: '13.5px',width:'20em',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                 Action
               </th> */}
             </tr>
           </thead>

       
           
           {tableData?.map((item,index)=>{
               const filterInstitute = filterInstituteName.toLowerCase();
               const currentInstituteName = (item.instituteName || '').toLowerCase();
               if (currentInstituteName.indexOf(filterInstitute) !== -1)
             return(
                 <tbody className='table-body'>
                 <tr  className='table-row' key={index} style={{ lineHeight: '24px' }}>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',paddingLeft:'10px'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#101828',fontWeight:'500'}}>{item?.name}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',paddingLeft:'10px'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.instituteName}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',paddingLeft:'10px'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.tenure}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{(item?.discountedInterestPercentage*100).toFixed(2)}%</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{(item?.standardInterestPercentage*100).toFixed(2)}%</span></td>
                 <td style={{ color: '#667085', fontFamily: 'Inter', fontSize: '0.9vw' }}>
                  <span style={{ fontFamily: 'Inter', fontSize: '0.9vw', color: '#667085', fontWeight: '500' }}>
                    {item?.facilityType === 3 ? item.studentShare : 0}
                  </span>
                </td>                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.processingfeeValue}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.advanceEmis}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.facilityType===1||item?.facilityType===5?InterestTypeArray[item?.discountedInterestType]:item?.facilityType===2?InterestTypeArray[item?.standardInterestType]:item?.facilityType===3?`${InterestTypeArray[item?.discountedInterestType]}`:null}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item?.status===1?"Active":"In-Active"}</span></td>
                 <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}><img style={{height:'15px',marginLeft:'1.3em',cursor:'pointer'}} src={edit} onClick={()=>toggleProductModal(item)}/></span></td>


                 {/* <td title="Manage Institute"><span style={{cursor:'pointer'}}><ManageProducts/></span></td> */}
                 </tr>
                
                 </tbody>
             )
          
                 })}
           
        
         </table>
     </div>
    
     {totalFetchRows ? ( searchText && totalFetchRows <= 100 ?(
      <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
        <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
              Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
              records of {totalFetchRows} |
             </div>
             <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
      </div>
              
             
            ):( 
              <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
<div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
           Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
           records of {totalFetchRows} |
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
              </div>
            )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
         <PaginationTable
           startPage={searchText !== "" ? 1 : currentPage}
           rowsPerPage={rowsPerPage}
           totalRows={totalFetchRows}
           paginate={paginate}
           searchText={searchText}
         />
</>
     )}
     
            </div>
          </div>
     </div>
        
 
       
    )
}
export default Products