import React from 'react'
import { Box } from '@mui/material'
import styles from './index.module.css'
import {formatToIndianRupees} from '../../../utils/formatToIndianRpuees'

export default function LoanDetails({profileDetails}) {
    console.log(profileDetails, 'profile details')
    
  return (
    <div className={styles.detailsContainer}>
      <Box>
          <span  className={styles.fieldTitle}>Loan Amount: </span>
          <span className={styles.details}>{formatToIndianRupees(profileDetails?.courseFees)}</span>
        </Box>
       {profileDetails?.vehicleBrandName && 
        <Box>
          <span  className={styles.fieldTitle}>Brand Name: </span>
          <span className={styles.details}>{profileDetails?.vehicleBrandName}</span>
        </Box>
       } 
       {profileDetails?.vehicleModel&& 
          <Box>
          <span  className={styles.fieldTitle}>Model: </span>
          <span className={styles.details}>{profileDetails?.vehicleModel}</span>
        </Box>

       }
       {profileDetails?.propertyType  && <Box>
        <span  className={styles.fieldTitle}>Property Type: </span>
        <span className={styles.details}>{profileDetails?.propertyType}</span>
      </Box>}
       {profileDetails?.propertyAddress  && <Box>
        <span  className={styles.fieldTitle}>Property Address: </span>
        <span className={styles.details}>{profileDetails?.propertyAddress}</span>
      </Box>}
       {profileDetails?.propertySize  && <Box>
        <span  className={styles.fieldTitle}>Property Size: </span>
        <span className={styles.details}>{profileDetails?.propertySize}</span>
      </Box>}
      {profileDetails?.propertyDescription  && <Box>
        <span  className={styles.fieldTitle}>Property Description: </span>
        <span className={styles.details}>{profileDetails?.propertyDescription}</span>
      </Box>}
      {profileDetails?.subProductType && 
          <Box>
          <span  className={styles.fieldTitle}>Product Type: </span>
          <span className={styles.details}>{profileDetails?.subProductType}</span>
        </Box>
      }
      
      {profileDetails?.otherDetails && 
      <Box>
      <span  className={styles.fieldTitle}>Other Details: </span>
      <span className={styles.details}>{profileDetails?.otherDetails}</span>
    </Box>}
    </div>
  )
}
