import {
  Col,
  Table,
  Card,
  Form,
  Button,
  ButtonGroup,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip
} from 'reactstrap';
import { useState, useEffect } from 'react';
import download from "../../../assets/images/download.png";
import statusimage from "../../../assets/images/status.png";
import activeDot from "../../../assets/images/activeDot.png";
import pendingDot from "../../../assets/images/pendingDot.png"
import rightarrow from "../../../assets/images/chevron-right.png";
import Sidebar from '../../Sidebar';
import search from "../../../assets/images/search.png"
import Topbar from '../../Topbar';
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode'
import 'react-datetime/css/react-datetime.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation,useNavigate } from 'react-router-dom';
import EditForm from '../EditForm/EditForm';
import MoveProcessingStatus from '../MoveProcessingStatus/MoveProcessingStatus';
import Sort from "../../../assets/images/sort.png";

//import { useUserAuth } from '../../views/auth/AuthContext.js';
import "./ProcessingTable.css"
import PaginationTable from '../PaginationTable';
//import '../../assets/scss/_variables.scss';
import Wait from '../../../assets/images/sand-clock.png';
import axiosInstance from '../../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import {userActions} from '../../../actions/user.actions';
import SendSms from '../SendSms/SendSms'
// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

//require('moment/locale/fr');
//require('moment/locale/de');
//require('moment/locale/en-gb');

const ProcessingTable = () => {
  const processstate = useSelector((state) => state?.Inprogress?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
  const usersearchstate = useSelector((state) => state?.UserSearch?.res?.data?.data);


 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)
  


  const [userDetails, setUserDetails] = useState(null)
  const [fundSourceSelected, setFundSourceSelected] = useState('')

  const dispatch = useDispatch();


  const ApplicationStatusEnum = {
    InSubmission: 'In Submission',
    InReview: 'In Review',
    InProcess: 'In Process',
    InSanction: 'In Sanction',
    InDisbursement: 'In Disbursement',
    Disbursed: 'Disbursed',
    Rejected: 'Rejected',
    Dropped: 'Dropped',
    OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
  };
  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.InProcess);
  const localStatus = sessionStorage.setItem("status",status)
  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [searchText, setSearchText] = useState("")
  const [clicked,setClicked] = useState(false)
  const [cra, newCra] = useState([]);
  const [selectedCRA, setSelectedCRA] = useState('');

  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };
//tooltip
 const [agreementTooltipOpen, setAgreementTooltipOpen] = useState(false);
 const [mandateTooltipOpen, setMandateTooltipOpen] = useState(false);
 const [activePage, setActivePage] = useState(15); 

  const copyAgreementToggle = () => {

    setAgreementTooltipOpen(!agreementTooltipOpen);
    navigator.clipboard.writeText(`
              Aadhaar Suffix:${info?info.aadhaar_suffix:null},
              Name: ${info?info.name:null},
              Postal Code: ${info?info.postal_code:null},
              Display Name: ${info?info.display_name:null},
              Name Validation:${info?info.name_validation_score:null},
              Created At:${docDetails?docDetails.createdAt:null},
              Done At:${docDetails?docDetails.updatedAt:null},
              Download Signed Agreement Document:${docDetails?docDetails.signedDocumentUrl:null} `)
  }
  const copyMandateToggle = () => {

    setMandateTooltipOpen(!mandateTooltipOpen);
   navigator.clipboard.writeText(`
              Account Number:${mandateDetails?mandateDetails.maskedAccountNumber:null},
              Account Type: ${mandateDetails?mandateDetails.accountType:null},
              Customer mobile:${mandateDetails?mandateDetails.customerMobile:null},
              IFSC Code: ${mandateDetails?mandateDetails.ifscCode:null},
              NPCI Txn ID:${mandateDetails?mandateDetails.npciTxnId:null},
              UMRN:${mandateDetails?mandateDetails.umrn:null},
              Mandate Mode:${mandateDetails?mandateDetails.mandateMode:null},
              Created At:${mandateDetails?mandateDetails.createdAt:null},
              Done At:${mandateDetails?mandateDetails.updatedAt:null},
               `)
  }
  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [info,setInfo]=useState(null)
  const [docDetails,setDocDetails]=useState(null)
  const [isLoading, setIsLoading] = useState(true); 

  // eslint-disable-next-line no-unneeded-ternary 
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
 
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [mandateStatusOpen, setMandateStatusOpen] = useState(false);
  const [mandateDetails, setMandateDetails] = useState(null);
   
 const closeAgreeCopy=()=>{
  setStatusOpen(!formStatusOpen)
  setAgreementTooltipOpen(agreementTooltipOpen?!agreementTooltipOpen:agreementTooltipOpen);
 }
 const closeMandateCopy=()=>{
  setMandateStatusOpen(!mandateStatusOpen)
  setMandateTooltipOpen(mandateTooltipOpen?!mandateTooltipOpen:mandateTooltipOpen);
 }
 //sorting
const [sort,setSort]=useState(-1)

  const urlUsers = `/admin/application/in-progress?perPage=${rowsPerPage}&pageNo=${currentPage}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;

  const[toggleSort,setToggleSort]=useState(false);
  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
    const urlUsers = `/admin/application/in-progress?perPage=${rowsPerPage}&pageNo=${currentPage}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;
    
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.applicantDetails);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }


  //button group
  const [fundSourceArray,setFundSourceArray]=useState([])

  useEffect(() => {
  if(usersearchstate){
    setIsLoading(false);
    setUserDetails(usersearchstate?.applicantDetails)
    setTableData(usersearchstate?.applicantDetails);
    setFilterTableData(usersearchstate?.applicantDetails);
    setcurrentPage(usersearchstate?.pagination.currentPage);
    setTotalFetchRows(usersearchstate?.pagination.totalRows);
    setrowsPerPage(usersearchstate?.pagination.perPage);
    setFundSourceArray(usersearchstate?.count)
  }else{
    if(toggleSort) 
    {
      setSort(1);
      

    }
    else{
       setSort(-1) ;
       
    } 

      if(searchText === "" && filterDateFrom === '' && filterDateTo ==='' && selectedCRA === ""){
        
      const urlUsers = `/admin/application/in-progress?perPage=${rowsPerPage}&pageNo=${currentPage}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;

      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
        setFundSourceArray(res?.data?.data?.count)
      })
      .catch((error) => {
        
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
      
     

    }
  }
  const userUrl = `/admin/users`;
  axiosInstance.get(userUrl,{
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }).then((res)=>{
      newCra(res?.data?.data)
  })

        
  }, [toggleSort,urlUsers, processstate,usersearchstate,datestate,fundSourceSelected]);

  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }
  const handleInputChange = (event) => {
    setIsLoading(true);
    setSearchText(event.target.value)
    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application/in-progress',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))
};

 
  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    setIsLoading(true);
    setClicked(!clicked)
    setFilterDateFrom(startDate)
    setFilterDateTo(endDate)
    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = startDate;
      queryParams.toDueDate = endDate+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application/in-progress',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))
  };
  const paginate = (pageNumber) => {
    console.log("Data poin Pagination", pageNumber)

    console.log("Search Text", searchText)
    // setcurrentPage(pageNumber);
    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){

      const urlUsers = `/admin/application/in-progress?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${pageNumber}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;
  console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });

    }
    else if(searchText === "" && filterDateFrom !== '' && filterDateTo !==''&& clicked ===  true){
      const urlUsers = `/admin/application/in-progress?fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      // reviewstate = res?.data?.data
      // console.log("Data poin paginate", reviewstate)
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !==''&& clicked ===  true){
      const urlUsers = `/admin/application/in-progress?instituteId=${id}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        // reviewstate = res?.data?.data
        // console.log("Data poin paginate", reviewstate)
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else{
      const urlUsers = `/admin/application/in-progress?perPage=${rowsPerPage}&pageNo=${pageNumber}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&sortOrder=${sort}&fundSource=${fundSourceSelected}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        // reviewstate = res?.data?.data
        // console.log("Data poin paginate", reviewstate)
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
    
  };
  
  const navigate = useNavigate();

  
  const handleUser = (event) => {
      
    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Process`;

    dispatch(userActions.fetchHandleuser(urlProfile, user),[])
     const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Process`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`

  const urlCIBIL = `/cibil/report?userId=${Id}`
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
      navigate('/status/Process/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Process" },
    });
   
  };

 


  const handleCoApp = (event) => {
    
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Process`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])
    const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
      
      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=In Process`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
     navigate('/status/Process/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Process" },
    });
   
  };

    //Go To Application Profile
   const handleApplicationProfile=(item)=>{
    sessionStorage.setItem("coappId",item?.coapplicantId)
    sessionStorage.setItem("userId",item?.userId)
    sessionStorage.setItem("appId",item?.applicationId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/Process/appProfile")
    
  }

  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,

        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59",
        fundSource:fundSourceSelected
        
       
    }
    axiosInstance.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_In_Process.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
      
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 1000);
  })
  }   
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

const handleCIBIL = (event) => {
    
       const Id = event.userId;
    const applId = event.applicationId
    const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Process`;
    
    dispatch(userActions.fetchHandleuser(profileUrl, user),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
     dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    navigate('/status/Process/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaCibil:true ,dir:"In Process"},
    });
  };
 
 
  //co applicant cibil
  const handleCoAppCIBIL = (event) => {
       const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Process`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])
        const ckycUrl=`/ckyc/details?userId=${Id}`;
   
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  //cibil
  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`

  const urlCIBIL = `/cibil/report?userId=${Id}`
    
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
   
     dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(urlProfile));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/Process/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaCibil:true ,dir:"In Process",},
    });

  
  };

    const [openErrorMessage,setOpenErrorMessage]=useState(false)
  const [errorMessage,setErrorMessage]=useState(null)
  
  const [errorItem,setErrorItem]=useState(null)

   const showErrorApplicant = (uid,appid,error) => {
  const profileUrl=`/summary/user?id=${uid}&applicationId=${appid}&status=In Review`;
    dispatch(userActions.getProfileDetails(profileUrl));


    sessionStorage.setItem('refetchUid',uid)
    sessionStorage.setItem('refetchAppId',appid)
    
       const Id = uid;

  const urlCIBIL = `/cibil/report?userId=${Id}`
   
  axiosInstance.get(urlCIBIL)
  .then((res)=>{
    setErrorMessage(res?.data?.data?.summary)
    setOpenErrorMessage(true)
  })
  .catch((err)=>{
    alert("Error While Fetching CIBIL");
  })


  };
  const showErrorCoApplicant = (uid,appid,error) => {
  const profileUrl=`/summary/coapplicant?id=${uid}&status=In Review`;
    dispatch(userActions.getProfileDetails(profileUrl));


    sessionStorage.setItem('refetchUid',uid)
    sessionStorage.setItem('refetchAppId',appid)
    
       const Id = uid;

  const urlCIBIL = `/cibil/report?userId=${Id}`
   
  axiosInstance.get(urlCIBIL)
  .then((res)=>{
    setErrorMessage(res?.data?.data?.summary)
    setOpenErrorMessage(true)
  })
  .catch((err)=>{
    alert("Error While Fetching CIBIL");
  })


  };


  const handlepageChange = (number) =>{
    setIsLoading(true)
    setActivePage(number);
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
   
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application/in-progress',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))
  
   
  }

  const handleChange = (event) =>{
    setIsLoading(true);
    setSelectedCRA(event.target.value)
    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(event.target.value){
      queryParams.cra = event.target.value
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application/in-progress',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))
  
   
  }
  const [isOpen,setIsOpen]=useState(true)
 
  
  if (tableData) {
    return (
      <Card className="card" style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
        <Topbar/>
        <CardBody>
        <div style={{display:'flex'}}>
            <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>In Process</span></p>
        
        {decode?.role!=="institute_user"&&decode?.role!=="institute_admin"?<div style={{paddingLeft:'30%'}}>
          <ButtonGroup>
            <Button onClick={()=>{setFundSourceSelected('')}} style={{color:'black',backgroundColor:'white',border:'1px solid black'}}>
              All
            </Button>
          { fundSourceArray?.map((item)=>{
                  if(item.fundSource!=='undefined')
                    return(
                
                      <Button onClick={()=>{setFundSourceSelected(item.fundSource)
                        if(filterDateFrom&&filterDateTo)
                      handleDateFilterApi(filterDateFrom,filterDateTo)
                      console.log(item.fundSource)
                      setcurrentPage(1)}} className={`${item.fundSource}-button`} >
                      {item.fundSource}<span className={`${item.fundSource}-count`} style={{marginLeft:'10px',color:'white',padding:'5px 8px 5px 8px',borderRadius:'1rem',textAlign:'center'}}>{item.count}</span>
                      </Button>
              
                    )
                })
                }
        </ButtonGroup>
         
        </div>:null}
           </div>
           <Row>
          <div style={{ display: 'flex', flex: 'wrap'}}>
          <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
          onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
               
              >
                Search Results
              </button>
              </div>
            </Col>
            <Col>
            <div>
          <Input type="select" name="select" id="exampleSelect" value={selectedCRA} onChange={(event)=>handleChange(event)}>
          <option value="">Select CRA</option> 
            {cra?.map((item,index)=>(
              <option>{item.name}</option>
            ))}
          </Input>
          </div>
            </Col>
            <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"150px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>

      <Modal isOpen={formStatusOpen}>
        <ModalHeader>Agreement Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
           
            
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button>
             <p>Co-App Det</p>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeAgreeCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal>
      
       <Modal isOpen={mandateStatusOpen}>
        <ModalHeader>Mandate Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
            { mandateDetails?<>
         
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyMandateToggle() }
            >
              Copy
            </Button>
               {mandateTooltipOpen&&<span style={{fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
            <p><span style={{fontWeight:'700'}}>Account Number: </span>{mandateDetails?mandateDetails.maskedAccountNumber:null}</p>
             <p><span style={{fontWeight:'700'}}>Account Type: </span>{mandateDetails?mandateDetails.accountType:null}</p>
             <p><span style={{fontWeight:'700'}}>customer mobile:</span> {mandateDetails?mandateDetails.customerMobile:null}</p>
             <p><span style={{fontWeight:'700'}}>ifscCode:</span> {mandateDetails?mandateDetails.ifscCode:null}</p>
            <p ><span style={{fontWeight:'700'}}>npci Tax ID:</span> {mandateDetails?mandateDetails.npciTxnId:null}</p>
            <p ><span style={{fontWeight:'700'}}>umrn:</span> {mandateDetails?mandateDetails.umrn:null}</p>
            <p ><span style={{fontWeight:'700'}}>Mandate Mode:</span> {mandateDetails?mandateDetails.mandateMode:null}</p>
             <p><span style={{fontWeight:'700'}}>Created At:</span> {mandateDetails?mandateDetails.createdAt:null}</p>
             <p><span style={{fontWeight:'700'}}>Done At:</span> {mandateDetails?mandateDetails.updatedAt:null}</p></>:<h3>Information is not available</h3>}
             
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeMandateCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal>
      {isLoading ? (
          <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
          <CircularProgress />
        </Box>
      ):(
        <>
 <div className='tables' style={{ marginTop: '20px' }}>
            <table>
              <thead className='table-heading'>
                <tr>
                  <th style={{  fontSize: '0.9vw',width:'10em',borderTopLeftRadius:'8px' }} id="appId">
                  <span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'12px'}}>Appl. Id</span>
                  {/* <img style={{cursor:'pointer',width:'15px',marginLeft:'5px'}} src={Sort} alt="sort"/> */}
                  </th>
                  <th id="appname" style={{width:'20em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}>Appl. Name</span></th>
                  {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                   <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10em' }} id="appname">
                   <span style={{marginLeft:'10px'}}></span> Student Name
                   </th>:null
                  }
                  <th id="insName" style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}>{ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}</span></th>
                  {decode?.role==="institute_user"||decode.role==="institute_admin"?null:<th id="cibil" style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}>CIBIL</span></th>}

                  <th id="coAppStatus"style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Co-App Status</span></th>
                  <th id="coLenderStatus"style={{width:'10em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Status</span></th>
                  <th id="bankStatus" style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Bank Stmnt</span></th>
                  
                  <th id="amt" style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px',width:'10em'}}>Amount</th>
                 
                 
                 
                  { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th id="actions"  style={{width:'10em'}} ><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Actions</span></th>}
                </tr>
              </thead>
              {tableData.map((item,index) => {
      
                  return (
                    <tbody className='table-body' key={item.applicationId}>
                      <tr className='table-row' style={{ lineHeight: '28px' }}>
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td >
                          <span onClick={()=>handleApplicationProfile(item)} style={{cursor:'pointer',fontFamily:"Inter",  fontSize: '0.9vw'}}>{item.applicationId}</span>
                        </td>:<td  style={{  fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter', width: '8em' ,color:'#101828',fontFamily:"Inter"}}>
                          {item.applicationId}
                        </td>
                        }
                        {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                             fontSize: '0.9vw'
                            ,fontFamily:"Inter",
                            color:'#101828',
                            width: '20em',
                          }}
                         
                        >
                           <span style={{fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',lineHeight: '1.5em'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span style={{fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',lineHeight: '2em'}}>
                              {' '}
                             {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            cursor: 'pointer',fontFamily:"Inter",
                            width: '20em',
                          }}
                        >
                          <span style={{fontFamily:"Inter",marginTop:'10px',lineHeight: '1.5em'}}onClick={() => handleUser(item)}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName && (
                            <span style={{fontFamily:"Inter",lineHeight: '1.5em'}}onClick={() => handleCoApp(item)}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          
                          )}
                        </td>}
                        {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                         <span style={{width: '10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                        </td>:null}
                        <td title={item.instituteName}><span style={{  fontSize: '0.9vw', width: '12em', color: '#667085' ,fontFamily:"Inter",marginTop:'10px',lineHeight: '1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.instituteName}	</span></td>

                       {decode.role==="institute_user"||decode.role==="institute_admin"?null:<td
                                   style={{
                                      fontSize: '0.9vw',
                                     width: '6em',
                                     color: item.fundSourceCode!=="GBP"?"black":'blue',
                                     cursor: 'pointer',
                                     fontFamily:"Inter"
                                     ,
                                   }}
                                  
                                 >
                                    {item.fundSourceCode!=="GBP"?<span>NA<br/></span>:item.applicantCibil!=="Error"? <span style={{fontFamily:"Inter",fontSize:'0.9vw'}} onClick={() => handleCIBIL(item)}>{item.applicantCibil}</span>:<span onClick={()=>showErrorApplicant(item.userId,item.applicationId,item)} style={{}}>Error</span>}
                                   {item.coapplicantName ? (
                                    item.fundSourceCode!=="GBP"?<span>NA</span>:
                                      item.coapplicantCibil!=="Error" ?<span style={{fontFamily:"Inter",fontSize:'0.9vw',}} onClick={() => handleCoAppCIBIL(item)}>
                                       {' '}<br/>
                                      {item.coapplicantCibil}</span>:<span onClick={()=>showErrorCoApplicant(item.coapplicantId,item.applicationId,item)} style={{}}>Error</span>
                                    
                                   ) : (
                                     ''
                                   )}

                                 </td>
                       }
                        <td
                          style={{  fontSize: '0.9vw', width: '9em', color: '#667085',fontFamily:"Inter" }}
                          onClick={() => console.log('Co-App Status')}
                        >
                         <span style={{ cursor:'pointer', fontWeight:'600',border:"none", fontSize: '0.9vw', width: '7em', color: '#667085',fontFamily:"Inter" ,marginTop:'10px',padding:'5px',
                         color:`${item.coapplicantStatus==='Completed'?'#12B76A':`${item.coapplicantStatus==='Pending'?'#F8A31F':`${item.coapplicantStatus==='Awaiting'?'aliceBlue':`${item.emandate==='Initiated'?'blue':''}`}`}`}`,
                         backgroundColor:`${item.coapplicantStatus==='Completed'?'#ECFDF3':`${item.coapplicantStatus==='Pending'?'#FFF6E8':`${item.coapplicantStatus==='Awaiting'?'aliceBlue':`${item.emandate==='Initiated'?'blue':''}`}`}`}`,textAlign:'center',borderRadius:'1em',}}>
                        <span>{item.coapplicantStatus==="Completed"?<span><img src={activeDot}/> {item.coapplicantStatus}</span>:null}</span>  <span>{item.coapplicantStatus==="Pending"?<span><img src={pendingDot}/> {item.coapplicantStatus}</span>:null}</span>   {item.coapplicantStatus==="Awaiting"?<img style={{width:'18px'}} src={Wait} alt="wait"/>:null}</span>
                        </td>
                        <td style={{ fontSize: '0.9vw',cursor:'pointer', width: '7em', color: 'black',fontFamily:"Inter"}}>
                          <span className={`${item.fundSourceCode}-text`} >{item.fileStatus?item.fileStatus.substr(0,1).toUpperCase()+item.fileStatus.substr(1,item.fileStatus.length).toLowerCase():"In Process"}</span></td>
                        <td
                          style={{ fontSize: '0.9vw',cursor:'pointer', width: '9em', color: '#667085',fontFamily:"Inter"}}
                          onClick={()=>console.log("Bank Statement Status")}
                        >
                          <span style={{fontWeight:'600', color: '#667085',fontFamily:"Inter" ,padding:'5px',
                          color:`${item.bankStatementStatus==='Completed'?'#ECFDF3':`${item.bankStatementStatus==='Initiated'?'#A8A8A8':`${item.bankStatementStatus==='Pending'?'#F8A31F':`${item.emandate==='Initiated'?'#007FFF':''}`}`}`}`,
                           backgroundColor:`${item.bankStatementStatus==='Completed'?'#ECFDF3':`${item.bankStatementStatus==='Initiated'?'#A8A8A8':`${item.bankStatementStatus==='Pending'?'#FFF6E8':`${item.emandate==='Initiated'?'#007FFF':''}`}`}`}`,textAlign:'center',borderRadius:'1em',width:'8em'}}>
                           <span>{item.bankStatementStatus==="Completed"?<span><img src={activeDot}/> {item.bankStatementStatus}</span>:null}</span>  <span>{item.bankStatementStatus==="Pending"?<span><img src={pendingDot}/> {item.bankStatementStatus}</span>:null}</span> 
                            </span>
                        </td>
                        
                        <td style={{ width: "7em" ,fontSize:'0.9vw', color: '#667085',fontFamily:"Inter" }}>{item.courseFees ? ( <>&#8377;{formatNumberWithCommas(item.courseFees)}</>) : ( '-')}</td>
                        
                       
                       
                       { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:
                        <td >
                          <div style={{display:'flex',flexDirection: 'row'}}>
                          <span title="Edit Form" style={{cursor:'pointer',marginLeft:'10px'}}><EditForm  curruser={item} /></span> 
                          <span title="Move Status" style={{cursor:'pointer',marginLeft:'0.2vw'}}><MoveProcessingStatus  ApplicantId={item} /></span> 
                          <span title="Request Bank Statement" style={{cursor:'pointer',marginLeft:'0.2vw'}}><SendSms ApplicantId={item}  /></span> 
                          </div>
                          
                        </td>}
                      </tr>
                    
                    </tbody>
                  );
              })}
            </table>
            </div>
            {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}

            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              searchText={searchText}
              filterDateFrom={filterDateFrom}
            />
        </>
      )}
         
          
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default ProcessingTable;


