import {
  Col,
  Card,
  Input,
  CardBody,
  Row,

} from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Sidebar from '../Sidebar';
import Topbar from "../Topbar"
import statusimage from "../../assets/images/status.png"
import rightarrow from "../../assets/images/chevron-right.png"
import { useState, useEffect } from 'react';
import search from "../../assets/images/search.png"
import Datetime from 'react-datetime';
import download from "../../assets/images/download.png"
import jwt_decode from 'jwt-decode';
import axiosInstance from '../../helpers/axios';
import "./Applications.css"
import ApplicationDetails from './ApplicationDetails';
import PaginationTable from '../status/PaginationTable';
import * as moment from 'moment'
import { useSelector, useDispatch } from "react-redux";

const CustomStepIcon = ({ index, consent ,cibil,approved,selfie,digilocker,agreementStatus,razorpayStatus,disbursed  }) => {
  let backgroundColor = '#898989'; 

  if (index === 0) {
      if (consent === "To Do") {
        backgroundColor = '#898989'; 
      } else if (consent) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 1) {
      if (cibil === "To Do") {
        backgroundColor = '#898989'; 
      } else if (cibil) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 2) {
      if (approved === "To Do") {
        backgroundColor = '#898989'; 
      } else if (approved) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 3) {
      if (selfie === "To Do") {
        backgroundColor = '#898989'; 
      } else if (selfie) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 4) {
      if (digilocker === "To Do") {
        backgroundColor = '#898989'; 
      } else if (digilocker) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    
    if (index === 5) {
      if (agreementStatus === "To Do") {
        backgroundColor = '#898989'; 
      } else if (agreementStatus) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 6) {
      if (razorpayStatus === "To Do") {
        backgroundColor = '#898989'; 
      } else if (razorpayStatus) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 7) {
      if (disbursed === "To Do") {
        backgroundColor = '#898989'; 
      } else if (disbursed) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
   
   

  return (
    <div
      style={{
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor,
        marginTop: '3px'
      }}  
    >
    </div>
  );
};

const CustomStepIconCoApp = ({ index, coAppconsent ,coAppCibil,approved,coAppselfie,coAppdigilocker,coAppAgeementStatus,coApprazorpayStaus,disbursed}) => {
  let backgroundColor = '#898989'; 

  if (index === 0) {
      if (coAppconsent === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coAppconsent) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 1) {
      if (coAppCibil === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coAppCibil) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 2) {
      if (approved === "To Do") {
        backgroundColor = '#898989'; 
      } else if (approved) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
  
    if (index === 3) {
      if (coAppselfie === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coAppselfie) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 4) {
      if (coAppdigilocker === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coAppdigilocker) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 5) {
      if (coAppAgeementStatus === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coAppAgeementStatus) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
    if (index === 6) {
      if (coApprazorpayStaus === "To Do") {
        backgroundColor = '#898989'; 
      } else if (coApprazorpayStaus) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }
   
    if (index === 7) {
      if (disbursed === "To Do") {
        backgroundColor = '#898989'; 
      } else if (disbursed) {
        backgroundColor = '#12B76A'; 
      } else {
        backgroundColor = '#D22129'; 
      }
    }

   
  return (
    <div
      style={{
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor,
        marginTop: '3px'
      }} 
    >
    </div>
  );
};
const steps = ['Consent', 'Cibil & Income', 'Approved/Rejected','Selfie','Kyc','Agreement','E Mandate','Disbursement'];

const Applications = ()=>{
  const [searchUser, setSearchUser] = useState(null);
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const user = sessionStorage.getItem('user');

  const valid = (current) => {
      return current.isAfter(filterDateFrom);
      
    };

    const usersearchinsstate = useSelector((state) => state?.UserSearchIns?.res?.data?.data);
    console.log("usersearchsta",usersearchinsstate)

    const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]);
    const createUrl = `/audit/app-tracker?perPage=${rowsPerPage}&pageNo=${currentPage}`

    const clearDateFilter =()=>{
      setFilterDateFrom('')
      setFilterDateTo('')
      axiosInstance.get(createUrl,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("reports resss",res?.data?.data)
        setTableData(res?.data?.data?.applicantDetails)
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage); 
       
      
      }).catch((error)=>console.log("error",error))
        }


      useEffect(()=>{
        if(usersearchinsstate){
        setTableData(usersearchinsstate)
     
        }else{
          
          axiosInstance.get(createUrl,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{
            console.log("reports resss",res?.data?.data)
            setTableData(res?.data?.data?.applicantDetails)
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage); 
           
          
          }).catch((error)=>console.log("error",error))
        }
      
      },[usersearchinsstate])

      const paginate = (pageNumber) => {
        const urlFilterUsers = `/audit/app-tracker?fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          console.log("filtering",res)
          setTableData(res?.data?.data?.applicantDetails)
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });      }


      const handleDateFilterApi = (startDate, endDate) => {
        setFilterDateFrom(startDate)
        setFilterDateTo(endDate)
        if (startDate > endDate) alert('Check From Date!!!');
        else if (startDate === null || endDate === null) alert('Enter Date Range to Filter');
        else {
          const urlFilterUsers = `/audit/app-tracker?fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${currentPage}`;
          axiosInstance.get(urlFilterUsers, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
            console.log("filtering",res)
            setTableData(res?.data?.data?.applicantDetails)
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage);  
            
          })
          .catch((error) => {
            alert(error?.response?.data?.message)
            console.error("Error fetching filtered data:", error);
          });
        console.log("else")}
      };
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [applicantDetails,setApplicantDetails] = useState([])
      
      const DisbursementDetails = (applicationId) => {
        console.log('Last step clicked!');
        setIsModalOpen(true);
        const urlUsers = `/admin/application/disbursed?applicationId=${applicationId}`
        axiosInstance.get(urlUsers,{
          headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
          console.log("details",res?.data?.data?.details[0])
          setApplicantDetails(res?.data?.data?.details[0])
      
      }).catch((error)=>console.log("error",error))
      };
const toggleModal = () => {
  setIsModalOpen(!isModalOpen);
};
function formatNumberWithCommas(number) {
  return number.toLocaleString('en-IN'); 
}
const [isOpen,setIsOpen]=useState(true)

  return(
      <>
      <Card className='card' style={{overflowX: 'hidden',display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
      <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
      <div style={{width:isOpen?'78%':'100%', overflow:"auto",marginLeft:isOpen?"21%":'0%'}}>
      <Topbar/>
         <div>
         <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>In Process</span></p>
         </div>
         <Row>
          <div style={{ display: 'flex', flex: 'wrap'}}>
            <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter-Medium'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
           {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }onChange={(event) => setSearchUser(event.target.value)}
            style={{
               fontSize: '1vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter-Medium',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
          onChange={(event) => setSearchUser(event.target.value)}
          style={{
             fontSize: '1vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter-Medium',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
           
            <Col >
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                 closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                    clearDateFilter()
                  }
                  }}

                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter-Medium',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col >
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                 closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                      clearDateFilter()
                    }
                  }}

                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter-Medium',fontSize:"14px"} }}
                />
              </div>
            </Col>
          
            <Col >
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
               
               onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
              >
                Search Results
              </button>
              </div>
            </Col>
            
            <Col >
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex',marginLeft:'150px', alignItems: 'center',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium"}} id="downloadButton"  >
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Disbursement Details</ModalHeader>
        <ModalBody>
         
         
            { applicantDetails?
            <>
             <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <div style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{applicantDetails?.applicationId}</span></div>
               <div style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{applicantDetails?.name}</span></div>
            </div>
              <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
              {applicantDetails.coapplicantName? 
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
   <p style={{display:'flex',flexDirection:"column",marginLeft:'5px'}}>
     <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px',marginTop:'20px'}}>Co-Applicant Name</span>
     
    <span 
         
         style={{
           
           textDecoration: 'none',
           color: '#232323',
            fontSize: '13px',
           fontFamily: 'Inter-Medium',
           lineHeight:"30px",
           display: 'block',
           width: '100%',
           wordBreak: 'break-word'
         }}>{applicantDetails?applicantDetails.coapplicantName:null}</span> 
   </p>
</div>:""}
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
             <p style={{display:'flex',flexDirection:"column"}}>
               <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px',marginTop:'20px'}}>Subvention Amount</span>
               
              <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{applicantDetails.subventionAmount ? ( <>&#8377;{formatNumberWithCommas(applicantDetails.subventionAmount)}</>) : ( '-')}</span> 
             </p>
   </div>
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
             <p style={{display:'flex',flexDirection:"column",marginLeft:'5px'}}>
               <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px',marginTop:'20px'}}>Processing Fee</span>
               
              <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{applicantDetails.processingFee ? ( <>&#8377;{formatNumberWithCommas(applicantDetails.processingFee)}</>) : ( '-')}</span> 
             </p>
   </div>
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
             <p style={{display:'flex',flexDirection:"column"}}>
               <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px',marginTop:'20px'}}>Disbursed Amount</span>
               
              <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{applicantDetails.disbursedAmount ? ( <>&#8377;{formatNumberWithCommas(applicantDetails.disbursedAmount)}</>) : ( '-')}</span> 
             </p>
   </div>
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
             <p style={{display:'flex',flexDirection:"column",marginLeft:'5px'}}>
               <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px',marginTop:'20px'}}>Disbursement Utr</span>
               
              <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{applicantDetails?applicantDetails.disbursementUtr:null}</span> 
             </p>
   </div>
   <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
             <p style={{display:'flex',flexDirection:"column"}}>
               <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px',marginTop:'20px'}}>Disbursement Date</span>
               
              <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{applicantDetails?applicantDetails.disbursementDate:null}</span> 
             </p>
   </div>
   
   </div>
        
 

        </>:<h3>Information is not yet available</h3>}
        </ModalBody>
       
      </Modal>
        <div className='tables' style={{ marginTop: '20px', width: '100%' }}>
            
            <table>
              <thead className='table-heading' >
                <tr >
                  <th style={{  fontSize: '0.9vw',cursor:'pointer',fontFamily:"Inter-Medium",width:'10%',borderTopLeftRadius:'8px' }} id="appId">
                  <span style={{marginLeft:'10px'}}></span> Appl. Id 
                  </th>
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium",width:'15%'}} id="appname">
                    Appl. Name
                  </th>
                  { decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <th  style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium",width:'15%',whiteSpace:'nowrap'}} id="appname">
                   Student Name
                  </th>
                  }
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium" ,width:'15%'}} >
                Mobile 
                  </th>
                  <th  style={{  fontSize: '0.9vw' ,fontFamily:"Inter-Medium",width:'15%'}}>
                   Email
                  </th>
                  <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium",width:'12em'}} >
                  { decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Client': 'Course'}
                  </th>
                  <th className="thead-text" style={{  fontSize: '0.9vw',cursor:'pointer',fontFamily:"Inter-Medium",width:'8em' }}>
                    Date
                  </th>
                  
                  <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium",width:'8em' }}>
                   Amount
                  </th>
                  <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter-Medium",width:'8em'}}>
                  Tenure
                  </th>
                 
                 
                </tr>
              </thead>
             
              {tableData?.map((item,index) => {
                return (
                <>
                <tbody className='table-body1' key={item.applicationId}  
                  >
                      
                      <tr className="table-row1" style={{ lineHeight: '14px'}}
                      >
                        <td >
                         <span style={{  fontSize: '0.9vw', fontWeight: '400',fontFamily:"Inter-Medium" ,color:'#101828',paddingLeft:'0.5em'}}>{item.applicationId}</span> 
                        </td>
                        
                      <td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            fontFamily:"Inter-Medium",
                          }}
                        >
                          <span title={item.name} style={{maxWidth:'10em',fontFamily:"Inter-Medium",fontWeight:'400',color:'#667085',fontSize:'0.9vw',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingTop:'5px'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{maxWidth:'10em',fontFamily:"Inter-Medium",fontWeight:'400',color:'#667085',fontSize:'0.9vw', lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        { decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium",lineHeight: '1.5em' }}>
                          {item.studentName ? item.studentName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "-"}
                        </td>
                        }
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium" }}>
                          {item.mobile ? item.mobile : "-"}
                        </td>
                        <td title={item.email}>
                         <span style={{width:'10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.email ? item.email : "-"}</span> 
                        </td>
                        <td title={item.courseName}>
                         <span style={{width:'11em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{item.courseName ? item.courseName : "-"}</span> 
                        </td>
                        <td
                         
                          style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium" ,width:'8em',whiteSpace:'nowrap'}}
                        >
                          {item.applicationDate?.substr(0, 10) ? moment(item.applicationDate?.substr(0, 10)).format('DD MMM YY') : "-" }

                        </td>
                        
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium",maxWidth:'6em'  }}>
                         {item.courseFees ? ( <>&#8377;{formatNumberWithCommas(item.courseFees)}</>) : ( '-')}
                        </td>
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter-Medium",maxWidth:'6em' }}>
                          {item.tenure ? item.tenure : "-"}
                        </td>
                      
                      </tr>
                      
                    <tr className='table-body'>
                      <td colSpan="9">
                      <Box sx={{ width: '88%' }} style={{marginLeft:'10%',backgroundColor:'#f0f0f0',borderRadius:'10px',paddingTop:'0.7em',boxShadow:'0px 0px 2px 0px rgb(0,0,0.2,0.6)'}}>
        {item.coapplicantId?(
        <>
            <Stepper activeStep={-1} alternativeLabel >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={() => <CustomStepIcon index={index} consent={item?.appTracker?.consent} cibil={item?.appTracker?.cibil} approved={item?.appTracker?.approved} selfie={item?.appTracker?.selfie} digilocker={item?.appTracker?.digilocker} agreementStatus={item?.appTracker?.agreementStatus} razorpayStatus={item?.appTracker?.razorpayStatus}  disbursed={item?.appTracker?.disbursed}  applicationId={item?.applicationId}/>}>
                    {label}
                </StepLabel>
              </Step>
            ))}
            </Stepper>
            <Stepper activeStep={-1} alternativeLabel style={{paddingTop:'0.5em'}} >
            {steps.map((label, index) => (
              <Step key={label}>
              <StepLabel  StepIconComponent={() => <CustomStepIconCoApp index={index} coAppconsent={item?.appTracker?.coapplicant?.consent} coAppCibil={item?.appTracker?.coapplicant?.cibil} approved={item?.appTracker?.approved} coAppselfie={item?.appTracker?.coapplicant?.selfie} coAppdigilocker={item?.appTracker?.coapplicant?.digilocker} coAppAgeementStatus={item?.appTracker?.coapplicant?.agreementStatus} coApprazorpayStatus={item?.appTracker?.coapplicant?.razorpayStatus}  disbursed={item?.appTracker?.disbursed}  applicationId={item?.applicationId}/>} style={{paddingBottom:'1em'}}></StepLabel>      </Step>
            ))}
            </Stepper>
        </>)
        :
        (<>
            <Stepper activeStep={-1} alternativeLabel style={{paddingBottom:'0.5em'}}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={() => <CustomStepIcon index={index} consent={item?.appTracker?.consent} cibil={item?.appTracker?.cibil} approved={item?.appTracker?.approved} selfie={item?.appTracker?.selfie} digilocker={item?.appTracker?.digilocker} agreementStatus={item?.appTracker?.agreementStatus} razorpayStatus={item?.appTracker?.razorpayStatus}  disbursed={item?.appTracker?.disbursed}  applicationId={item?.applicationId}/>}>
                {label}
                </StepLabel>
              </Step>
            ))}
            </Stepper>
        </>)}
     
        </Box>
                      </td>
                    </tr>
                
                    </tbody>
                    
                     
                </>
                  
                );
            })}
            </table>
            </div>
            {usersearchinsstate ? "":<> {totalFetchRows ? (
              <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter-Medium", fontSize: '0.9vw',marginLeft:'10px' ,marginBottom:'5px'}}>
                Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage}{' '}
                records
              </div>
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter-Medium", fontSize: '0.9vw' }}>No Records</div>
            )}
            <PaginationTable
              startPage="1"
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              currStatus={selectedStatus}
            /></>}
           
      </div>
      </Card>
      </>
  )
}

export default Applications