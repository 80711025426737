import * as Icon from 'react-feather';
import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip
} from 'reactstrap';
import axiosInstance from '../../../helpers/axios';
import './PendingTable.css'
import search from "../../../assets/images/search.png"
import { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar';
import Topbar from '../../Topbar';
import download from "../../../assets/images/download.png"
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode'
import 'react-datetime/css/react-datetime.css';
import statusimage from "../../../assets/images/status.png";
import rightarrow from "../../../assets/images/chevron-right.png"
import { useLocation,useNavigate } from 'react-router-dom';
import EditForm from '../EditForm/EditForm';
import MovePendingStatus from '../MovePendingStatus/MovePendingStatus';
import Sort from "../../../assets/images/sort.png";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import PaginationTable from '../PaginationTable';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';
//import '../../assets/scss/_variables.scss';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/en-gb');

const PendingTable = () => {

  const state = useSelector((state) => state?.Pendingindisburse?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
  const usersearchstate = useSelector((state) => state?.UserSearch?.res?.data?.data);
  // const state = useMemo(() => (res), []);

  const [userDetails, setUserDetails] = useState(null)

  const dispatch = useDispatch();

  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};

  //tooltip
  const [signedTooltipOpen, setSignedTooltipOpen] = useState(false);
  const signedToggle = () => setSignedTooltipOpen(!signedTooltipOpen);
  
  const [selfieTooltipOpen, setSelfieTooltipOpen] = useState(false);
  const selfieToggle = () => setSelfieTooltipOpen(!selfieTooltipOpen);



  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.PendingDisbursement);
  const localStatus = sessionStorage.setItem("status",status)
  const [isLoading, setIsLoading] = useState(true);
  const user = sessionStorage.getItem("user")
  //const { user } = useUserAuth();
  const decode=jwt_decode(user)
  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [clicked,setClicked] = useState(false)

  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };
//tooltip
 const [agreementTooltipOpen, setAgreementTooltipOpen] = useState(false);
 const [mandateTooltipOpen, setMandateTooltipOpen] = useState(false);
 const [activePage, setActivePage] = useState(15); 


  const copyAgreementToggle = () => {

    setAgreementTooltipOpen(!agreementTooltipOpen);
    navigator.clipboard.writeText(`
              Aadhaar Suffix:${info?info.aadhaar_suffix:null},
              Name: ${info?info.name:null},
              Postal Code: ${info?info.postal_code:null},
              Display Name: ${info?info.display_name:null},
              Name Validation:${info?info.name_validation_score:null},
              Created At:${docDetails?docDetails.createdAt:null},
              Done At:${docDetails?docDetails.updatedAt:null},
              Download Signed Agreement Document:${docDetails?docDetails.signedDocumentUrl:null} `)
  }
  const copyMandateToggle = () => {

    setMandateTooltipOpen(!mandateTooltipOpen);
   navigator.clipboard.writeText(`
              Name:${mandateDetails?mandateDetails.name:null},
              Account Type:${mandateDetails?mandateDetails.accountNumber:null},
              Account Type: ${mandateDetails?mandateDetails.accountType:null},
              Customer mobile:${mandateDetails?mandateDetails.phone:null},
              Mandate Type:${mandateDetails?mandateDetails.mandateType:null},
              IFSC Code: ${mandateDetails?mandateDetails.ifsc:null},
              TokenId:${mandateDetails?mandateDetails.tokenId:null},
              PaymentId:${mandateDetails?mandateDetails.paymentId:null},
              CustomerId:${mandateDetails?mandateDetails.customerId:null},
             
               `)


           
  }
  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');

  const [info,setInfo]=useState(null)
  const [docDetails,setDocDetails]=useState(null)
  const [searchText, setSearchText] = useState("")

  
  // eslint-disable-next-line no-unneeded-ternary 
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
    const urlUsers = `/disbursement/payouts?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
    
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.disbursements);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }

  const paginate = (pageNumber) => {


    console.log("Search Text", searchText)
    // setcurrentPage(pageNumber);
    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){
      const urlUsers = `/disbursement/payouts?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;

  console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });

    }
    else if(searchText === "" && filterDateFrom !== '' && filterDateTo !=='' && clicked === true){
      const urlUsers = `/disbursement/payouts?fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
  
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !=='' && clicked === true){
      const urlUsers = `/disbursement/payouts?instituteId=${id}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
  
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.disbursements);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else{
      const urlUsers = `/disbursement/payouts?perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
  
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.disbursements);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
    
  };
 
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [mandateStatusOpen, setMandateStatusOpen] = useState(false);
  const [mandateDetails, setMandateDetails] = useState(null);
   
 const closeAgreeCopy=()=>{
  setStatusOpen(!formStatusOpen)
  setAgreementTooltipOpen(agreementTooltipOpen?!agreementTooltipOpen:agreementTooltipOpen);
 }
 const closeMandateCopy=()=>{
  setMandateStatusOpen(!mandateStatusOpen)
  setMandateTooltipOpen(mandateTooltipOpen?!mandateTooltipOpen:mandateTooltipOpen);
 }
    //sorting
  const [sort,setSort]=useState(-1)


  const urlUsers = `/disbursement/payouts?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

  const[toggleSort,setToggleSort]=useState(false);

  useEffect(() => {
    if(usersearchstate){
      setIsLoading(false);
      setUserDetails(usersearchstate?.disbursements)
        setTableData(usersearchstate?.disbursements);
        setFilterTableData(usersearchstate?.disbursements);
        setcurrentPage(usersearchstate?.pagination.currentPage);
        setTotalFetchRows(usersearchstate?.pagination.totalRows);
        setrowsPerPage(usersearchstate?.pagination.perPage);  
    }else{
      if(toggleSort)
      {
        setSort(1)
        
      } 
      else {
        setSort(-1)
       
      } 
  
     
        if(searchText === "" && filterDateFrom === '' && filterDateTo ===''){
          
          const urlUsers = `/disbursement/payouts?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
  
          axiosInstance.get(urlUsers, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
           
            setTableData(res?.data?.data?.disbursements);
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage);  
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          
          })
          .catch((error) => {
            alert(error?.response?.data?.message)
            console.error("Error fetching filtered data:", error);
          });
          
      }  
    }
   
  
        
  }, [toggleSort,urlUsers,usersearchstate]);




  const handleDocDownload=(docUserId,docUserApplicationId)=>{
    setStatusOpen(!formStatusOpen);
      const docUrl=`/agreement/downloadAgreement?userId=${docUserId}&applicationId=${docUserApplicationId}`
    axiosInstance.post(docUrl).then((resp)=>{
      if(resp.data.data!=undefined)
      {

        setInfo(JSON.parse(resp.data.data.info))
        setDocDetails(resp.data.data)
      }
    }
    )
  }

  const handleMandateDetails=(muserApp,muserId)=>{
    setMandateStatusOpen(!mandateStatusOpen);
    const mandateUrl=`/integrations/razorpay-details?applicationId=${muserApp}&userId=${muserId}`
    axiosInstance.get(mandateUrl,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      }).then((resp)=>
  
    setMandateDetails(resp.data.data))
  }
  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    setClicked(!clicked)
    setFilterDateFrom(startDate)
    setFilterDateTo(endDate)
    if (startDate === '' || endDate === '') alert('Enter Date Range to Filter');
    else {
      setIsLoading(true);
      if(searchText){
        const urlFilterUsers = `/disbursement/applications?instituteId=${id}&fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.disbursements);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }else{
        const urlFilterUsers = `/disbursement/applications?fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.disbursements);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
      
    }
  };
  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }

  const handlepageChange = (number) => {

     setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/disbursement/payouts',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      console.log("filtering",res)
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

  };




  const handleInputChange = (event) => {

    if(event.target.value.length >= 4 && filterDateFrom === "" && filterDateTo === ""){
      setSearchText(event.target.value)
      const urlUsers = `/disbursement/payouts?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
      console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  }
  else if(event.target.value.length >= 4 && filterDateFrom !== "" && filterDateTo !== ""){
    const urlUsers = `/disbursement/payouts?instituteId=${id}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;

    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
  }else{
    setSearchText("")
    setFilterDateFrom('')
    setFilterDateTo('')
    const urlUsers = `/disbursement/payouts?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.disbursements);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
  }
  };

  
  const navigate = useNavigate();
  

 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)
  const handleUser = (event) => {
      
    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=${status}`;

    dispatch(userActions.fetchHandleuser(urlProfile, user),[])
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=${status}`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/Pending/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId },
    });

   
  };

 


  const handleCoApp = (event,currTab) => {
    
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=Pending`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=Pending`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
 
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/Pending/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId },
    });

     
   
  };

  //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    sessionStorage.setItem("coappId",currCoAppId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/Pending/appProfile")
    
  }
  
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,

        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59"
       
    }
    axiosInstance.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
      
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    }, 1000);
  })
  }     
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }
  //payouts intergration
    const [otp,setOTP]=useState(null)
    const [otpSent,setOTPSent]=useState(false)
    const [confirmDisbursement,setConfirmDisbursement]=useState(false)
  // #1 get all selected
  const [adjustmentAmt,setAdjustmentAmount]=useState(null)
   const [amountToBeDisbursed,setAmountToBeDisbursed]=useState(null)
   const [sourceAccNum,setSourceAccountNumber]=useState("Src Acc No.")
  //  const [destinationAccNum,setDestinationAccountNumber]=useState("Destn. Acc No")
  const [dropDownOpen, setDropDownOpen] = useState(false);
  // const [destDropDownOpen, setDestDropDownOpen] = useState(false);
   const [amtNotes,setAmountNotes]=useState(null)
  
  const dropdown=[];

  //otp

  const handleSendOTP=()=>{
    const urlSendOTP=`/disbursement/otp`
    axiosInstance.post(urlSendOTP,null ,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>setOTPSent(!otpSent))
    

  }

  const handleVerifyOtp=()=>{
    const urlVerifyOtp=`/auth/verify-otp`
    
    const mobileNumber=decode.mobile
    const data={
      mobile:mobileNumber,
      otp:otp
    }
    
    axiosInstance.post(urlVerifyOtp,data)
    
    .then((res)=>
    setConfirmDisbursement(!confirmDisbursement)

    )
  }

   const handleToggle = (position,array) => {
    if(array[position]==undefined)
     array[position]=true
     else
      array[position]=false
        };
    // const handleDestToggle = () => {
    //        setDestDropDownOpen(!destDropDownOpen);
    // };
  const [selectedStatusOpen,setSelectedStatusOpen]=useState(false)
  const [selectedApp,setSelectedApp]=useState([])
  const [displaySelected,setDisplaySelected]=useState([])
  const [totalAmountSelected,setTotalAmountSelected] = useState(0)
  
  const handleSelectedApp=(event,item)=>{
    
    if(event.target.checked==true )
    {
      setSelectedApp(prev=>[...prev,item]);
      setTotalAmountSelected((prevTotal) => prevTotal + Number(item.disbursementAmount));
      
    }
    else if(selectedApp.includes(item))
    {
      var index = selectedApp.indexOf(item);
        if (index !== -1) {
          selectedApp.splice(index, 1);
        }
        setTotalAmountSelected((prevTotal) => prevTotal - Number(item.disbursementAmount));
    }
  


  }


   



  const handlePayouts=(createdList)=>{
    
    setSelectedStatusOpen(!selectedStatusOpen)
    const urlGetSelectedApp=`/disbursement/payout`
    const data=createdList.map((item)=>{
      return(
        {
            applicationId: item.applicationId,
            fundAccountId: item.bankDetails.razorpayFundAccountId,
            amountToBeDisbursed: item.disbursementAmount,
            adjustmentAmount:item.adjustedAmount,
            destinationAccountNumber: item.bankDetails.accountNumber
          }
      )
    })
    
    axiosInstance.post(urlGetSelectedApp,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
       
      setDisplaySelected(res.data.data)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      
    })
  }

 
 
  {/*const handleConfirmDisburse=(finalApp)=>{

    //const isValid=checkValidation(finalApp)
    const urlSendForDisbursement=`/disbursement/payout`
    if(finalApp.length!=0 && isValid)
    {

      const data=finalApp

      
    axios.post(urlSendForDisbursement,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      setSelectedStatusOpen(!selectedStatusOpen)
      alert(res.data.message)
      
     setTimeout(() => {
      window.location.reload(true)
     }, 500); 
    })
      
    }
    else
    alert("None Selected or Incorrect Format")
  }*/}
  
  const [isOpen,setIsOpen]=useState(true)

  if (tableData) {
    return (
      <Card className="card" style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
        <Topbar/>
        <CardBody>
        <div>
            <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>Pending Approvals</span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:"black",marginLeft:'25%'}}>Total Amount Disbursing : <span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>{totalAmountSelected ? <>&#8377;{formatNumberWithCommas(totalAmountSelected)}</> : "₹ 0"}</span></span></p>
            
           </div>
          <Row>
            <div style={{ display: 'flex', flex: 'wrap' }}>
            <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
          onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
              <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                    clearDateFilter()
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                      clearDateFilter()
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
              <Col>
              <div> 
              <button style={{display:'flex',alignItems:'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                  onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
                 
                >
                  Search Results
                </button>
                </div>
              </Col>
              {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<Col>
              <div style={{height:'34px',lineHeight:'33px',fontSize:'14px',fontFamily:"Inter",width:'200px',display:'flex',alignItems:'center'}}>

                <Button style={{marginLeft:'10px'}} disabled={selectedApp.length>0?false:true} onClick={() => setSelectedStatusOpen(!selectedStatusOpen)} color="primary">
                  Initiate Payout
                </Button></div>
              </Col>:null}
              
              {/* <Col>
              <div>
                <button style={{width:'100px',backgroundColor:'gray',height:'36.5px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} onClick={() => handleClearFilter()} color="primary">
                  Clear Filter
                </button>
                </div>
              </Col> */}
             
              <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"120px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>

             
            </div>
          </Row>
          
          <Modal size="md" isOpen={selectedStatusOpen}>
        <ModalHeader>Generate OTP</ModalHeader>
        <ModalBody >
          <Form>
            <FormGroup>
             { !otpSent?<div style={{width:'30em',padding:'1em' ,display:'flex'}}>
              <p style={{padding:'10px',margin:'10px',width:'35em',backgroundColor:'#E9E9E9',borderRadius: '10px',border:'none',}}>OTP will be sent to XXXXXX<span style={{fontWeight:'bold'}}>{decode.mobile.substr(6,10)} </span></p>
              <Button onClick={()=>handleSendOTP()} style={{margin:'10px',width:'15em'}} color="info" type="button">Send OTP</Button>
              </div>:null}
              {otpSent?<div style={{width:'30em',padding:'1em' ,display:'flex'}}>
              <Input style={{margin:'10px'}} type="text" onChange={(e)=>setOTP(e.target.value)} placeholder="Enter OTP"/>
              <Button onClick={()=>handleVerifyOtp()} style={{margin:'10px',width:'15em'}} color="info" type="button">Verify OTP</Button>
              </div>:null}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button color="primary" onClick={() => setSelectedStatusOpen(!selectedStatusOpen)}>Close Box</Button>
          <Button disabled={!confirmDisbursement} color="primary" onClick={() => handlePayouts(selectedApp)}>Confirm & Disburse</Button>
        </ModalFooter>
      </Modal>

      {/* <Modal isOpen={formStatusOpen}>
        <ModalHeader>Agreement Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
             { (docDetails||info)?<>

            
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyAgreementToggle() } >
              Copy
            </Button>
             {agreementTooltipOpen&&<span style={{fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}


             <p><span style={{fontWeight:'700'}}>Aadhaar Suffix:</span> {info?info.aadhaar_suffix:null}</p>
             <p><span style={{fontWeight:'700'}}>Name:</span> {info?info.name:null} </p>
             <p><span style={{fontWeight:'700'}}>Postal Code:</span>  {info?info.postal_code:null}</p>
             <p><span style={{fontWeight:'700'}}>Display Name: </span> {info?info.display_name:null}</p>
            <p style={{color:'white',backgroundColor:`${info?info.name_validation_score>=75?'lightGreen':info.name_validation_score>=60 &&info.name_validation_score<75?'yellow':info.name_validation_score<60?'#FF3333':null:null}`}}><span style={{fontWeight:'700' ,color:'black'}}>Aadhaar Name Validation:</span>  <span style={{color:'black',fontSize:'17px',marginLeft:'7px',fontWeight:'600'}}>{info?info.name_validation_score:null}</span></p>
             
             <p><span style={{fontWeight:'700'}}>Created At:</span>  {docDetails?docDetails.createdAt:null}</p>
            
             <p><span style={{fontWeight:'700'}}>Done At:</span>  {docDetails?docDetails.updatedAt:null}</p> {decode.role==="institute_user"||decode.role==="institute_admin"?null:<p style={{backgroundColor:'aliceBlue',height:'3em',padding:'0.5em'}}><a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null}>Click To Download Signed Agreement Document</a></p>}</>:<h3>Information is not yet available</h3>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeAgreeCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal> */}
{/*       
       <Modal isOpen={mandateStatusOpen}>
        <ModalHeader>Mandate Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
            { mandateDetails?<>
         
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyMandateToggle() }
            >
              Copy
            </Button>
               {mandateTooltipOpen&&<span style={{fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
            <p><span style={{fontWeight:'700'}}>Name: </span>{mandateDetails?mandateDetails.name:null}</p>
            <p><span style={{fontWeight:'700'}}>Account Number: </span>{mandateDetails?mandateDetails.accountNumber:null}</p>
             <p><span style={{fontWeight:'700'}}>Account Type: </span>{mandateDetails?mandateDetails.accountType:null}</p>
             <p><span style={{fontWeight:'700'}}>ifscCode:</span> {mandateDetails?mandateDetails.ifsc:null}</p>
             <p><span style={{fontWeight:'700'}}>Mobile:</span> {mandateDetails?mandateDetails.phone:null}</p>
            <p ><span style={{fontWeight:'700'}}>Mandate Mode:</span> {mandateDetails?mandateDetails.mandateType:null}</p>
            <p ><span style={{fontWeight:'700'}}>Customer Id:</span> {mandateDetails?mandateDetails.customerId:null}</p>
             <p><span style={{fontWeight:'700'}}>token Id:</span> {mandateDetails?mandateDetails.tokenId:null}</p>
             <p><span style={{fontWeight:'700'}}>Payment Id:</span> {mandateDetails?mandateDetails.paymentId:null}</p></>:<h3>Information is not available</h3>}
             
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeMandateCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal> */}
      {isLoading ? (
         <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
         <CircularProgress />
       </Box>
      ):(
        <>
         <div className='tables' style={{ marginTop: '20px' }}>
            <table >
              <thead className='table-heading'>
                <tr>
                  {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<th  id="select" style={{width:'2em',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>Select</th>:null}
                  <th  id="appId" style={{width:'8em'}} > 
                  <span style={{fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>Appl. Id</span></th>
                  <th  id="appname"style={{width:'20%',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>Appl. Name</th>
                  <th  id="insName"style={{width:'18%',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>{ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}</th>

                  <th  id="adjustmentAmt" style={{width:'8em',fontSize:'0.9vw',fontFamily:"Inter",whiteSpace:'nowrap'}}><span style={{marginLeft:'10px'}}></span>Bank Details</th>
                  
                  <th  id="amt" style={{width:'6em',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>Loan Amt.</th>
                  <th  id="adjustmentAmt" style={{width:'5em',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'5px'}}></span>Adj.Amt</th>
                  <th id="advEmi" style={{width:'3em',fontSize:'0.9vw',fontFamily:"Inter"}}>Adv.EMI</th>
                  <th  id="pfAmount" style={{width:'6em'}}><span style={{marginLeft:'10px',fontSize:'0.9vw',fontFamily:"Inter",whiteSpace:'nowrap'}}><span style={{marginLeft:'10px'}}></span>PF Amt</span></th>
                  <th  id="amt" style={{width:'6em',fontSize:'0.9vw',fontFamily:"Inter"}}><span style={{marginLeft:'10px'}}></span>Disb Amt.</th>
                  {/* { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th  id="fundSource" style={{width:'6em'}}><span style={{fontSize:'0.9vw',fontFamily:"Inter",whiteSpace:'nowrap'}}><span style={{marginLeft:'10px'}}></span>Fund Src</span></th>} */}
                  {/* { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th  id="actions" style={{width:'6em'}}><span style={{fontSize:'0.9vw',fontFamily:"Inter"}}>Actions</span></th>} */}
                </tr>
              </thead>
              { 
                tableData.map((item,index) => {
                
                  return (
                    
                      <tbody className='table-body' key={item.applicationId}>
                      {/* { (item.agreement==="Successful")&&(item.selfie==="Successful")&&(item.emandate==="Successful")? */}
                      
                        <tr className='table-row'  style={{ lineHeight: '20px' }}>
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&item.fundSourceCode==="GBP"&&(item.status==="configured"||item.status==="awaiting_approval"||item.status==="not_initiated")?<td style={{  fontSize: '0.9vw', width: '2em',fontFamily:"Inter" }}><input  style={{marginLeft:'1em',height:'18px',width:'18px',marginTop: '5px',}} onChange={(e)=>handleSelectedApp(e,item)} type="checkbox"/></td>:<td style={{  fontSize: '0.9vw', width: '2em' }}><input  style={{marginLeft:'1em',height:'18px',width:'18px',marginTop: '5px',}} disabled type="checkbox"/></td>}

                        
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?
                        <td >
                          <span id="hoverAppId" onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{ cursor:'pointer', fontSize: '0.9vw', fontWeight: '400', width: '10em' ,color:'#101828',fontFamily:"Inter" }}>{item.applicationId}
                         
                          <span  >{item.status==="not_initiated"||item.status==="awaiting_approval"?<span title="Not Initiated" style={{marginLeft:'5px',padding:'5px',fontFamily:"Inter",paddingLeft:'8px',paddingRight:'8px',borderRadius:'5em',backgroundColor:'grey',color:'white',fontWeight:'600'}}>N</span>:item.status==="initiated"?<span title="Initiated" style={{backgroundColor:'blue',marginLeft:'5px',padding:'5px',paddingLeft:'10px',paddingRight:'10px',borderRadius:'1em',color:'white',fontWeight:'600'}}>I</span>:item.status==="failure"?<span title="Failure" style={{marginLeft:'5px',padding:'5px',paddingLeft:'8px',paddingRight:'8px',borderRadius:'1em',backgroundColor:'red',color:'white',fontWeight:'600'}}>F</span>:item.status==="success"?<span title="Success" style={{marginLeft:'5px',padding:'5px',paddingLeft:'8px',paddingRight:'8px',borderRadius:'1em',backgroundColor:'#4BB543',color:'white',fontWeight:'600'}}>S</span>:null}</span>
                         </span>
                          
                        </td>
                        
                          :<td  style={{  fontSize: '0.9vw', fontWeight: '400',fontFamily:"Inter", width: '7em' }}>
                          {item.applicationId}
                        </td>
                        }

                         {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                            fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',
                            width: '17em',
                          }}
                         
                        >
                           <span style={{fontSize:"0.9vw",fontFamily:"Inter",lineHeight: '1.5em'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span style={{fontSize:"0.9vw",fontFamily:"Inter",lineHeight: '1.5em'}}>
                              {' '}
                             {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            fontFamily:"Inter",
                            cursor: 'pointer',
                            width: '10em',
                          }}
                        >
                          <span style={{fontSize:"0.9vw",fontFamily:"Inter",lineHeight: '1.5em'}} onClick={() => handleUser(item)}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span style={{fontSize:"0.9vw",fontFamily:"Inter",lineHeight: '1.5em'}} onClick={() => handleCoApp(item)}>
                              {' '}
                             {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>}
                        
                        <td style={{  fontSize: '0.9vw', width: '10em',fontFamily:"Inter",color:'#667085' ,lineHeight: '1.5em'}}>{item.instituteName}</td>
                        
                        
                        
                       <td style={{ width: "12em" ,fontFamily:"Inter"}} >
                          <span id="hoverBank"  style={{cursor:'pointer',fontFamily:"Inter",color:'#667085',fontSize:"0.9vw",marginTop:'15px',lineHeight: '1.5em'}}>{item.bankDetails?item.bankDetails.accountHolderName:"-"}
                          </span>
                          
                        </td>
                        <td style={{ width: "3em",fontFamily:"Inter",color:'#667085',  fontSize: '0.9vw' }}>{item.loanAmount ? ( <>&#8377;{formatNumberWithCommas(item.loanAmount)}</>) : ( '-')}</td>
                        <td style={{ width: "3em" ,fontFamily:"Inter",color:'#667085',  fontSize: '0.9vw'}}>{item.adjustedAmount ? <>&#8377;{formatNumberWithCommas(item.adjustedAmount)}</> : "-"}</td>
                        <td style={{ width: "3em" ,fontFamily:"Inter",color:'#667085',  fontSize: '0.9vw'}}>{item.advanceEmi?<>&#8377;{item.advanceEmi}</>:0} </td>
                        
                        <td style={{  fontSize: '0.9vw', width: '3em', textAlign: 'center',color:'#667085',fontFamily:"Inter" }}>
                          {item.processingFee ? <>&#8377;{formatNumberWithCommas(item.processingFee)}</> : "-"}
                        </td>
                        <td style={{ width: "3em",fontFamily:"Inter",color:'#667085',  fontSize: '0.9vw' }}>{item.disbursementAmount ? ( <>&#8377;{formatNumberWithCommas(item.disbursementAmount)}</>) : ( '-')}</td>

                        {/* { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<td style={{  fontSize: '0.9vw',color:'#667085',fontFamily:"Inter", width: '3em', textAlign: 'center' }}>
                          {item.fundSourceCode?item.fundSourceCode:"-"}
                        </td>}
                       { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:
                        <td>
                          <div>
                            <span><MovePendingStatus    ApplicantId={item} /></span>
                          </div>
                         
                        </td>} */}
                      </tr>
                     
                    </tbody>
                        
                  );

              
              })}
            </table>
            </div>
            {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}

            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              searchText={searchText}
              filterDateFrom={filterDateFrom}
            />
        </>
      )}
         
         
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default PendingTable;


