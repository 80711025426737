import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useState, useEffect } from 'react';
import Datetime from 'react-datetime';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import search from "../../../assets/images/search.png"
import 'react-datetime/css/react-datetime.css';
import statusimage from "../../../assets/images/status.png";
import rightarrow from "../../../assets/images/chevron-right.png";
import { useLocation,useNavigate } from 'react-router-dom';
import EditForm from '../EditForm/EditForm';
//import { useUserAuth } from '../../views/auth/AuthContext';
import Sort from "../../../assets/images/sort.png";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MoveReviewStatus from '../MoveReviewStatus/MoveReviewStatus';
import SendSms from '../SendSms/SendSms'
import download from "../../../assets/images/download.png";
import PaginationTable from '../PaginationTable';
import "./ReviewTable.css"
import Sidebar from '../../Sidebar';
import Topbar from '../../Topbar';
import axiosInstance from '../../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import alerticon from "../../../assets/images/alertIcon.png"

import {userActions} from '../../../actions/user.actions';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/en-gb');

const ReviewTable = () => {
  // const reviewstate = useSelector((state) => state?.Inreview?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
  const usersearchdefstate = useSelector((state) => state?.UserSearchDefault?.res?.data?.data);
  const profileState=useSelector((state)=>state?.PROFILE?.profileDetails?.res?.data);
  const [activePage, setActivePage] = useState(15); 
// console.log("Data poin", reviewstate)
// const state = useMemo(() => (res), []);

const [userDetails, setUserDetails] = useState(null)

const dispatch = useDispatch();

  
  //const { user, logOut } = useUserAuth();
  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  
  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.InReview);
  const localStatus = sessionStorage.setItem("status",status)
  const navigate = useNavigate();

  {/*const handleLogOut = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
    
  };*/}
  const handleAuth = () => {
 
    const now = dayjs();
    if (now.unix() > decode.exp + 3600) {
      alert('Session Expired Re-Login');
      //handleLogOut();
    }
  };

  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(true); 
  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };

  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  //pagination
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [clicked,setClicked] = useState(false)

  const [cra, newCra] = useState([]);
  const [selectedCRA, setSelectedCRA] = useState('');
   //sorting
  const [sort,setSort]=useState(-1)
  const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
 

  const[toggleSort,setToggleSort]=useState(false);

  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
        const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`
    
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.applicantDetails);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }


  const paginate = (pageNumber) => {
   
    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){
  const urlUsers = `/admin/application?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText === "" && filterDateFrom !== '' && filterDateTo !=='' && clicked ===  true){
      const urlUsers = `/admin/application?status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !=='' && clicked ===  true){
      const urlUsers = `/admin/application?instituteId=${id}&status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else{
      const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
   
// setcurrentPage(pageNumber);


};
  
  useEffect(() => {
   if(usersearchdefstate){
     console.log("Data poin 1")
     setIsLoading(false);
    setUserDetails(usersearchdefstate?.applicantDetails)
        setTableData(usersearchdefstate?.applicantDetails);
        setFilterTableData(usersearchdefstate?.applicantDetails);
        setcurrentPage(usersearchdefstate?.pagination?.currentPage);
        setTotalFetchRows(usersearchdefstate?.pagination?.totalRows);
        setrowsPerPage(usersearchdefstate?.pagination?.perPage);  
   }else{
    if(toggleSort) 
    {
      setSort(1);
    }
    else{
       setSort(-1) ;
    } 


    

    if(searchText === "" && filterDateFrom === '' && filterDateTo ==='' && selectedCRA ===''){
      
        
      const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
       
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
      
      

  
        
        if(profileState)
        {
          setErrorItem(profileState)
        }
        
   
     

    }
   }
   const userUrl = `/admin/users`;
   axiosInstance.get(userUrl,{
     headers: {
       Authorization: `Bearer ${user}`,
     },
   }).then((res)=>{
       newCra(res?.data?.data)
   })
        
  }, [toggleSort,urlUsers,datestate, searchText,profileState]);
  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }



  const handlepageChange = (number) => {
    setIsLoading(true);
    setActivePage(number);
    setrowsPerPage(number);
    let queryParams = {}
  
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

};



  const handleInputChange = (event) => {
    setIsLoading(true);
    setSearchText(event.target.value)
    let queryParams = {}
  
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

};
  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    setClicked(!clicked)
    setFilterDateFrom(startDate)
    setFilterDateTo(endDate)
    setIsLoading(true);
    let queryParams = {}
  
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = startDate;
      queryParams.toDueDate = endDate+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
  
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))
  };

 

  const handleCIBIL = (event) => {
    
       const Id = event.userId;
    const applId = event.applicationId
    const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Review`;
    
    dispatch(userActions.fetchHandleuser(profileUrl, user),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
   
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
     dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    navigate('/status/review/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaPreScreen:true ,dir:"In Review"},
    });
  };
 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)
  //co applicant cibil
  const handleCoAppCIBIL = (event) => {
       const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Review`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])
        const ckycUrl=`/ckyc/details?userId=${Id}`;
   
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   
     dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(urlProfile));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/review/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaPreScreen:true ,dir:"In Review",},
    });

  
  };

  const [openErrorMessage,setOpenErrorMessage]=useState(false)
  const [errorMessage,setErrorMessage]=useState(null)
  
  const [errorItem,setErrorItem]=useState(null)

  //form 
  

const [errorFormFirstName,setErrorFormFirstName]=useState(null)
const [errorFormLastName,setErrorFormLastName]=useState(null)
const [errorFormDOB,setErrorFormDOB]=useState(null)
const [errorFormAddress,setErrorFormAddress]=useState(null)
const [errorFormCity,setErrorFormCity]=useState(null)
const [errorFormState,setErrorFormState]=useState(null)
const [errorFormPincode,setErrorFormPincode]=useState(null)


  const showErrorApplicant = (uid,appid,error) => {
  const profileUrl=`/summary/user?id=${uid}&applicationId=${appid}&status=In Review`;
    dispatch(userActions.getProfileDetails(profileUrl));


    sessionStorage.setItem('refetchUid',uid)
    sessionStorage.setItem('refetchAppId',appid)
    
       const Id = uid;

  const urlCIBIL = `/cibil/report?userId=${Id}`
   
  axiosInstance.get(urlCIBIL)
  .then((res)=>{
    setErrorMessage(res?.data?.data?.summary)
    setOpenErrorMessage(true)
  })
  .catch((err)=>{
    alert("Error While Fetching CIBIL");
  })


  };
  const showErrorCoApplicant = (uid,appid,error) => {
  const profileUrl=`/summary/coapplicant?id=${uid}&status=In Review`;
    dispatch(userActions.getProfileDetails(profileUrl));


    sessionStorage.setItem('refetchUid',uid)
    sessionStorage.setItem('refetchAppId',appid)
    
       const Id = uid;

  const urlCIBIL = `/cibil/report?userId=${Id}`
   
  axiosInstance.get(urlCIBIL)
  .then((res)=>{
    setErrorMessage(res?.data?.data?.summary)
    setOpenErrorMessage(true)
  })
  .catch((err)=>{
    alert("Error While Fetching CIBIL");
  })


  };

  const reFetchCibil=()=>{
    const urlSave='/summary/update'
    const saveBody={
      applicationId:sessionStorage.getItem('refetchAppId'),
      userId:sessionStorage.getItem('refetchUid'),
      firstName:errorFormFirstName,
      lastName:errorFormLastName,
      dateOfBirth:errorFormDOB,
      currentCity:errorFormCity,
      currentAddress:errorFormAddress,
      currentState:errorFormState,
      currentPincode:errorFormPincode,
    }
    const urlFetch='/admin/application/retrigger-cibil'
    const body={
      applicationId:sessionStorage.getItem('refetchAppId'),
      userId:sessionStorage.getItem('refetchUid')
    }
    axiosInstance.post(urlSave,saveBody,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      if(res.data.message==="Successful")
      {
        axiosInstance.post(urlFetch,body,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      if(res.data.message==="Successful")
      {
        window.alert("Successfully Re-Fetched CIBIL")
        setOpenErrorMessage(true)
        sessionStorage.removeItem('refetchAppId')
        sessionStorage.removeItem('refetchUid')
        window.location.reload();


      }

    })
    .catch((err)=>{
      window.alert('Error Re-Fetching')
    })


      }

    })
    .catch((err)=>{
      window.alert('Error Saving Details')
    })

  }

  const handleUser = (event) => {
    console.log("appevent",event)

    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Review`;

    dispatch(userActions.fetchHandleuser(urlProfile, user),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Review`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
    
   
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
     dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])

    navigate('/status/review/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaCibil:false,dir:"In Review" },
    });

  };

 


  const handleCoApp = (event) => {
    console.log("coappevent",event)
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Review`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])
        const ckycUrl=`/ckyc/details?userId=${Id}`;
  
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    
   
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(urlProfile));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/review/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,viaCibil:false,dir:"In Review",clickEvent:"coapp" },
    });

   
  };

   //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("coappId",currCoAppId)
    sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/Review/appProfile")
    
  }
  //changepage


function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
const handleDownloadList=()=>{
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = String(currentDate.getFullYear());

  const formattedDate = `${day}-${month}-${year}`;
  document.getElementById("downloadButton").disabled=true;
  document.getElementById("downloadButton").innerText="Wait....";
  document.getElementById("downloadButton").style.backgroundColor="#D22129";
  document.getElementById("downloadButton").style.color= "white";
  document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
  document.getElementById("downloadButton").style.fontSize= "15px";
  document.getElementById("downloadButton").style.width= "90px";
  document.getElementById("downloadButton").style.cursor= "pointer";
  document.getElementById("downloadButton").style.height= "34px";
  document.getElementById("downloadButton").style.borderRadius= "5px";
  document.getElementById("downloadButton").style.textAlign= "center";
  document.getElementById("downloadButton").style.marginLeft="150px";
  document.getElementById("downloadButton").style.backgroundImage = "none"


  const downloadTemplateUrl=`/excel/download`
  const downloadData={
      instituteId: decode.instituteId?decode.instituteId:id,

      status:status,
      fromDate:filterDateFrom,
      toDate:filterDateTo+" "+"23:59:59"
     
  }
  axiosInstance.post(downloadTemplateUrl,downloadData, {
    responseType:'arraybuffer',
    headers:{
      Authorization:`Bearer ${user}`

  }
}).then((res)=>{
  setTimeout(() => {
    document.getElementById("downloadButton").disabled=false
    document.getElementById("downloadButton").innerText="Export";
    document.getElementById("downloadButton").style.paddingLeft = "30px";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft= "150px";
    document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
    document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
    // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
    document.getElementById("downloadButton").style.backgroundSize  ='15px' 
    document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

  }, 2000);
  const blob = new Blob([res.data], { type: "application/xlsx", });
  const url= window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href =url
  link.download = `${formattedDate}_${status}.xlsx`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
    
  
).catch((error)=>{
  alert("Error Downloading")
  document.getElementById("downloadButton").innerText="Error..";
  document.getElementById("downloadButton").style.backgroundColor="#D22129";
  setTimeout(() => {
document.getElementById("downloadButton").disabled=false
    document.getElementById("downloadButton").innerText="Export";
    document.getElementById("downloadButton").style.paddingLeft = "30px";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft= "150px";
    document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
    document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
    // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
    document.getElementById("downloadButton").style.backgroundSize  ='15px' 
    document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

  }, 3000);
})
}  

function formatNumberWithCommas(number) {
  return number.toLocaleString('en-IN'); 
}

const handleChange = (event) =>{
  setIsLoading(true);
  setSelectedCRA(event.target.value)
  let queryParams = {}
  
  if(filterDateFrom && filterDateTo){
    queryParams.fromDueDate = filterDateFrom;
    queryParams.toDueDate = filterDateTo+" "+"23:59:59"
  }
  
  if(id){
    queryParams.instituteId = id
  }

  if(status){
    queryParams.status = status
  }

  if(event.target.value){
    queryParams.cra = event.target.value
  }
  if(rowsPerPage && currentPage){
    queryParams.perPage = rowsPerPage;
    queryParams.pageNo = 1
  }
  axiosInstance.get('/admin/application',{
    params:queryParams,
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res)=>{    
    setTableData(res?.data?.data?.applicantDetails);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 300);
  })
  .catch((error)=>console.log(error))

 
}
const [isOpen,setIsOpen]=useState(true)

  if (tableData) {
    
    return (
      <Card className="card" style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
        <Topbar/>
        <Modal isOpen={openErrorMessage}>
          <ModalHeader> Error While Fetching CIBIL</ModalHeader>
        <ModalBody>
          <p style={{padding:'1em',color:'#d32028',textAlign:'center'}}>{errorMessage}</p>
          <div style={{backgroundColor:''}}>
            <p>To Re-Fetch CIBIL Edit Details and Save </p>
            <div style={{display:'block'}}>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>First Name</p>
                <input onChange={(e)=>setErrorFormFirstName(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.firstName}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>Last Name</p>
                <input onChange={(e)=>setErrorFormLastName(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.lastName}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>DOB</p>
                <input onChange={(e)=>setErrorFormDOB(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.dateOfBirth}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>Address</p>
                <input onChange={(e)=>setErrorFormAddress(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.currentAddress}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>City</p>
                <input onChange={(e)=>setErrorFormCity(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.currentCity}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>State</p>
                <input onChange={(e)=>setErrorFormState(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.currentState}/>
              </div>
              <div style={{display:'flex',justifyContent: 'space-between',margin:'1em'}}>
                <p style={{fontWeight:'bold'}}>Pincode</p>
                <input onChange={(e)=>setErrorFormPincode(e.target.value)} style={{width:'80%',borderRadius:'5px',border:'2px solid #F0F0F0',paddingLeft:'5px'}} type="text" defaultValue={errorItem?.currentPincode}/>
              </div>
            </div>
            <div style={{display:'flex',justifyContent: 'center',}}>
            <Button style={{border:'none',backgroundColor:'#d32028',color:'white'}} onClick={()=>reFetchCibil()}> Save and Re-Fetch CIBIL</Button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
         <Button onClick={()=>setOpenErrorMessage(false)}> Close</Button>
         {/* <Button> Re-Fetch</Button> */}
        </ModalFooter>
        </Modal>
        <CardBody>
        <div>
          <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>{status}</span></p>
          
         </div>
         <Row>
          <div style={{ display: 'flex', flex: 'wrap'}}>
          <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 'Product Type':'Institute Name'
          } onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
               
              >
                Search Results
              </button>
              </div>
            </Col>
            <Col>
            <div>
          <Input type="select" name="select" id="exampleSelect" value={selectedCRA} onChange={(event)=>handleChange(event)}>
          <option value="">Select CRA</option> 
            {cra?.map((item,index)=>(
              <option>{item.name}</option>
            ))}
          </Input>
          </div>
            </Col>
            <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"150px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>
        {isLoading ? (
           <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
           <CircularProgress />
         </Box>
        ):(
          <>
          <div className='tables' style={{ marginTop: '20px' }}>
                     <table >
                       <thead className='table-heading'>
                         <tr>
                           <th  id="appId" style={{width:'8%',fontSize:'0.9vw',fontFamily:'Inter',borderTopLeftRadius:'8px'}} ><span style={{marginLeft:'10px'}}></span> Appl. Id
                           {/* <img style={{cursor:'pointer',width:'0.9vw',marginLeft:'2px'}} src={Sort} alt="sort"/> onClick={()=>setToggleSort(!toggleSort)} */}
                           </th>
                           <th  style={{  fontSize: '0.9vw',width:'12%' }} id="appname"><span  style={{marginLeft:'20px'}}></span> Appl. Name</th>
                           {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                            <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10em' }} id="appname">
                            <span style={{marginLeft:'10px'}}></span> Student Name
                            </th>:null
                            }
                           <th  style={{  fontSize: '0.9vw' ,width:'10%'}} id="insName"><span  style={{marginLeft:'10px'}}></span>{ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}</th>
                           {decode.role==="institute_user"||decode.role==="institute_admin"?null:<th  style={{  fontSize: '0.9vw',width:'10%' }} id="cibil"><span  style={{marginLeft:'10px',whiteSpace:'nowrap'}}></span>Pre-Screen</th>}
                           <th  style={{  fontSize: '0.9vw',width:'8%' }} id="coursename"><span  style={{marginLeft:'10px'}}></span>Course</th>
                           <th   id="appfromdate" style={{  fontSize: '0.9vw' ,width:'10%'}}>
                          <span  style={{marginLeft:'10px'}}></span>Date
                             {/* <img style={{cursor:'pointer',width:'14px',marginLeft:'2px'}} src={Sort} alt="sort"/> onClick={()=>setToggleSort(!toggleSort)}*/}
                           </th>
                           <th style={{fontSize:'0.9vw',width:'10em'}}  id="amt"><span  style={{marginLeft:'10px'}}></span>Amount</th>
                           {decode.role==="institute_user"||decode.role==="institute_admin"?null:<th id="cra" style={{textAlign:'center', fontSize: '0.9vw',width:'10em'}} >CRA</th>}
                           
                           { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"?null:<th style={{ textAlign: 'center',width:'10em', fontSize: '0.9vw' }} id="actions"  >
                              Actions
                           </th>}
                         </tr>
                       </thead>
                       {tableData.map((item,index) => {
                        
                           return (
                             <tbody className='table-body' key={item.applicationId}>
                               <tr className="table-row" style={{ lineHeight: '20px'}}>
                                 {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td>
                                   <span onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{ fontFamily:"Inter",cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', width: '8em' ,color:'#101828'}}>{item.applicationId}</span>
                                 </td>:<td  style={{  fontSize: '0.9vw', fontWeight: '400', width: '12em',fontFamily:"Inter"  }}>
                                   {item.applicationId}
                                 </td>
                                 }
                                 {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                                   style={{
                                     fontFamily:"Inter",fontWeight:'400',color:'#667085',fontSize:'0.9vw',
                                    
                                   }}
                                  
                                 >
                                    <span title={item.name} style={{ width: '15em',fontFamily:"Inter",fontWeight:'400',color:'#667085',fontSize:'0.9vw',lineHeight: '1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}
                                   {item.coapplicantName ? (
                                     <span title={item.coapplicantName} style={{ width: '15em',fontFamily:"Inter",fontWeight:'400',color:'#667085',fontSize:'0.9vw',lineHeight: '1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                       
                                       {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                                     </span>
                                   ) : (
                                     ''
                                   )}
                                 </td>:<td
                                   style={{
                                      fontSize: '0.9vw',
                                     color: '#101828',
                                     cursor: 'pointer',
                                    
                                     fontFamily:"Inter",
                                   }}
                                 >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <span
                                    title={item.name}
                                    style={{
                                      width: '15em',
                                      fontFamily: 'Inter',
                                      fontWeight: '400',
                                      color: '#101828',
                                      fontSize: '0.9vw',
                                      lineHeight: '1.5em',
                                      display: 'block',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      
                                    }}
                                    onClick={() => handleUser(item)}
                                  >
                                  {item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                 
                                  </span>
                                  <span>{item.panValid === 0 ?  <img src={alerticon} style={{ maxWidth: '100%', height: 'auto' }} />:null}</span>
                                </span>
                                   {item.coapplicantName ? (
                                
                                     <span style={{ display: 'flex', alignItems: 'center' }}>
                                     <span
                                       title={item.coapplicantName}
                                       style={{
                                         width: '15em',
                                         fontFamily: 'Inter',
                                         fontWeight: '400',
                                         color: '#101828',
                                         fontSize: '0.9vw',
                                         lineHeight: '1.5em',
                                         display: 'block',
                                         whiteSpace: 'nowrap',
                                         overflow: 'hidden',
                                         textOverflow: 'ellipsis',
                                        
                                       }}
                                       onClick={() => handleCoApp(item)}
                                     >{item.coapplicantPanValid === 0 ? (<>
                                     {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}( C )
                                     <img src={alerticon} style={{ maxWidth: '100%', height: 'auto' }} />
                                     </>):(
                                       <>{item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}( C )
                                       </>
                                     )}
                                     </span>
                                     
                                   </span>
                                    
                                   ) : (
                                     ''
                                   )}
                                 </td>}
                                 {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                         <span style={{width: '10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                        </td>:null}
                                 <td title={item.instituteName}>
                                  <span  style={{fontWeight: '400',  fontSize: '0.9vw', color: '#667085' ,fontFamily:"Inter",width:'15em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.instituteName ? item.instituteName: "-" }</span> 
                                 </td>
                                 
                                  {decode.role==="institute_user"||decode.role==="institute_admin"?null:<td
                                   style={{
                                      fontSize: '0.9vw',
                                     width: '6em',
                                     color: 'blue',
                                     cursor: 'pointer',
                                     fontFamily:"Inter"
                                     ,
                                   }}
                                  
                                 >
                                    {item.applicantCibil!=="Error"? <span title={item.applicantCibil===null&&(Object.prototype.hasOwnProperty.call(item, 'applicantCibil'))?"Does not exist":item.applicantCibil} style={{fontFamily:"Inter",fontSize:'0.9vw'}} onClick={() => handleCIBIL(item)}>{item.applicantCibil===null&&(Object.prototype.hasOwnProperty.call(item, 'applicantCibil'))?"--DNE--":item.applicantCibil}</span>:<span onClick={()=>showErrorApplicant(item.userId,item.applicationId,item)} style={{}}>Error</span>}
                                   {item.coapplicantName ? (
                                      item.coapplicantCibil!=="Error" ?<span  title={item.coapplicantCibil===null&&(Object.prototype.hasOwnProperty.call(item, 'coapplicantCibil'))?"Does not exist":item.coapplicantCibil} style={{fontFamily:"Inter",fontSize:'0.9vw',}} onClick={() => handleCoAppCIBIL(item)}>
                                       {' '}<br/>
                                      {item.coapplicantCibil===null&&(Object.prototype.hasOwnProperty.call(item, 'coapplicantCibil'))?"--DNE--":item.coapplicantCibil}</span>:<span onClick={()=>showErrorCoApplicant(item.coapplicantId,item.applicationId,item)} style={{}}>Error</span>
                                    
                                   ) : (
                                     ''
                                   )}

                                 </td>
                       }
                                 <td title={item.courseName}>
                                  <span  style={{fontWeight: '400',  fontSize: '0.9vw', color: '#667085' ,fontFamily:"Inter",width:'12em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.courseName ? item.courseName: "-" }</span> 
                                 </td>
                                 <td title={item?.applicationDate?.substr(10, 15)}
                                   style={{fontWeight: '400' , color: '#667085',fontFamily:"Inter", fontSize: '0.9vw' }}>
                             {item.applicationDate?.substr(0, 10)?moment(item?.applicationDate?.substr(0, 10)).format('DD MMM YY'):"-"}
                                 </td>
                                 <td style={{  fontSize: '0.9vw', fontWeight: '400', width: '7em', color: '#667085',fontFamily:"Inter"  }}>
                                 {item.courseFees ? ( <>&#8377;{formatNumberWithCommas(item.courseFees)}</>) : ( '-')}
                                 </td>
                                  {decode.role==="institute_user"||decode.role==="institute_admin"?null:<td title={item.CRA} style={{  fontSize: '0.9vw',fontFamily:"Inter", color: '#667085', fontWeight: '500',textAlign:'center', width: 'auto' }}>
                                   {item.CRA?item.CRA[0]+item.CRA.toUpperCase().substr(item.CRA.indexOf(" "),item.CRA.indexOf("")+2):null}
                                 </td>}
                                 
         
                                 {decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"?null:
                                 <td >
                                  <div style={{display:'flex',flexDirection: 'row',justifyContent:'space-between'}}>
                                    <span title="Edit Form" style={{cursor:'pointer'}}><EditForm curruser={item} /></span> 

                                    <span title="Move Status" style={{cursor:'pointer'}}><MoveReviewStatus ApplicantId={item} /></span> 
                                   
                                    <span title="Request Bank Statement" style={{cursor:'pointer',paddingRight:'10px'}}><SendSms ApplicantId={item}  /></span> 
                                 </div>
                                   
                                 </td>}
                               </tr>
                              
                             </tbody>
                           );
                       })}
                     </table>
                     </div>
                     {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ): (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
         
                     <PaginationTable
                       startPage={currentPage}
                       rowsPerPage={rowsPerPage}
                       totalRows={totalFetchRows}
                       paginate={paginate}
                       searchText={searchText}
                       filterDateFrom={filterDateFrom}
                     />
                   </>
         
        )}
         
          
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default ReviewTable;






