import React, { useState,useEffect} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './EditForm.css'
import close from "../../../assets/images/close.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import edit from "../../../assets/images/edit2.png"

import {userActions} from '../../../actions/user.actions';

import {
  Modal,Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { startTransition } from 'react';
import axiosInstance from '../../../helpers/axios';
import jwtDecode from 'jwt-decode';

//import { useUserAuth } from '../../views/auth/AuthContext.js';


export default function EditForm({curruser}) {
   
  //const state = useSelector((state) => state?.Indropped?.res);
    const editstate = useSelector((state) => state.Editform?.res);
    const savedstate = useSelector((state)=> state.Updateeditform?.res)


    const dispatch = useDispatch();

  const location = useLocation();
  const [formOpen, setFormOpen] = useState(false);
 
  const [instituteName, setInstituteName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseFees, setCourseFees] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [salary, setSalary] = useState('');
  const [studentName, setStudentName] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [panId, setPanId] = useState('');
  const [currentPincode, setCurrentPincode] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [currentCity, setCurrentCity] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [officePincode, setOfficePincode] = useState('');
  const [officeState, setOfficeState] = useState('');
  const [officeCity, setOfficeCity] = useState('');
  const [aadhaarId, setAadhaarId] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newStudentName, setNewStudentName] = useState('');
  const [newMobileNumber, setNewMobileNumber] = useState('');
  const [newCourseFees, setNewCourseFees] = useState('');
  const [newCourseName, setNewCourseName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newPincode, setNewPincode] = useState('');
  const [cra, newCra] = useState([]);
  const maritalOptions = [{label:"Married",value:"Married"},{label:"Single",value:"Single"},{label:"Divorced",value:"Divorced"},{label:"Widowed/Others",value:"Widowed/Others"}]
  const [selectedCRA, setSelectedCRA] = useState('');
  const [tenure,setTenure] = useState("")
  const [newTenure,setNewTenure] = useState("")

  //const { user } = useUserAuth();
  
  const user = sessionStorage.getItem("user")
  const decode = jwtDecode(user)
  console.log("curruser",curruser)
  const Id = curruser?.userId;
  const appId=curruser?.applicationId
  const instName = curruser?.instituteName
  const urlProfile = `/summary/user?id=${Id}&status=${curruser?.status}`;

  const popUpForm = () => {
    setFormOpen(true);
    const urlProfile = `/summary/user?id=${Id}&status=${sessionStorage.getItem("status")}`;
    axiosInstance.get(urlProfile).then((res) => {
      setInstituteName(res.data.data.instituteName);
      setCourseName(res.data.data.courseName);
      setCourseFees(res.data.data.courseFees);
      setEmail(res.data.data.email);
      setDob(res.data.data.dateOfBirth);
      setGender(res.data.data.gender);
      setTenure(res?.data?.data?.loanTenure)
      setFirstName(res.data.data.firstName);
      setLastName(res.data.data.lastName);
      setMobile(res.data.data.mobile);
      setCurrentAddress(res.data.data.currentAddress);
      setOfficeAddress(res.data.data.officeAddress);
      setMaritalStatus(res.data.data.maritalStatus);
      setStudentName(res.data.data.studentName);
      setSalary(res.data.data.salary);
      setPanId(res.data.data.panId);
      setAadhaarId(res.data.data.aadhaarId);
      setCurrentPincode(res.data.data.currentPincode);
      setCurrentCity(res.data.data.currentCity);
      setCurrentState(res.data.data.currentState);
      setOfficePincode(res.data.data.officePincode);
      setOfficeCity(res.data.data.officeCity);
      setOfficeState(res.data.data.officeState);
    });

    const userUrl = `/admin/users`;
    axiosInstance.get(userUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res)=>{
        newCra(res?.data?.data)
    })


  };
  const updateMobile=`/summary/update`;
  const handleSave=async ()=>{
    const data={
      applicationId:appId,
      userId:Id,
      instituteName:instName,
      mobile:newMobileNumber?newMobileNumber:document.getElementById("phone").value,
      firstName:newFirstName?newFirstName:document.getElementById("firstName").value,
      lastName:newLastName?newLastName:document.getElementById("lastName").value,
      studentName:newStudentName?newStudentName:document.getElementById("studentname").value,
      courseFees:newCourseFees?newCourseFees:document.getElementById("courseFees").value,
      courseName:newCourseName?newCourseName:document.getElementById("courseName").value,
      currentAddress:newAddress?newAddress:document.getElementById("address").value,
      officeAddress:officeAddress?officeAddress:document.getElementById("officeaddress").value,
      email:newEmail?newEmail:document.getElementById("email").value,
      currentCity:newCity?newCity:document.getElementById("city").value,
      currentState:newState?newState:document.getElementById("state").value,
      currentPincode:newPincode?newPincode:document.getElementById("zip").value,
      officeCity:officeCity?officeCity:document.getElementById("officecity").value,
      officeState:officeState?officeState:document.getElementById("officestate").value,
      officePincode:officePincode?officePincode:document.getElementById("officezip").value,
      loanTenure:newTenure?newTenure:document.getElementById("tenure").value,
      maritalStatus
    }
    console.log(data)

    await axiosInstance.post(updateMobile,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>
    {
      alert(`Details Updated ${res.data.message}` )
      setTimeout(() => {
        window.location.reload(true)
      }, 1000);

    })

    if (selectedCRA) {
      const updateCra = `/admin/application/update`;
      const craData = {
        applicationId: appId,
        cra: selectedCRA, // Use the stored value
      };
  
      axiosInstance.post(updateCra, craData, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          console.log(res)
        });
    }
  }

  return (
    <div>
      <img style={{height:'1vw',width:'1vw'}} onClick={() => popUpForm()} src={edit}/>
      {formOpen && (
      <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal 
      style={{
    
        top: '5%',
        width:'60vw',
        
        backgroundColor: 'white',}} size="lg" className='edit-form ' isOpen={formOpen}>
          
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Edit Entry</p>
          <img  className="image1" onClick={()=>{
            setFormOpen(!formOpen)
          setSelectedCRA('')
          }} src={close}/>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
          <div style={{marginLeft:'10px'}}>
          <div style={{width:'220px',marginLeft:'10px'}} >
          <Label for="exampleSelect"style={{lineHeight:'1px'}}><span style={{fontFamily:"Inter-Medium",fontSize:"13px"}}>Re-assign CRA</span></Label>
          <Input type="select" name="select" id="exampleSelect" value={selectedCRA} onChange={(e) => setSelectedCRA(e.target.value)}>
          <option value="">Select CRA</option> 
            {cra?.map((item,index)=>(
              <option>{item.name}</option>
            ))}
            
          
          </Input>
              </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",marginBottom:'10px'}}>
              
            <div style={{marginRight:"15px"}}>
              <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>{`${decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 'Loan Type': 'Institute Name'}`}</p>
              <Input
                id="instituteName"
                value={instituteName}
                disabled
                className='input'
                placeholder="Institue Name"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
            </div>
            <div style={{marginRight:"15px"}}>
              <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}> Course</p>
              <Input
                id="courseName"
                defaultValue={courseName}
                onChange={(e)=>setNewCourseName(e.target.value)}
                placeholder="Course Name"
                className='input'
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
            </div>
            <div style={{marginRight:"15px"}}>
              <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>{`${decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 'Loan Amount': 'Course Fees'}`}</p>
              <Input
                id="courseFees"
                defaultValue={courseFees}
                className='input'
                onChange={(e)=>setNewCourseFees(e.target.value)}
                placeholder="Course Fees"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
            </div>
            </div>
            
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",marginBottom:'10px'}}>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>First Name</p>
                <Input
                id="firstName"
                className='input'
                onChange={(e)=>setNewFirstName(e.target.value)}
                defaultValue={firstName}
                placeholder="First Name"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Last Name</p>
                <Input
                id="lastName"
                className='input'
                onChange={(e)=>setNewLastName(e.target.value)}
                defaultValue={lastName}
                placeholder="Last Name"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}> Gender</p>
                <Input
                id="gender"
                disabled
                className='input'
                value={gender}
                placeholder="Gender"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",marginBottom:'10px'}}>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Email</p>
                <Input
                id="email"
                defaultValue={email}
                className='input'
                onChange={(e)=>setNewEmail(e.target.value)}
                placeholder="Email Address"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Phone Number</p>
                <Input
                id="phone"
                disabled={location.pathname.split('/')[2]==="processing"?true:false}
                defaultValue={mobile}
                className='input'
                onChange={(e)=>setNewMobileNumber(e.target.value)}
                placeholder="Phone Number"
                style={{fontSize:"14px",fontFamily:"Inter-Medium"}}
              />

              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}> Date of Birth</p>
                <Input id="dob" className='input' disabled value={dob} placeholder="Applicant DOB"
                 style={{fontSize:"14px",fontFamily:"Inter-Medium"}} />
              </div>
            </div>
            <div style={{display:'flex',justifyContent: 'space-evenly',flexDirection: 'row',paddingTop:'1rem',}}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",marginBottom:'10px',width:'350px',backgroundColor:'#DBDBDB',padding:'4px',borderRadius:'8px',paddingLeft:'1rem',paddingBottom: '1rem',}}>
              <div style={{marginRight:"15px",width:"300px"}}>
                <b>APPLICANT ADDRESS</b>

                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Address</p>
                <Input
                id="address"
                defaultValue={currentAddress}
                onChange={(e)=>setNewAddress(e.target.value)}
                className='input1'
                placeholder="Address"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>City</p>
                <Input
                id="city"
                className='input'
                defaultValue={currentCity}
                onChange={(e)=>setNewCity(e.target.value)}
                placeholder="City"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
                <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>State</p>
                <Input
                id="state"
                defaultValue={currentState}
                onChange={(e)=>setNewState(e.target.value)}
                className='input'
                placeholder="State"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Pin Code</p>
                <Input
                id="zip"
                defaultValue={currentPincode}
                onChange={(e)=>setNewPincode(e.target.value)}
                className='input'
                placeholder="Zip"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",marginBottom:'10px',marginLeft:'20px',width:'350px',backgroundColor:'#DBDBDB',padding:'4px',borderRadius:'8px',paddingLeft:'1rem',paddingBottom: '1rem',}}>
              <div style={{marginRight:"15px",width:"300px"}}>
                <b>OFFICE ADDRESS</b>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}> Office Address</p>
                <Input
                id="officeaddress"
                defaultValue={officeAddress}
                onChange={(e)=>setOfficeAddress(e.target.value)}
                className='input1'
                placeholder="Office Address"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Office City</p>
                <Input
                id="officecity"
                className='input'
                defaultValue={officeCity}
                onChange={(e)=>setOfficeCity(e.target.value)}
                placeholder="Office City"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
                <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Office State</p>
                <Input
                id="officestate"
                defaultValue={officeState}
                onChange={(e)=>setOfficeState(e.target.value)}
                className='input'
                placeholder=" Office State"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px",width:"300px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Office Pin Code</p>
                <Input
                id="officezip"
                defaultValue={officePincode}
                onChange={(e)=>setOfficePincode(e.target.value)}
                className='input'
                placeholder="Office Zip"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
            </div>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginBottom:'10px',marginLeft:'1rem',marginRight:'1rem'}}>
                <div style={{marginRight:"15px",width:"220px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Marital Status</p>
                <Input type="select" name="select" id="exampleSelect" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)} style={{width:'220px'}}>
              <option value="">Select Marital Status</option> 
                {maritalOptions?.map((item,index)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
                
              
              </Input>
              </div>
              <div style={{marginRight:"15px"}}>
                
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Student Name</p>
                <Input
                id="studentname"
                className='input'
                onChange={(e)=>setNewStudentName(e.target.value)}
                defaultValue={studentName}
                placeholder="Employer Name"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Tenure</p>
                <Input
                id="tenure"
                type='number'
                className='input'
                onChange={(e)=>setNewTenure(e.target.value)}
                disabled={sessionStorage.getItem("status")==="In Process" ? true : false}
                defaultValue={tenure}
                placeholder="Tenure"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
            </div>
            
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Salary</p>
                <Input
                id="salary"
                className='input'
                disabled
                value={salary}
                placeholder="Salary"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Aadhar Number</p>
                <Input
                id="aadhaar"
                className='input'
                disabled
                value={aadhaarId}
                placeholder="Aadhaar Number"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
              <div style={{marginRight:"15px"}}>
                <p style={{marginBottom:"-10px",fontFamily:"Inter-Medium",fontSize:"13px"}}>Pan Number</p>
                <Input
                id="pan"
                disabled
                value={panId}
                className='input'
                placeholder="Pan Number"
                style={{fontSize:"14px",fontFamily:'Inter-Medium'}}
              />
              </div>
            </div>
          </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{backgroundColor:'#E9E9E9',borderBottomLeftRadius:"10px",borderBottomRightRadius:'10px'}}>
          <button  style={{marginLeft:"330px",marginBottom:"8px",marginTop:'8px',textAlign:"center",color:"white",borderRadius:"30px",backgroundColor:"#D32028",height:"40px",width:"100px",borderStyle:"none"}}onClick={() => handleSave()}>
            <span style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Save</span>
          </button>{' '}
          
        </div>
        
      </Modal>
      </>
       )}
    </div>
  );
}


