export const tenureOptions = [
    {label:'1'}, {label:'2'},{label:'3'},{label:'4'},{label:'5'},{label:'6'},{label:'7'},{label:'8'},{label:'9'},{label:'10'},
    {label:'11'}, {label:'12'},{label:'13'},{label:'14'},{label:'15'},{label:'16'},{label:'17'},{label:'18'},{label:'19'},{label:'20'},
    {label:'21'}, {label:'22'},{label:'23'},{label:'24'},
  ]
export const productCategoryOptions = [
    { label: 'Subvention GST' }, { label: 'Subvention Non GST' }, { label: 'Standard EMI' }, { label: 'B2B' }, { label: 'Hybrid' }
];


  export const productTenureOptions = [
    { label: '1' }, { label: '2' }, { label: '3' }, { label: '4' }, { label: '5' }, { label: '6' }, 
    { label: '7' }, { label: '8' }, { label: '9' }, { label: '10' }, { label: '11' }, { label: '12' }, 
    { label: '13' }, { label: '14' }, { label: '15' }, { label: '16' }, { label: '17' }, { label: '18' }, 
    { label: '19' }, { label: '20' }, { label: '21' }, { label: '22' }, { label: '23' }, { label: '24' }, 
    { label: '25' }, { label: '26' }, { label: '27' }, { label: '28' }, { label: '29' }, { label: '30' }, 
    { label: '31' }, { label: '32' }, { label: '33' }, { label: '34' }, { label: '35' }, { label: '36' }
  ];


export const advanceEmiOptions = [
  { label: '0' },{ label: '1' }, { label: '2' }, { label: '3' }, { label: '4' }, { label: '5' }, { label: '6' }, 
  { label: '7' }, { label: '8' }, { label: '9' }, { label: '10' }, { label: '11' }, { label: '12' }, 
];

export const pfTypeOptions = [
  { label: 'Flat' },{ label: 'Percentage' }, { label: 'Varied' }, 
];
  
export const subVerticals = {
    2: {
        New: 1,
        Old: 2
    },
    3: {
        New: 1,
        Old: 2
    }
  }
  
export const propertyTypeOptions = [
    { label: 'Residential Property' },
    { label: 'Commertial Property'},
    { label: 'Industrial Property' },
    { label: 'Warehouse Property' },
  ]
  
 export  const vehicleTypeOptions = [
    { label: '2 Wheeler' },
    { label: '4 Wheeler'},
    {label: 'CE - construction equipment'},
    {label: 'LCV - light commercial vehicle'},
    {label: 'HCV - heavy commercial vehicle'},
  
  ]


export const statesList =  [
  { label: "ANDAMAN AND NICOBAR ISLANDS" },
  { label: "ANDHRA PRADESH" },
  { label: "ARUNACHAL PRADESH" },
  { label: "ASSAM" },
  { label: "BIHAR" },
  { label: "CHANDIGARH" },
  { label: "CHATTISGARH" },
  { label: "DAMAN & DIU" },
  { label: "DADRA & NAGAR HAVELI" },
  { label: "DELHI" },
  { label: "GOA" },
  { label: "GUJARAT" },
  { label: "HARYANA" },
  { label: "HIMACHAL PRADESH" },
  { label: "JAMMU & KASHMIR" },
  { label: "JHARKHAND" },
  { label: "KARNATAKA" },
  { label: "KERALA" },
  { label: "LADAKH" },
  { label: "LAKSHADWEEP" },
  { label: "MANIPUR" },
  { label: "MAHARASHTRA" },
  { label: "MEGHALAYA" },
  { label: "MIZORAM" },
  { label: "MADHYA PRADESH" },
  { label: "NAGALAND" },
  { label: "ORISSA" },
  { label: "PONDICHERRY" },
  { label: "PUNJAB" },
  { label: "RAJASTHAN" },
  { label: "SIKKIM" },
  { label: "TAMIL NADU" },
  { label: "TELANGANA" },
  { label: "TRIPURA" },
  { label: "UTTAR PRADESH" },
  { label: "UTTARANCHAL" },
  { label: "WEST BENGAL" },
  { label: "APO Address" },
]

export const policyDetails = [
  {
    status: 'In Submission',
    policies: [
      {label: 'Age must 18 above', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
      {label: 'Policy 2', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
    ]
  },
  {
    status: 'In Sanction',
    policies: [
      {label: 'Policy 1', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
      {label: 'Policy 2', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
    
    ]
  },
  {
    status: 'Rejected',
    policies: [
      {label: 'Policy 1', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
      {label: 'Policy 2', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
    
    ]
  },
  {
    status: 'Disbursed',
    policies: [
      {label: 'Policy 1', details: ['Applicant age should be above 18', 'Age is taken from the PAN verification']},
    ]
  }
]
