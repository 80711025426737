
import { useState, useEffect,useCallback  } from 'react';
import activedot from "../../assets/images/activeDot.png"
import closeddot from "../../assets/images/closedDot.png"
import initiateddot from "../../assets/images/initiatedDot.png"
import pendingdot from "../../assets/images/pendingDot.png"
import axiosInstance from '../../helpers/axios';






const Allemis= ({item}) =>{
    const [repaymentTable,setRepaymentTable] = useState([])
    const currentDate = new Date();
   
    
    const user = sessionStorage.getItem("user")
    console.log("items",item)
    const { loanId: extractedLoanId } = item;
    const { legacyLoanId: legacyLoanId } = item;

    const options = {
       year: 'numeric',
       day: '2-digit',
       month: '2-digit'
      
    };
    
    const formattedDate =  currentDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
    console.log("formate",(formattedDate))

    useEffect(()=>{
        const url = `/emis/for-loan?loanId=${extractedLoanId}`

        axiosInstance.get(url,{
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }).then((res)=>{
          setRepaymentTable(res?.data?.data)
        })
    
    },[])

  
  


return(
    <>

                    <div  >
                    <table hover  className='tables1' >
                    <thead >
                    <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px',paddingLeft: '10px', paddingRight: '20px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Loan id</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px',borderTopLeftRadius:'8px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EmiNo.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px',borderTopLeftRadius:'8px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EmiAmt.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px' ,borderTopRightRadius:"8px",paddingLeft: '10px', paddingRight: '20px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>PaidAmt</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px' ,paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Dpd</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085' ,paddingLeft:'10px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Penalty</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>B.chrge</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Due Date</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px',paddingLeft: '10px', paddingRight: '20px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Status</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085',paddingLeft:'10px' ,borderTopRightRadius:"8px",paddingLeft: '10px', paddingRight: '20px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>TotalAmt</span></th>
                    
                    
                    
                    </tr>
                    </thead>
                    <tbody>
                      {repaymentTable.map((item,index)=>{
                        console.log(item?.dueDate,'duedate')
                        // const moment = require('moment');

                        // const dateString = item?.dueDate;
                        // const formattedDate = moment(dateString, 'DD/MM/YYYY').format('YYYY/MM/DD');
                        
                        // console.log(dateString,'formattedDate');
                        
                        // const current = new Date()
                        // const year1 = current.getFullYear();
                        // const month1 = String(current.getMonth() + 1).padStart(2, '0');
                        // const day1 = String(current.getDate()).padStart(2, '0');
                        // const result1= `${year1}-${month1}-${day1}`;
                        
                        

                        // const date1=new Date(formattedDate)
                        // const date2=new Date(result1)
                        // const days=(date1-date2)/(1000* 3600* 24)
                        const dueDate = item?.dueDate;
                        const emiRepaidDate = 
                        item?.emiRepaidDate && item?.emiRepaidDate !== "Invalid date" 
                          ? item?.emiRepaidDate 
                          : item?.emiRepaidDate === "Invalid date"
                            ? new Date().toLocaleDateString("en-GB")
                            : '-';

                        // Convert strings to Date objects
                        const dueDateObj = new Date(dueDate.split('/').reverse().join('-'));
                        const emiRepaidDateObj = new Date(emiRepaidDate.split('/').reverse().join('-'));

                        // Calculate the difference in time (in milliseconds)
                        const differenceInTime = emiRepaidDateObj - dueDateObj;

                        // Convert milliseconds to days
                        const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
                        const result = differenceInDays > 0 ? differenceInDays: '-';
                        return (
                      
                        <>
                         <tr >
                          <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	{legacyLoanId}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	{item?.emiNo}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	₹{item?.emiAmount}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	₹{item?.repaidEmiAmount}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}></span>{((result))}</td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	₹{Math.round(item?.overdueCharges)}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	₹{Math.round(item?.bounceCharges)}</span></td>
                      <td style={{  color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw'}}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'2px'}}></span>{item?.dueDate}</td>
                      <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>
                              
                              {item.status?
                              item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px',whiteSpace:'nowrap'}}><img style={{marginRight:'5px'}} src={initiateddot}/>UnPaid</span>
                              :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px',whiteSpace:'nowrap'}}><img style={{marginRight:'5px'}} src={pendingdot}/>PartlyPd.</span>
                              :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px'}}><img style={{marginRight:'5px'}} src={activedot}/>Paid</span>
                              :item.status==4?<span style={{backgroundColor:'#8ab7ff',color:'black',borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px'}}>OvdPmt.</span>
                              :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px'}}><img style={{marginRight:'5px'}} src={closeddot}/>Ovd</span>
                              :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Inter',fontSize:'14px'}}><img style={{marginRight:'5px'}} src={pendingdot}/>Pause</span>
                              :item.status
                              :'-'}
                            </td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'10px'}}>	₹{Math.round(item?.emiAmount+item?.overdueCharges+item?.bounceCharges)}</span></td>
                      

                      </tr>
                      {index !== repaymentTable.length - 1 && (
        <tr>
          <td colSpan="12" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
                        </>
                      )})}
                     
                    </tbody>
                   </table>
                    </div>

    </>
)
}

export default Allemis