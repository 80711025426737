import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { useState, useEffect } from 'react';
import active from "../../assets/images/progress.png"
import overdue from "../../assets/images/active.png"
import closed from "../../assets/images/closed.png"
import search from "../../assets/images/search.png"
import pendingdot from "../../assets/images/pendingDot.png"
import activedot from "../../assets/images/activeDot.png"
import closeddot from "../../assets/images/closedDot.png"
import tododot from "../../assets/images/todoDot.png"
import initiateddot from "../../assets/images/initiatedDot.png"
import rupee from "../../assets/images/rupee.png"
import {Row,Col,Input} from "reactstrap"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SmsTwoToneIcon from '@mui/icons-material/SmsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import jwt_decode from 'jwt-decode';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation,useNavigate } from 'react-router-dom';
import axiosInstance from '../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import { userActions } from '../../actions/user.actions';
import PaginationTable from '../status/PaginationTable';
import clear from "../../assets/images/clear.png";
import "./Pooltaging.css"
import { CoPresentOutlined, SettingsApplications } from '@mui/icons-material';
import { event } from 'jquery';
import { Chart, Bar, Doughnut,Line } from "react-chartjs-2";
import { Pie } from 'react-chartjs-2';
import DatePicker from "react-multi-date-picker"
import calender from "../../assets/images/calender.png"
import download from "../../assets/images/download.png"

import {
    Chart as ChartJS,
    LinearScale,
    ArcElement,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  } from "chart.js";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

export default function PoolTagedreport() {

    const state = useSelector((state) => state?.Loans?.res);
    const countstate = useSelector((state) => state?.LoanDetails?.res);
  
    const navigate = useNavigate();
   
    const dispatch = useDispatch();
    const [totalFetchRows, setTotalFetchRows] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowsPerPage, setrowsPerPage] = useState(15);
    const [loanId, setloanId] = useState(null);
    const [filterCustomerName, setFilterCustomerName] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [searchText, setSearchText] = useState("")
    const[tenure,setTenure]=useState("");
    const [pos, setPos] = useState("")
    const [id,setId]=useState(null)
    const [isOpen,setIsOpen]=useState(true)
    const [selectedValue, setSelectedValue] = useState("");
    const [duefilterDateFrom, setFilterstartDateFrom] = useState("");
    const [duefilterDateTo, setFilterstartDateTo] = useState("");
   
    
    const user = sessionStorage.getItem('user');
    
    const decode=jwt_decode(user)
      const [tableData, setTableData] = useState([]);
      const [count, setCount] = useState([]);
      const [status, setStatus] = useState(null);
      const [filteredData, setFilteredData] = useState([]);
      const [searchQuery, setSearchQuery] = useState('');
      const url = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${currentPage}`
      const customerNameField=document.getElementById("customerName")?.value
      const urlUsers = `/loan-repayment/search?searchField=${customerNameField?customerNameField.length>=5?customerNameField:null:null}`;
      
      const countUrl = `/loans/count`
      const clearDateFilter = () => {
        window.location.reload()
        const url = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${currentPage}`
        setSearchText("")
  
        axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
          setTableData(res?.data?.data?.data);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      };

     

     


     
      useEffect(() => {
        if(filterCustomerName){
          console.log("inside  if")
          handleCustomerChange()
        }
    else if(searchText === "" || filterCustomerName === ""){
      console.log("inside else")
      setIsLoading(true);
      axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res)=>{
    
        setTableData(res?.data?.data?.data)
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage); 
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }).catch((error)=>{
        alert(error?.response?.data?.message)
      })
    }
  
      }, [customerNameField]);
  
     useEffect(()=>{
      let queryParams = {}
  
        if(id){
          queryParams.instituteId = id
        }
        
       
      axiosInstance.get("/loans/count",{params:queryParams,
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        setCount(res.data.data)
  
        
      })
      .catch((error)=>console.log(error))
  
  
      
     },[countUrl])
  
     const urlPos = `/analytics`
  
     useEffect(()=>{
      axiosInstance.get(urlPos,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        setPos(res?.data?.data?.totalPos)
  
        
      })
      .catch((error)=>console.log(error))
  
  
      
     },[countUrl])
        
     
     
  
      
      const paginate = (pageNumber) => {
        
        setcurrentPage(pageNumber);
        let queryParams = {}
  
        if(status){
          queryParams.status = status
        }
          if (tenure) {
          queryParams.tenure = tenure;
        }
        if (emiCount) {
          queryParams.emiRemainingCount = emiCount;
        }
        if (emiAmount) {
          queryParams.outstandingAmount = emiAmount;
        }
  
        
        if(id){
          queryParams.instituteId = id
        }
        
        if(rowsPerPage && currentPage){
          queryParams.perPage = rowsPerPage;  
          queryParams.pageNo = pageNumber
        }

        if(duefilterDateFrom && duefilterDateTo){
          const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
          const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') ;
          queryParams.startDate = formattedStartDate;
          queryParams.endDate = formattedEndDate
        }

        axiosInstance.get('/loan-repayment/loans',{
          params:queryParams
        , 
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
          setTableData(res?.data?.data?.data);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
                  setIsLoading(false);
                }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      };
  
    const handleLoanProfile = (loanId,legacyLoanId)=>{
      sessionStorage.setItem("loanid",loanId)
      sessionStorage.setItem("legacyLoanId",legacyLoanId)
      const urlUsers = `/loan-repayment/profile?loanId=${sessionStorage.getItem("loanid")}`;
      const callDetailsUrl = `/call-history/details?loanId=${sessionStorage.getItem("loanid")}`;
      const urlGetReminder=`/call-history/reminder-details?loanId=${sessionStorage.getItem("loanid")}`
      const emihistoryUrl=`/emis/for-loan?loanId=${sessionStorage.getItem("loanid")}`
  
      dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
      dispatch(userActions.fetchLoanDetails(urlUsers, user),[])
      dispatch(userActions.getReminder(urlGetReminder))
      dispatch(userActions.getEmiHistory(emihistoryUrl,user))
  
    
      const newWindow = window.open("/Loanprofile", "_blank");  }
      

    //   const status = event.target.value; 
    //   
    //   setStatus(status)
    //   setIsLoading(true);
    //   let queryParams = {}
  
    //   if(status){
    //     queryParams.status = status
    //   }
      
    //   if(id){
    //     queryParams.instituteId = id
    //   }
    //     if (tenure) {
    //       queryParams.tenure = tenure;
    //     }
    //     if (emiCount) {
    //       queryParams.emiRemainingCount = emiCount;
    //     }
    //     if (emiAmount) {
    //       queryParams.outstandingAmount = emiAmount;
    //     }
  
      
    //   if(rowsPerPage && currentPage){
    //     queryParams.perPage = rowsPerPage;
    //     queryParams.pageNo = currentPage;
    //   }
     
    //   if(selectedname){
    //     queryParams.pooltag=selectedname
    //    }
          

    //   axiosInstance.get('/loan-repayment/loans',{
    //     params:queryParams
    //   , 
    //     headers: {
    //       Authorization: `Bearer ${user}`,
    //     },
    //   })
    //   .then((res) => {
  
    //     setTableData(res?.data?.data?.data);
    //     setcurrentPage(res?.data?.data?.pagination.currentPage);
    //     setTotalFetchRows(res?.data?.data?.pagination.totalRows);
    //     setrowsPerPage(res?.data?.data?.pagination.perPage);  
    //     const loadingTimeout = setTimeout(() => {
    //             setIsLoading(false);
    //           }, 300);
    //   })
    //   .catch((error) => {
    //     alert(error?.response?.data?.message)
    //     console.error("Error fetching filtered data:", error);
    //   });
  
     
     
    // };
    const getInsituteInfo=(text)=>{
      
      const urlGetInstitute=`/institute/info/name?name=${text}`;
        
      if(text.length>=4)
      {
        axiosInstance.get(urlGetInstitute,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res) => {
          console.log("text",res?.data?.data[0]?.id)
          setId(res?.data?.data[0]?.id)
        });
    
     
      }
   
    }
    const handleCustomerChange = () =>{
  
        axiosInstance.get(urlUsers,{
          headers:{
            Authorization:`Bearer ${user}`
        }}).then((res) => {
          
      
            console.log("res",res?.data?.data)
            setTableData(res?.data?.data);
          
        });
      
    }
  console.log(tenure,"teneeeeeee")
  
    const [searchClicked,setSearchClicked] = useState(false)
    const [legacyloanId,setLegacyLoanId] = useState(null)
    const [emiCount,setEmicount]=useState('')
    const [emiAmount,setEmiamount]=useState('')
  
    

    const handleInputChange = (event) => {
      setIsAllSelected(false)
      setIsLoading(true);
      setSearchClicked(!searchClicked);
      
   
      let queryParams = {};
    console.log(emiAmount,'emiAmountsssssssssss')
  
      if (searchText.length >= 4) {
       
        if (id) {
          queryParams.instituteId = id
        }}
  
  
        if (tenure) {
          queryParams.tenure = tenure;
        }
        if (emiCount) {
          queryParams.emiRemainingCount = emiCount;
        }
        if (emiAmount) {
          queryParams.outstandingAmount = emiAmount;
        }
  
  
      
        // Add additional filters if they are present
        if (status) {
          queryParams.status = status;
        }
    
        if (id) {
          queryParams.instituteId = id;
        }
    
        // Add pagination details if available
        if (rowsPerPage && currentPage) {
          queryParams.perPage = rowsPerPage;
          queryParams.pageNo = currentPage;
        }
        
      if(duefilterDateFrom && duefilterDateTo){
        const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
        const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";
        queryParams.fromDueDate = formattedStartDate;
        queryParams.toDueDate = formattedEndDate+" "+"23:59:59"
      }
  
      
    
      // Fetch the loans data using the constructed query parameters
      axiosInstance
        .get('/loan-repayment/loans', {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          setTableData(res?.data?.data?.data);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching filtered data:", error);
        });
    
      // Fetch the count based on the same filters (without pagination)
      let queryParamss = {};
      if (id) {
        queryParamss.instituteId = id;
      }
      if (tenure) {
        queryParamss.tenure = tenure;
      }
      if (status) {
        queryParamss.status = status;
      }
      if (emiAmount) {
        queryParams.emiRemainingCount = emiAmount;
      }
      if (emiCount) {
        queryParams.emiRemainingCount = emiCount;
      }
    if(duefilterDateFrom && duefilterDateTo){
      const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
      const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";
      queryParams.fromDueDate = formattedStartDate;
      queryParams.toDueDate = formattedEndDate+" "+"23:59:59"
    }
  
    
      axiosInstance
        .get('/loans/count', {
          params: queryParamss,
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          setCount(res.data.data);
        })
        .catch((error) => console.log("Error fetching count:", error));
  
        axiosInstance.get("/loans/count",{params:queryParamss,
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res) => {
          setCount(res.data.data)
    
          
        })
        .catch((error)=>console.log(error))
    
    };
    
  
  
  
  
  
  const handleChange = (event) => {
    setIsLoading(true)
    setrowsPerPage(event.target.value);
    let queryParams = {}
  
        if(status){
          queryParams.status = status
        }
        
        if(id){
          queryParams.instituteId = id
        }
        
        if (rowsPerPage && currentPage) {
          queryParams.perPage = rowsPerPage;
          queryParams.pageNo = currentPage;
        }
        if(selectedname){
            queryParams.pooltag=selectedname
           }
          
        if(duefilterDateFrom && duefilterDateTo){
        const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
        const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') ;
        queryParams.startDate = formattedStartDate;
        queryParams.endDate = formattedEndDate
      }
    
        axiosInstance.get('/loan-repayment/loans',{
          params:queryParams,
        
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
          setTableData(res?.data?.data?.data);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
                  setIsLoading(false);
                }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
        let queryParamss = {}
  
        if(id){
          queryParamss.instituteId = id
        }
        
       
     
  
  };
  
    function formatNumberWithCommas(number) {
      return number?.toLocaleString('en-IN');
    }
  
  
  
  
  
  
    
    const [selectedRows, setSelectedRows] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    
   
    const[fetchedData,setFetchedtable] = useState('');
    console.log(fetchedData,'totalFetchRows')
  
    console.log(selectedRows,'selectedRows')
    
    // const fetchAllData = async () => {
  
    //   setIsLoading(true);
    //   setSearchClicked(!searchClicked);
    
    //   // Initialize the query parameters object
    //   let queryParams = {};
    // console.log(emiAmount,'emiAmountsssssssssss')
    //   // Check if the search text length is sufficient
    //   if (searchText.length >= 4) {
    //     // Add filters based on the input event
    //     if (id) {
    //       queryParams.instituteId = id
    //     }}
  
  
    //     if (tenure) {
    //       queryParams.tenure = tenure;
    //     }
    //     if (emiCount) {
    //       queryParams.emiRemainingCount = emiCount;
    //     }
    //     if (emiAmount) {
    //       queryParams.outstandingAmount = emiAmount;
    //     }
  
    //    if(selectedname){
    //     queryParams.pooltag=selectedname
    //    }
      
    //     // Add additional filters if they are present
    //     if (status) {
    //       queryParams.status = status;
    //     }
    
    //     if (id) {
    //       queryParams.instituteId = id;
    //     }
    //     if(selectedname){
    //         queryParams.pooltag=selectedname
    //        }
          
    //   if(duefilterDateFrom && duefilterDateTo){
    //   const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
    //   const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') ;
    //   queryParams.startDate = formattedStartDate;
    //   queryParams.endDate = formattedEndDate
    // }
    //     // Add pagination details if available
        
        
      
    //     const queryString = new URLSearchParams(queryParams).toString();
  
    //     // Construct the URL with query parameters
    //     const url = `/loan-repayment/loans?perPage=${totalFetchRows}&perPage=5000000000&pageNo=1&${queryString}`;
    
    
       
    //     axiosInstance.get(url,{
    //       headers:{
    //         Authorization:`Bearer ${user}`
    //       }
    //     }).then((res) => {
    //       console.log("text",res?.data?.data?.data)
    //       const allData = res?.data?.data?.data;
    //       setFetchedtable(allData)
    //       const allLoanIds = allData.map((item) => item.loanId);
       
    //       setSelectedRows(allLoanIds)
    //       setIsLoading(false)
         
          
          
    //       return allLoanIds;
    //     }
    //   );
    // };
    
    const fetchAllData = async () => {
      setIsLoading(true); // Show the loader
      setSearchClicked(!searchClicked); // Toggle searchClicked state
    
      try {
        const perPage = 3; // Number of rows to fetch per page
        const totalRows = totalFetchRows; // Total rows to fetch (provided as input)
        let currentPage = 1; // Start with the first page
        let allClients = []; // Array to store all fetched data
    
        // Loop until all pages are fetched
        while (allClients.length < totalRows) {
          // Initialize query parameters for the current page
          let queryParams = {
            perPage,
            pageNo: currentPage,
          };
    
          // Add filters dynamically
          if (searchText.length >= 4 && id) queryParams.instituteId = id;
          if (tenure) queryParams.tenure = tenure;
          if (emiCount) queryParams.emiRemainingCount = emiCount;
          if (emiAmount) queryParams.outstandingAmount = emiAmount;
          if (selectedname) queryParams.pooltag = selectedname;
          if (status) queryParams.status = status;
    
          // Add date filters if both dates are provided
          if (duefilterDateFrom && duefilterDateTo) {
            const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
            const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD');
            queryParams.startDate = formattedStartDate;
            queryParams.endDate = formattedEndDate;
          }
    
          // Construct the query string
          const queryString = new URLSearchParams(queryParams).toString();
          const url = `/loan-repayment/loans?${queryString}`;
    
          // Fetch data for the current page
          const response = await axiosInstance.get(url, {
            headers: {
              Authorization: `Bearer ${user}`, // Include the token in the header
            },
          });
    
          // Extract data from the response
          const { data } = response?.data?.data || {};
          console.log(`Fetched page ${currentPage}:`, data);
    
          // Append fetched data to allClients
          allClients.push(...data);
    
          // Increment to the next page
          currentPage++;
        }
      console.log(allClients,'allClients')
        // Update state with the aggregated data
        setFetchedtable(allClients);
    
        // Extract and set all loan IDs for selected rows
        const allLoanIds = allClients.map((item) => item.loanId);
        setSelectedRows(allLoanIds);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
      } finally {
        setIsLoading(false); // Hide the loader
      }
    };
    
    // const handleSelectRow = (loanId,allLoanIds) => {
    //   setSelectedRows((prevSelected) => {
    //     const updatedSelected = prevSelected.includes(loanId)
    //       ? prevSelected.filter((id) => id !== loanId)
    //       : [...prevSelected, loanId];
     
          
    //       setIsAllSelected(updatedSelected.length === tableData.length);
        
    
    //     return updatedSelected;
    //   });
    // };
    const handleSelectRow = (loanId) => {
      setSelectedRows((prevSelected) => {
        // Toggle the selection of the current loanId
        const updatedSelected = prevSelected.includes(loanId)
          ? prevSelected.filter((id) => id !== loanId)  // Remove if already selected
          : [...prevSelected, loanId];  // Add if not selected
    
        // Check if all rows in fetchedtable are selected
        setIsAllSelected(updatedSelected.length === fetchedData.length?fetchedData.length:tableData.length);
    
        // Return the updated selected rows
        return updatedSelected;
      });
    };
    
  
    
  
    const handleSelectAll = () => {
      const currentPageLoanIds = tableData.map((item) => item.loanId);
      
      if (isAllSelected) {
        setSelectedRows([]);
        setIsAllSelected(false);
        
     
      } else {
       
        
        setSelectedRows(currentPageLoanIds);
      
        setIsAllSelected(true);
        
        
      }
    };
  
    console.log(isAllSelected,"teneeeeeee")
   
    const handleSelectAlldata = async () => {
  
      if (isAllSelected) {
      
        setSelectedRows();
        setIsAllSelected(false);
      
        
  
      }else{
      // const currentPageLoanIds = tableData.map((item) => item.loanId);
      const allLoanIds = await fetchAllData();
      // setSelectedRows(currentPageLoanIds);
      setIsAllSelected(true);
      
    }
     
    };
  
   
  
  
   
  
    const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
    };
  
  
  
  
  
    
    const isPoolTaggingVisible = selectedRows.length > 0 || isAllSelected;
    
    const [modal, setModal] = useState(false);
  
    const toggleModal = () => {
      setModal(!modal);
    };
  
  
    
  
   
  
    const handleSelecttenure = (event) => {
      const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
      
      if (selectedOptions.length === 0) {
        setTenure([]);
        clearDateFilter();
      } else {
        setTenure(selectedOptions);
      }
    };
       

    const[tagData,setTagData] =useState('');
    const [dataPie, setDataPie] = useState({
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      });
    console.log(dataPie,'afsgdngf')
    useEffect(() => {
        axiosInstance
          .get('/analytics/taged-loans-parcentage') // Use your axios instance here
          .then((response) => {
            const data = response.data?.tagedpiechart ?.tagedpiechart|| [];
            console.log(data,"actualdata")
            setTagData(data)

            const labels = data?.map((item) => item.taged ? item.taged : '-');
           
            console.log(labels,"dataddddddddddddd")
            const percentages = data?.map((item) => parseFloat(item.tag_percentage?item.tag_percentage:'_'));
            const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50'];
    
            setDataPie({
              labels,
              datasets: [
                {
                  data: percentages,
                  backgroundColor: colors,
                  hoverBackgroundColor: colors.map((color) => `${color}AA`),
                },
              ],
            });
          })
          .catch((error) => console.error('Error fetching data:', error));
      }, []);



      const [selectedname, setSelectedname] = useState('');
      console.log(selectedname,'selectedname')

      const handleTagClick = (tagName) => {
        setIsLoading(true);
       
      
        let queryParams = {}
  
        if(status){
          queryParams.status = status
        }
        
        if(id){
          queryParams.instituteId = id
        }
        
        if (rowsPerPage && currentPage) {
          queryParams.perPage = rowsPerPage;
          queryParams.pageNo = currentPage;
        }
        if(tagName){
            queryParams.pooltag=tagName
           }
          
      if(duefilterDateFrom && duefilterDateTo){
      const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
      const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') ;
      queryParams.startDate = formattedStartDate;
      queryParams.endDate = formattedEndDate
    }
      
          
           axiosInstance.get('/loan-repayment/loans',{
            params:queryParams,
          
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
      
            setTableData(res?.data?.data?.data);
            setcurrentPage(res?.data?.data?.pagination.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination.totalRows);
            setrowsPerPage(res?.data?.data?.pagination.perPage);  
            const loadingTimeout = setTimeout(() => {
                    setIsLoading(false);
                  }, 300);
                 
          })
          .catch((error) => {
            alert(error?.response?.data?.message)
            console.error("Error fetching filtered data:", error);
          });
      };
      
      const resetTag = () => {
        window.location.reload();
      };



      const handleDownloadList=()=>{
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = String(currentDate.getFullYear());
        const formattedDate = `${day}-${month}-${year}`;
        document.getElementById("downloadButton").disabled=true;
        document.getElementById("downloadButton").innerText="Wait....";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").style.color= "white";
        document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
        document.getElementById("downloadButton").style.fontSize= "15px";
        document.getElementById("downloadButton").style.width= "130px";
        document.getElementById("downloadButton").style.cursor= "pointer";
        document.getElementById("downloadButton").style.height= "34px";
        document.getElementById("downloadButton").style.borderRadius= "5px";
        document.getElementById("downloadButton").style.textAlign= "center";
        // document.getElementById("downloadButton").style.marginLeft="150px";
        document.getElementById("downloadButton").style.backgroundImage = "none"
    
    
        const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/IrrCountInRange `
        
        let queryParams = {}

        
        if (searchText.length >= 4) {
     
          if (id) {
            queryParams.instituteId = id
          }}
    
    
          if (tenure) {
            queryParams.tenure = tenure;
          }
          if (emiCount) {
            queryParams.emiRemainingCount = emiCount;
          }
          if (emiAmount) {
            queryParams.outstandingAmount = emiAmount;
          }
    
    
          if (status) {
            queryParams.status = status;
          }
      
          if (id) {
            queryParams.instituteId = id;
          }
      
          if (rowsPerPage && currentPage) {
            queryParams.perPage = rowsPerPage;
            queryParams.pageNo = currentPage;
          }

          if(duefilterDateFrom && duefilterDateTo){
            const formattedStartDate = moment(duefilterDateFrom, 'DD MMM YYYY').format('YYYY-MM-DD');
            const formattedEndDate = moment(duefilterDateTo, 'DD MMM YYYY').format('YYYY-MM-DD') ;
            queryParams.startDate = formattedStartDate;
            queryParams.endDate = formattedEndDate
          }
           if(selectedname){
            queryParams.pooltag=selectedname
           }
      

        
        axiosInstance.get("/excel/allLoans", {
          params: queryParams,
          responseType: 'arraybuffer',
          headers: {
              Authorization: `Bearer ${user}`
          }
      }).then((res)=>{
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    
        }, 2000);
        const blob = new Blob([res.data], { type: "application/xlsx", });
        const url= window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href =url
        link.download = `${formattedDate}.xlsx`;
    
        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
      }
        
      ).catch((error)=>{
        alert("Error Downloading")
        document.getElementById("downloadButton").innerText="Error..";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          // document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='20px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
        }, 1000);
      })
      } 

    
    
  

  return( 
     <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
  <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
  <div style={{width:isOpen?'78%':'98%', overflow:"auto",marginLeft:isOpen?"21%":'0%',}}>
          <Topbar/>
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <h1 style={{fontFamily:'Inter-Medium',fontSize:'25px',fontWeight:'500',padding:'10px',color:'#101828'}}>All Loans</h1>
          <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{backgroundColor:'#D22129',marginTop:'10px',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'100px'}}
           id="downloadButton"    onClick={() => handleDownloadList()}  >
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
          
         </div>
         <div  style={{marginRight:'20px',marginTop:"7px"}}>
     <button
        onClick={resetTag}
        style={{
         backgroundColor:'#D22129',color:'white',marginTop:'8px',display: 'flex', alignItems: 'center',width:'90px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'5px',justifyContent:'space-around',cursor:'pointer',marginleft:'90px',
          
        }}
      >
        Reset
      </button>
      </div> 
      </div>     
         </div>
          <div >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  <div
    style={{
      width: '25vw',
      height: '15em',
      padding: '15px',
      borderRadius: '10px',
      boxShadow: "4px 7px 9px 2px #1018280F",
      border: '1px solid white',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: 'white',
      overflowY: 'auto',
    }}
  >
     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
    <div style={{ width: "25em", height: "20em"}}>
      
      <Pie
        data={dataPie}
        options={{
          plugins: {
            legend: {
              position: 'right', // Move labels to the right side
              labels: {
                font: {
                  size: 14, // Adjust font size
                  family: 'Inter-Medium', // Customize font family
                },
                color: '#667085', // Customize label color
                boxWidth: 10, // Size of the legend box
              },
             
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const label = tooltipItem.label || '';
                  const value = tooltipItem.raw || 0;
                  return `${label}: ${value.toFixed(2)}%`;
                },
              },
            },
          },
        }}
      />
    </div>
    </div>
  </div>
  <div style={{ paddingRight: '10px' }}>
    <div
      style={{
        width: '25vw',
        height: '15em',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: "4px 7px 9px 2px #1018280F",
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        overflow: 'hidden',
      
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '10px',
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: '8px', border: '1px solid #ddd' }}>Tag Names</th>
              <th style={{ padding: '8px', border: '1px solid #ddd' }}>Pos</th>
              <th style={{ padding: '8px', border: '1px solid #ddd' }}>Count</th>
            </tr>
          </thead>
          <tbody>
            {tagData.length > 0 ? (
              tagData.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    handleTagClick(item.taged); // Call the handleTagClick function
                    setSelectedname(item.taged); // Update the selectedname state
                  }}
                  style={{
                    backgroundColor: selectedname === item.taged ? "#f0f8ff" : "white",
                    cursor: "pointer",
                  }}
                >
                  <td style={{ padding: '8px', border: '1px solid #ddd', cursor: 'pointer' }}>
                    {item.taged ? item.taged : '-'}
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    {item.POS ? `₹${formatNumberWithCommas(Math.round(item.POS))}` : '-'}
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    {item.tag_count}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" style={{ textAlign: 'center', padding: '10px' }}>
                  Loading data...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

         
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'10px'}}>


          <Input type="select" id="page" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium',width:'8%',marginTop:'11px'}} onChange={(event)=>handleSelecttenure(event)}   value={tenure}>
    <option value="" disabled>Select Tenure</option>
    <option value="3">3 months</option>
    <option value="6">6 months</option>
    <option value="12">12 months</option>
    <option value="18">18 months</option>
    <option value="24">24 months</option>
    <option value="36">36 months</option>
       </Input>
         
         <Input  onChange={(event) => {
          setEmicount(event.target.value);
          if (event.target.value === '') {
            setEmicount("")
            clearDateFilter(); 
          }
        }} 
        type="text"
        placeholder="emi count"
        className="placeholder-style"
        style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'10%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
        }}
      />
         <Input  onChange={(event) => {
          setEmiamount(event.target.value);
          if (event.target.value === '') {
            setEmiamount("")
            clearDateFilter(); 
          }
        }} 
        type="text"
        placeholder="emi Amount"
        className="placeholder-style"
        style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'10%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
        }}
      />

      <Input  onChange={(event) => {
          getInsituteInfo(event.target.value);setSearchText(event.target.value);
          if (event.target.value === '') {
            setSearchText("")
            clearDateFilter(); 
          }
        }} 
        type="text"
        placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
        className="placeholder-style"
        style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'15%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
        }}
      />
     <div style={{ height:'30px',width:'12em', borderRadius: '15px',marginRight:"60px",marginTop:'-2px'}}>
              <DatePicker
                style={{ height:'33px', BorderRadius: '5px',padding: '2px', backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px', backgroundImage: `url(${calender})`, paddingLeft: '30px',fontSize:"14px", border:'1px solid #ccc',fontWeight:"normal", border:'1px solid #ccc'}}
                placeholder="Closure date range"
                allowClear={true}
                format="DD MMM YYYY"
                onChange={(dates, dateStrings) => {
                  console.log("dateStrings:", dateStrings);
                
                    
                  setFilterstartDateFrom(dateStrings?.validatedValue[0])
                  setFilterstartDateTo(dateStrings?.validatedValue[1])
              }}
                range
                />
                </div>
      <Input type="select" id="page" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium',width:'15%',marginTop:'11px'}} onChange={(event)=>handleChange(event)}>
      <option value="15" >Per Page 15</option>
      <option value="50">Per Page 50</option>
      <option value="100">Per Page 100</option>
      <option value="500">Per Page 500</option>
    </Input>
    {searchClicked === true ? (
      <div style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer',paddingLeft:'10px',marginTop:'10px'}} onClick={clearDateFilter}>
                <img src={clear} style={{height:'18px'}}/>
                <div style={{color:'white',display:'flex',alignItems:'center'}}>Clear Filter</div>
              </div>
    ):(
<button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px',marginTop:'10px'}}
        onClick={(event) =>  handleInputChange(event)}
        >
          Search Results
        </button>
    )}
    
      
          </div>
    
      {isLoading ? (
        <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
        <CircularProgress />
      </Box>
      ):(
        <>
         {Array.isArray(tableData)?(
        <div className='tables' >
          
          <table hover style={{ width: '100%', borderCollapse: 'collapse' }}>
<thead className='table-heading'>
  <tr>  <th style={{
      padding: '2px 4px',   // Reduce padding for less height
      lineHeight: '1.2',    // Decrease line height
      height: '30px', 
      width: '3em',
      textAlign: 'left',
      paddingLeft: '20px',
      borderTopLeftRadius: '8px',
      paddingTop: '10px',
      paddingBottom: '10px'
    }}>
   <div   style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={handleSelectAll}
        style={{ marginRight: '5px', cursor: 'pointer' }}
      />
        <div style={{position: 'relative'}}>
  <span
    onClick={toggleDropdown}
    style={{
      cursor: 'pointer',
      fontSize: '16px',
      marginRight: '5px',
      userSelect: 'none'
    }}
  >
    &#9660; {/* Dropdown arrow icon */}
  </span>

  {showDropdown && (
    
    <div
    style={{
      position: 'absolute',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
      zIndex: 1000,
      marginTop: '5px',
      width: '150px',
    }}
  >
    <div
      style={{
        padding: '8px',
        cursor: 'pointer',
        borderBottom: '1px solid #f0f0f0',
      }}
      onClick={() => {
        if(selectedRows.length>0){
          setSelectedRows([]);
          setIsAllSelected(false);
        }else{ handleSelectAll();
          setIsAllSelected(true)
        }toggleDropdown();
        // Close dropdown after selection
      }}
    >
      Current Page
    </div>
    <div
style={{ padding: '8px', cursor: 'pointer' }}
onClick={() => {
  // Check if all rows are already selected
  if (selectedRows.length  > 0) {
    // If all are selected, deselect all
    setSelectedRows([]);
    setIsAllSelected(false);
  } else {
    // If not all are selected, select all
    handleSelectAlldata();
    setIsAllSelected(true);
  }
  toggleDropdown(); // Close dropdown after selection
}}
>
All
</div>
  </div>
  
  )}
</div>
</div>


    </th>
    
    <th style={{
      padding: '2px 4px',   // Reduce padding for less height
      lineHeight: '1.2',    // Decrease line height
      height: '30px', 
      width: '3em'
    }} > {isPoolTaggingVisible && (

    <span>({ selectedRows.length } selected)</span> )}
    </th>
        
    
    </tr>
  <tr>
   <th style={{
      width: '3em',
      textAlign: 'left',
      paddingLeft: '20px',
      
      paddingTop: '10px',
      paddingBottom: '10px'
    }}>
      
    </th>
  
    <th style={{
      width: '12em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      Loan ID
    </th>
    <th style={{
      width: '18em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      CustName
    </th>
    <th style={{
      width: '18em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      { decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Institute Name'}
    </th>
    <th style={{
       width: '10em',
       textAlign: 'left',
       fontFamily: 'Inter',
       fontSize: '0.9vw',
       color: '#667085',
       padding: '10px 0'
    }}>
     emi count
    </th>
    <th style={{
      width: '10em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      LoanAmt
    </th>
    <th style={{
      width: '10em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      PaidAmt
    </th>
    <th style={{
      width: '10em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      POS
    </th>
   
    <th style={{
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      width: '12em',
      borderTopRightRadius: '8px',
      padding: '10px 0',
      paddingLeft:'20px'
    }}>
      Status
    </th>

    <th style={{
      width: '10em',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9vw',
      color: '#667085',
      padding: '10px 0'
    }}>
      Tag status
    </th>
  </tr>
</thead>

<tbody className='table-body'>
  {tableData?.map((item, index) => (
    <tr className='table-row' key={index} style={{ lineHeight: '1.5em', fontSize: '0.9vw' }}>
      <td style={{ paddingLeft: '20px' }}>
        <input
          type="checkbox"
          checked={selectedRows.includes(item.loanId)}
          onChange={() => handleSelectRow(item.loanId)}
          style={{ cursor: 'pointer' }}
        />
      </td>
      <td onClick={() => handleLoanProfile(item.loanId, item.legacyLoanId)}
          style={{ cursor: 'pointer', maxWidth: '12em', color: '#101828', fontWeight: '500' }}>
        {item.legacyLoanId}
      </td>
      <td style={{ maxWidth: '18em', color: '#101828', fontWeight: '500' }}>
        {item?.userName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
      </td>
      <td style={{
        maxWidth: '18em',
        color: '#667085',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {item.instituteName}
      </td>
      <td style={{ color: '#667085' }}>
        {(item.paidcount ? (item.paidcount) : '-')}/{(item.tenure ? (item.tenure) : '-')}
      </td>
      <td style={{ color: '#667085' }}>
        {item.loanAmount ? `₹${formatNumberWithCommas(item.loanAmount)}` : '-'}
      </td>
      <td style={{ color: '#667085' }}>
        {item.paidAmount ? `₹${formatNumberWithCommas(item.paidAmount)}` : '-'}
      </td>
      <td style={{ color: '#667085' }}>
        {item.outstandingAmount ? `₹${formatNumberWithCommas(item.outstandingAmount)}` : '-'}
      </td>
      <td style={{ color: '#667085' }}>
          {item.status ? (
            item.status === 1 ? (
              <span style={{
                paddingLeft: '10px',
                backgroundColor: '#ECFDF3',
                color: '#027A48',
                borderRadius: '1em',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                height: '30px'
              }}>
                <img src={activedot} /> <span style={{ marginLeft: '5px' }}>Active</span>
              </span>
            ) : item.status === 2 ? (
              <span style={{
                paddingLeft: '10px',
                backgroundColor: '#FFE8E8',
                color: '#F81F1F',
                borderRadius: '1em',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                height: '30px'
              }}>
                <img src={closeddot} /> <span style={{ marginLeft: '5px' }}>Closed</span>
              </span>
            ) : item.status === 3 ? (
              <span style={{
                paddingLeft: '10px',
                backgroundColor: '#FFF6E8',
                color: '#F8A31F',
                borderRadius: '1em',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                height: '30px'
              }}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}>Overdue</span>
              </span>
            ) : item.status === 4 ? (
              <span style={{width:'7em',paddingLeft:'10px',backgroundColor:'#898989',color:'white',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'}}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}> Written Off</span>
              </span>
            ) : item.status === 5 ? (
              <span style={{
                width:'6em',paddingLeft:'10px',backgroundColor:'#EFE4FF',color:'#6E24E7',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'}}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}> Refund</span>
              </span>
            ) :item.status === 6 ? (
              <span style={{
              width:'6em',paddingLeft:'10px',backgroundColor:'#ffb499',color:'black',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'
              }}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}> Legal</span>
              </span>
            ) :item.status === 7 ? (
              <span style={{
                width:'6em',paddingLeft:'10px',backgroundColor:'#ffb499',color:'black',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'             }}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}> Paused</span>
              </span>
            ) :item.status === 8 ? (
              <span style={{
              width:'6em',paddingLeft:'10px',backgroundColor:'#ffb499',color:'black',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'
              }}>
                <img src={pendingdot} /> <span style={{ marginLeft: '5px' }}> Legal</span>
              </span>
            ) :
            (
              'NA'
            )
          ) : (
            'NA'
          )}
        </td>
      <td style={{ color: '#667085' }}>
      {item.pooltag ? item.pooltag : '-'}
      </td>
    </tr>
  ))}
</tbody>
</table>

    
        
          </div>
      ):''}
          {filterCustomerName || legacyloanId? null : <>
            {totalFetchRows ? ( totalFetchRows <= 10 ?(
         <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
         Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
         records of {totalFetchRows}
       </div>
      ):( <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
      Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
      records of {totalFetchRows}
    </div>)
       
      ) : (
        <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
      )}
          </>}
          
      {filterCustomerName || legacyloanId ? null : <PaginationTable
        startPage="1"
        rowsPerPage={rowsPerPage}
        totalRows={totalFetchRows}
        paginate={paginate}
        isActive={isActive}
        searchText={searchText}
      /> }
     
        </>
      )}
     
        
          </div>
      </div>
  </div>
)

}
