import React,{useState} from 'react'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import jwt_decode from 'jwt-decode'

import "./index.css"
import LoanCards from '../LoanCards'
import BasicLoanForm from '../LoanCards/LoanForms/BasicLoanForm/BasicLoanForm.js'


const Dashboard = () =>{
    const user=sessionStorage.getItem('user')
    const decode=jwt_decode(user)
    const [loanTypeForm, setLoanTypeForm] = useState('')
    const [isOpen,setIsOpen]=useState(true)

    const handleLoanTypeForm = (loanType) => {
      setLoanTypeForm(loanType)
    }
    return(
    <div style={{display:'flex' ,flexDirection:'row',width:"100%" ,height:'100%',backgroundColor:"#FFF"}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"21%":'0%'}}>
            <Topbar/>
          <div className="fade-In">
              <h1>
                <p style={{fontWeight:'500',fontSize:'1em',fontFamily:'Outfit'}}>Welcome ! <span style={{ fontSize: '1em',color:'#d32028',fontFamily:'Outfit' }}>
                  {decode.display_name}
                </span>{' '}</p>{' '}
              
                <p style={{ fontSize: '0.5em' }}>
                  Begin the <span style={{fontWeight:'bold'}}>{loanTypeForm}</span> Applicant Onboarding Journey by filling out the form with few details.
                </p>
              </h1> 
            {loanTypeForm === '' && <LoanCards handleLoanTypeForm={handleLoanTypeForm} clientId={decode.clientId}/>}
            {(loanTypeForm !== ""  && loanTypeForm!=='Term Loan')&& <BasicLoanForm loanType={loanTypeForm}/>} 
          
          </div>
        </div>
    </div>
    )
}

export default Dashboard