import React, { useState,useEffect } from 'react';
import paymentlink from "../../../assets/images/paymentlink.png"
import close from "../../../assets/images/close.png"
import Switch from '@mui/material/Switch';
import send from "../../../assets/images/send.png"
import copy from "../../../assets/images/copy.png"
import {
    Modal,
    ModalBody,
    Label,
    Form,
    FormGroup,
    Input,ModalHeader
  } from 'reactstrap';
import axiosInstance from '../../../helpers/axios';

export default function PaymentLink({selectedItems,totalEmiAmount,loanDetails,overdueCharges,chequeBounceCharges,interest}){
    console.log("selected items",selectedItems,overdueCharges,chequeBounceCharges)
    const [amount, setamount] = useState('');
    const [lumpsumAmount, setLumpsumAmount] = useState('');
    const [penaltyAmount, setPenaltyAmount] = useState('');
    const [bounceCharges, setBounceCharges] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState("");
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [formLoader, setFormLoader] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
    const [formStatusOpen, setStatusOpen] = useState(false);
    const [RepaymentType, setRepaymentType] = useState('1');
    const [DiscountAmount, setDiscount] = useState(true);
    const [PenaltyAmount, setPenalty] = useState(true);
    const [BounceCharges, setBounce] = useState(true);
    const [paymentLink,setPaymentLink] = useState(null)
    const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);

    const handleDiscountChange = () => {
        setDiscount(!DiscountAmount);
      };
      const handlePenaltyAmount = () => {
        setPenalty(!PenaltyAmount);
      };
      const handleBounceCharges = () => {
        setBounce(!BounceCharges);
      };
    const popUpStatusForm = () => {
        setStatusOpen(true);
        calculateTotalAmount()
      };
      const calculateTotalAmount = () => {
        console.log("amount",totalEmiAmount)
        const emi = parseFloat(totalEmiAmount) || totalEmiAmount;
        const penalty = parseFloat(penaltyAmount) || 0;
        const bounce = parseFloat(bounceCharges) || 0;
        let discount = parseFloat(discountAmount) || 0;
        const maxDiscount = parseFloat(overdueCharges) + parseFloat(chequeBounceCharges) + parseFloat(interest);
        if (discount > maxDiscount) {
          discount = maxDiscount >= 0 ? maxDiscount : 0;
      }
        const total = emi  - discount;
        setTotalAmount(total);
      };
      console.log("amount",totalEmiAmount)
    
      useEffect(() => {
        calculateTotalAmount();
      }, [amount, penaltyAmount, bounceCharges, discountAmount,totalEmiAmount]);
      const resetForm = () => {
        setTotalAmount(0)
        setamount('');
        setPenaltyAmount('');
        setBounceCharges('');
        setDiscountAmount('');
        setTotalAmount(0);
      };
      const user = sessionStorage.getItem("user")
    const loanId = sessionStorage.getItem("loanid")
      const handlePayment = () => {
        // setFormLoader(!formLoader);
      setStatusOpen(!formStatusOpen);
      // setConfirmationDialog(!confirmationDialog);
        const formData = {
          amount:lumpsumAmount?lumpsumAmount:totalAmount,
          reason : RepaymentType,
          discount:discountAmount?discountAmount:0,
          penaltyAmount:penaltyAmount?penaltyAmount:0,
          bounceCharges:bounceCharges?bounceCharges:0,
          loanId,
          emiIds:selectedItems,
          whatsappNotification:1,
          emailNotification:1,
           
        };
        const paymentUrl = `/loan-repayment/payment-link`;
        axiosInstance
        .post(`${paymentUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then(
          (res) => {
            if (res.data.message === 'Successful') {
                setPaymentLink(res?.data?.data)
                        setShowPaymentLinkModal(true);
                // setConfirmationMessage('PaymentLink Sent Successfully');
                // setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
                // setTimeout(() => setConfirmationDialog(false), 2000);
                // setTimeout(() => window.location.reload(true), 500);
              }
  
         console.log("formatteddata",res);
          },
          (error) => {
            // setConfirmationMessage(`Unauthorized --- Error Code ${error}`);
            // setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
            //  setTimeout(() => setConfirmationDialog(false), 3750);
            //  setTimeout(() => window.location.reload(true), 3000);

            console.log(error)
          },
        );
       
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(paymentLink);
      alert('Payment link copied to clipboard!');
      // setTimeout(() => window.location.reload(true), 500);
    };
    const  togglePaymentLinkModal = ()=>{
      setShowPaymentLinkModal(!showPaymentLinkModal)
    }
    return(
        <div >
            <img  onClick={() => selectedItems?.length>=1?popUpStatusForm():null} style={{cursor:selectedItems?.length>=1?'pointer':'not-allowed',marginRight:'10px'}} src={paymentlink}/>
            <Modal  isOpen={confirmationDialog}>
                <ModalHeader>Waiting For Confirmation</ModalHeader>
                <ModalBody>{confirmationMessage}</ModalBody>
            </Modal>
            <Modal isOpen={showPaymentLinkModal} toggle={togglePaymentLinkModal} backdrop="static" style={{top:'10%'}}>
        <ModalHeader toggle={togglePaymentLinkModal}>Payment Link</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'Inter-Medium'}}> {paymentLink}{" "}
          <span onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'50px'}} title='copy to clipboard'/>
            </span>
          </p>
        </ModalBody>
      </Modal>
            {formStatusOpen && (
                <>
                <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                        }}>
                </div>
                 <Modal style={{
                    
                    width:"500px",
                    
                    top:"4%",
                    borderRadius:"10px",
                }} isOpen={formStatusOpen}>
                  {loanDetails.mandateActive === true ? 
                  <>
                  <div style={{backgroundColor:'#E9E9E9',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingLeft:'20px',height:'60px',paddingRight:'20px',paddingTop:'10px'}}>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'600',color:'#101828'}}>Payment Link</p>
                        <p style={{paddingBottom:'10px'}}><img src={close} style={{cursor:'pointer'}} onClick={() => {resetForm();setStatusOpen(!formStatusOpen);}} /></p>
                    </div>
                    </div>
                    <ModalBody style={{color:'red',fontFamily:'Inter',fontSize:'0.9vw'}}>
                    Mandate is active,cannot generate the payment link.
                    </ModalBody>
                   
                  </>:
                  <>
                  <div style={{backgroundColor:'#E9E9E9',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingLeft:'20px',height:'60px',paddingRight:'20px',paddingTop:'10px'}}>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'600',color:'#101828'}}>Payment Link</p>
                        <p style={{paddingBottom:'10px'}}><img src={close} style={{cursor:'pointer'}} onClick={() => {resetForm();setStatusOpen(!formStatusOpen);}} /></p>
                    </div>
                    </div>
                <ModalBody>
                    <Form style={{padding:'10px'}}>
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'10px'}}>
                        <Label ><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Total EMI Amount</span></Label>
                        <Input disabled value={totalEmiAmount} type="text" placeholder="EMI Amount" id="amount"  onChange={(e) => setamount(e.target.value)}/>
                    </FormGroup>
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'20px'}}>
                    <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Repayment Type</span></Label>
                    <Input type="select" id="type"  onChange={(e) => setRepaymentType(e.target.value)}>
                    <option value="">Select</option>
                    <option value="3">EmiRepayment</option>
                    <option value="4">MultipleEmiPayments/Custom Amount</option>
                    <option value="2">Overdue</option>
                    <option value="1">Preclosure</option>
                    <option value="5">Settlement</option>
                    </Input>
                    </FormGroup>
                    {RepaymentType === "4" ? (
                      <FormGroup style={{lineHeight:'1.5em',marginTop:'10px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'100%'}}>
                            <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px',whiteSpace:'nowrap'}}>Amount
                             </span>
                            </Label>
                        <Input  type="number" placeholder="Amount" onChange={(e) => setLumpsumAmount(e.target.value)}/>
                            </div>
                           
                        </div>
                        
                    </FormGroup>
                    ):
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'10px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'100%'}}>
                            <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px',whiteSpace:'nowrap'}}>Discount Amt.
                                    (Max. Discount : {chequeBounceCharges} + {overdueCharges} + {interest} = {parseFloat(chequeBounceCharges) + parseFloat(overdueCharges) + parseFloat(interest)})
                             </span>
                            </Label>
                        <Input  type="number" placeholder="Discount Amount" id="discount" value={discountAmount} onChange={(e) => setDiscountAmount(e.target.value)}/>
                            </div>
                           
                        </div>
                        
                    </FormGroup>
                    }
                    
                    
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'10px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'100%'}}>
                            <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Penalty Amount</span></Label>
                        <Input disabled type="number" placeholder="Penalty Amount" id="penalty" value={overdueCharges} onChange={(e) => setPenaltyAmount(e.target.value)}/>
                            </div>
                            <div style={{marginTop:'30px'}}>
                            {/* <Switch 
                            checked={!PenaltyAmount}
                            onChange={handlePenaltyAmount}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /> */}
                            </div>
                            
                        </div>
                        
                    </FormGroup>
                    <FormGroup style={{lineHeight:'1em',marginTop:'20px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{width:'100%'}}>
                            <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Bounce Charges</span></Label>
                        <Input disabled type="number" placeholder="Bounce Charges" id="charges" value={chequeBounceCharges} onChange={(e) => setBounceCharges(e.target.value)}/>
                            </div>
                            <div style={{marginTop:'30px'}}>
                            {/* <Switch 
                            checked={!BounceCharges}
                            onChange={handleBounceCharges}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /> */}
                            </div>
                            
                        </div>
                        
                    </FormGroup>
                    </Form>
                </ModalBody>
                <div style={{backgroundColor:'#FFF3F3',display:'flex',alignItems:'center',flexDirection:'column',borderRadius:'10px',paddingTop:'20px',paddingLeft:'10px',paddingRight:'10px',paddingBottom:'20px'}}>
                    <p style={{whiteSpace:'nowrap',lineHeight:'0.5em',color:'#D32028',fontWeight:'600',fontFamily:'Inter-Medium'}}>Net Amount</p>
                    <p style={{whiteSpace:'nowrap',lineHeight:'0.5em',fontWeight:'600',fontSize:'20px'}}>₹ {lumpsumAmount ? lumpsumAmount : totalAmount}</p>
                    <p style={{whiteSpace:'nowrap',lineHeight:'1.5em',color:'#344054',fontFamily:'Inter-Medium'}}>(Included Penalty & Bounce Charges)</p>
                    <button  onClick={handlePayment} style={{width:'400px',backgroundColor:'#D32028',borderRadius:'20px',color:'white',fontWeight:'500',fontSize:'16px',fontFamily:'Inter-Medium'}}><img src={send}/><span style={{marginLeft:'10px'}}>Generate Payment Link</span></button>
                </div>
                  </>}
                    
                </Modal>
                </>
            )}
        </div>
    )
}