import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import action3 from "../../../assets/images/action3.png"
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';

import jwt_decode from 'jwt-decode'
import close from '../../../assets/images/close.png'
import dropdown from "../../../assets/images/dropdown.png"
import Datetime from 'react-datetime';
import Switch from 'react-bootstrap-switch';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';
import axiosInstance from '../../../helpers/axios';

export default function MoveReviewStatus(ApplicantId) {
  const statuschange = useSelector((state) => state.Updatestatus?.res)
  const droppedstate = useSelector((state) => state.Droppedstatuschange?.res)
  const disbursedstate = useSelector((state) => state.Disbursedstatuschange?.res)
  const approveapistate = useSelector((state)=>state.Handleapproveapi?.res)
  const approveformstate = useSelector((state)=>state.Handleapprove?.res)
  const colenderfundstate = useSelector((state)=>state.ColenderFundSource?.res)
  const approvefundstate = useSelector((state)=>state.Handleapproveapifund?.res)
  const fundstate = useSelector((state)=>state.Fundcode?.res)

  const dispatch = useDispatch();
  //to refresh token
  const ApplicationStatusEnum = {
    InSubmission: 'In Submission',
    InReview: 'In Review',
    InReviewAgain:'In Review',InProcess:'In Process',
    InSanction: 'In Sanction',
    InDisbursement: 'In Disbursement',
    Disbursed: 'Disbursed',
    Rejected: 'Rejected',
    Dropped: 'Dropped',
    OnHold:'On Hold'
  };
  const user = sessionStorage.getItem("user")
  //const { user, logOut } = useUserAuth();
const decode=jwt_decode(user)


const id = ApplicantId.ApplicantId.applicationId

const name = ApplicantId.ApplicantId.name
  
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [productDropDownOpen, setProductDropDownOpen] = useState(false);
  const [fundDropDownOpen, setFundDropDownOpen] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [status, setStatus] = useState(' Select Status');
  const [product, setProduct] = useState([]);
  const [prodArray, setProdArray] = useState([]);
  const [productName, setProductName] = useState('Product');
  const [fundSourceName, setFundSourceName] = useState('Fund Source');
  const [fundSource, setFundSource] = useState([]);
  const [fundSourceArray, setFundSourceArray] = useState([]);
  const [prodLoanAmount, setLoanAmount] = useState(null);
  const [prodEmi, setEMI] = useState(null);
  const [dateEmi, setEmiDate] = useState(null);
  const [dayEmi, setEmiDay] = useState(null);
  const [form, setForm] = useState(prodArray ? prodArray : null);
  const [formLoader, setFormLoader] = useState(false);
  const [coAppNumber, setCoAppNumber] = useState(false);
  const [currUserId, setUserId] = useState('');
  const [currApplicantId, setApplicantId] = useState('');
  const [coAppFName, setCoAppFName] = useState('');

  const [coAppEmail, setCoAppEmail] = useState('');
  const [relationshipStatus, setRelationshipStatus] = useState('Select Relation with Applicant');
  const [relationshipStatusDropdown, setRelationshipStatusDropdown] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
  const [coAppAadhaar, setCoAppAadhaar] = useState(false);
  const [coAppPAN, setCoAppPAN] = useState(false);
  const [emandate, setEmandate] = useState(false);
  const [runCIBIl, setRunCIBIL] = useState(false);
  const [incomeProof, setIncomeProof] = useState(false);
//get Emi
  
  const [emiData,setEmiData]=useState(null)
 

  //  const updateDisbursedUrl = `/admin/application/disburse`;
     const [disbursedDate, setDisbursedDate] = useState(null);
  const [disbursedAmount, setDisbursedAmount] = useState(null);
 
  const [loanId, setLoanId] = useState('');

  const [disbursedUTR, setDisbursedUTR] = useState('');



  //get string validation
  const getStringContain=(str)=>{
    return (/\d/.test(str))
  }
  //get email validation
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const updateDroppedStatus = `/admin/application/update-status`;
  const updateInReviewStatusUrl = `/end-user/submit/admin`; 
  const updateStatusUrl = `/${fundSourceName === "FIB" ? "fibe/approve":"admin/application/approve"}`;
  const updateDisbursedUrl = `/admin/application/disburse`;
  const popUpStatusForm = () => {
    setStatusOpen(true);
  };
  console.log("droppedstte",droppedstate)

  const handleStatusChange = () => {
    if (status === 'Status') {
      alert('NO STATUS CHANGE !');
    } else if (status === 'In Sanction' && fundSourceName !== "Fund Source") {
      setFormLoader(!formLoader);
      setStatusOpen(!formStatusOpen);
      setConfirmationDialog(!confirmationDialog);
      const statusData = {
        applicationId: ApplicantId.ApplicantId.applicationId,
        fundCode: fundSourceName,
        productId: form.productId,
        loanAmount: prodLoanAmount,
        loanTenure: form.tenure,
        emi: prodEmi?prodEmi:emiData,
        emiFirstDate: dateEmi,
        dayOfEmi: dayEmi,
      };

      axiosInstance
        .post(`${updateStatusUrl}`, statusData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then(
          (res) => {
            if (res.data.message === 'Successful') {
              setConfirmationMessage('In Sanction Successfully');
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 500);
            }
          },
          (error) => {
            alert(' Unsuccessful!!! Check Fields/Format');
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 1000);
          },
        );
    }else if(status==="In Review"){
      console.log("inside if dropped",droppedstate)

      const statusData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.InReviewAgain,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate?.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Moved To In Review Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,statusData, user),[])
      }
      
    }
    else if (status === 'In Process') {
      
       const reviewData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.InProcess,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Moved To In Process Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("error");
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
          setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,reviewData, user),[])

      }
    }
  }
 
  useEffect(()=>{
    handleStatusChange();
  },[droppedstate])
  
  const urlApproveApiProd = `/products/instituteId?instituteId=${ApplicantId.ApplicantId.instituteId}`;

  

  const urlApproveApiFund = `/fund_source`;

  const handleApproveForm = (x, y, applId) => {
    
    setProductName(x.target.innerText);
      const urlgetEmi=`/admin/application/emi?applicationId=${applId}&productId=${y.productId}`
    axiosInstance.get(urlgetEmi,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
 
      setEmiData(res.data.data)
    document.getElementById("defaultEMI").defaultValue = res.data.data
  })

   setForm(y)
  };


  //set colender fund source
  const [colenderFundSource,setColenderFundSource]=useState(null)
  const getUserInfo=`/admin/user-info`
const getColenderFundSource=()=>{

      axiosInstance.get(getUserInfo,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res)=>{
      if(res.data.data.colenderId)
      {
        axiosInstance.get(`/fund_source/fund_code?fundCode=${res.data.data.colenderId}`,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
        .then((result)=>
        setColenderFundSource(result.data.data.fundCode))
      }
    })
  }
  const handleApproveApi = (x, y, z) => {
 
    
    setStatus(x.target.innerText);
    axiosInstance
      .get(urlApproveApiProd, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) =>{   
         //setting loan amount
        setLoanAmount(z)
        document.getElementById("loanAmount").defaultValue=z;
        setProdArray(res.data.data)
      }
      );


    if(decode.role==='co_lender')
    {
      getColenderFundSource()
    }
    else{
axiosInstance
      .get(urlApproveApiFund, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => setFundSourceArray(res.data.data));
    }
        
  };

  

  
  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const handleRelationshipToggle = () => {
    setRelationshipStatusDropdown(!relationshipStatusDropdown);
  };
  const handleProductToggle = () => {
    setProductDropDownOpen(!productDropDownOpen);
  };
  const handleFundToggle = () => {
    setFundDropDownOpen(!fundDropDownOpen);
  };
  const handleDescriptionText = (text) => {
    setDescriptionText(text);
  };


  
  return (
    <div>
            <img  onClick={() => popUpStatusForm()} style={{cursor:'pointer',width:'18px'}} src={action3} alt="resend consent"/>
      
      <Modal  isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
      {formStatusOpen && (
        <>
       <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal style={{
        
        
        
        
       height:"100%",
       overflowY:"auto",
        width:"100%",
        borderRadius:"10px"}}
      isOpen={formStatusOpen} size="xl">
         <div style={{backgroundColor:"#E9E9E9",borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',height:'60px',padding:"10px"}}>
                <p style={{fontFamily:"Inter-Medium",fontSize:'14px'}}>Update Status Here (Rejected to <span style={{fontFamily:"Inter-Medium",color:'#D32028'}}>{status}</span> )</p>
                <p style={{fontFamily:"Inter-Medium",cursor: 'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}>
                  <img src={close}/>
                </p>
            </div>
            </div>
           
            <div style={{marginTop:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{id}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{name}</span></p>
            </div>
         
          
        </div>
        <ModalBody>
          <Form>
            <FormGroup>
            <p style={{lineHeight:'10px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Select Status*</p>
              <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
              <DropdownToggle style={{lineHeight:'10px',textAlign:"left",height:'40px',width:'330px',backgroundColor:'white',color:'gray',fontFamily:"Inter-Medium",fontSize:'14px'}} >
                  {status}<span style={{ position: 'absolute',left:"300px", top: '50%', transform: 'translateY(-50%)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="currentColor" d="M1.5 4.5l4.5 4.5 4.5-4.5z" />
                    </svg>
                  </span>
                </DropdownToggle>
                <DropdownMenu >
                <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}
                    onClick={(e) => handleApproveApi(e, ApplicantId.ApplicantId.instituteId,ApplicantId.ApplicantId.courseFees)}
                  >
                    In Sanction
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}
                    onClick={(e) => setStatus(e.target.innerText)}                    >
                    In Review
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}
                      onClick={(e) => setStatus(e.target.innerText)}                  >
                    In Process
                  </DropdownItem>
                 
                </DropdownMenu>
              </Dropdown>

              
              {status === 'In Sanction'? (
                 <div style={{ display: 'flex' }}>
                 <div style={{ display: 'block' }}>
                   <div style={{ display: 'flex' }}>
                     <Dropdown isOpen={fundDropDownOpen} toggle={() => handleFundToggle()}>
                       <DropdownToggle style={{border:"none", marginTop: '1em',fontFamily:"Inter-Medium",fontSize:'14px',backgroundColor:'#D32028' }} caret >
                         {decode.role==='co_lender'?colenderFundSource:fundSourceName}
                       </DropdownToggle>
                       {decode.role==='co_lender'?null:<DropdownMenu>
                         {fundSourceArray.map((item, idx) => {
                           return (
                             // eslint-disable-next-line react/no-array-index-key
                             <DropdownItem
                               key={idx}
                               onClick={(e) => setFundSourceName(e.target.innerText)}
                             >
                               {item.fundCode}
                             </DropdownItem>
                           );
                         })}
                       </DropdownMenu>}
                     </Dropdown>
                     <Dropdown
                       style={{ marginLeft: '4em' }}
                       isOpen={productDropDownOpen}
                       toggle={() => handleProductToggle()}
                     >
                       <DropdownToggle style={{border:"none", marginTop: '1em',fontFamily:"Inter-Medium",fontSize:'14px',backgroundColor:"#D32028" }} caret >
                         {productName}
                       </DropdownToggle>

                       <DropdownMenu>
                         {prodArray.map((item, idx) => {
                           return (
                             // eslint-disable-next-line react/no-array-index-key
                             <>
                             {item.name==="SSSV090101"||item.name==="TSSV090200"?null:<DropdownItem
                               key={idx}
                               onClick={(e) => handleApproveForm(e, prodArray[idx],ApplicantId.ApplicantId.applicationId)}
                             >
                               {item.name}
                             </DropdownItem>}
                             </>
                           );
                         })}
                       </DropdownMenu>
                     </Dropdown>
                   </div>
                   <p style={{ position: 'absolute',lineHeight:'5px', marginTop: '1.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>Tenure</p>
                   <Input
                     type="number"
                     id="inp1"
                     disabled
                     
                     value={form.tenure}
                     style={{
                       height: '30px',
                       width: '465px',
                       marginTop: '3em',
                       borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                     }}
                   />

                   <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Loan Amount</p>
                   <Input
                     type="number"
                     id="loanAmount"
                     placeholder="Enter Loan Amount"
                     onChange={(e) => setLoanAmount(e.target.value)}
                     style={{
                       height: '30px',
                       width: '465px',
                       marginTop: '3em',
                       borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                     }}
                   />
                   <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>EMI</p>
                   <Input
                   disabled
                     type="number"
                     id="defaultEMI"
                     placeholder="enter EMI"
                     style={{
                       height: '30px',
                       width: '465px',
                       marginTop: '3em',
                       borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                     }}
                     onChange={(e)=>setEMI(e.target.value)}
                   />

                   <p style={{ position: 'relative', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>First Date of EMI</p>
                   <Datetime
                     id="fromDate"
                     dateFormat="YYYY/MM/DD"
                     timeFormat={false}
                     onChange={(event) => setEmiDate(event.format('YYYY/MM/DD'))}
                     locale="en-gb"
                     inputProps={{ placeholder: 'Date',style:{fontFamily:'Inter-Medium',fontSize:'14px'} }}
                   />

                   <p style={{ position: 'relative', marginTop: '20px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Day of EMI</p>
                   <Input
                     type="number"
                     id="inp1"
                     placeholder="enter day(1-30)"
                     onChange={(e) => setEmiDay(e.target.value)}
                     style={{
                       height: '30px',
                       width: '465px',
                       marginTop: '2em',
                       borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                     }}
                   />
                 </div>
                 <div
                   style={{
                     display: 'block',
                     marginLeft: '3em',
                     paddingLeft: '3em',
                     borderLeft: '1px dashed #333',fontFamily:'Inter-Medium',fontSize:'14px'
                   }}
                 >
                   <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Facility Type</p>
                   <div style={{ display: 'flex' }}>
                     <Input
                       type="button"
                       disabled={true}
                       id="inp1"
                       value="Discounted Interest"
                       placeholder="enter text"
                       onClick={(e) => console.log(e)}
                       style={{
                         height: '30px',
                         width: '165px',
                         marginRight: '1em',
                         marginTop: '3em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                         color: 'black',
                         fontWeight: 'bold',
                         backgroundColor: form.facilityType === 1 ? '#7CB9E8' : '',
                       }}
                     />
                     <Input
                       type="button"
                       disabled={true}
                       id="inp1"
                       value="Standard Interest"
                       style={{
                         height: '30px',
                         width: '150px',
                         marginRight: '1em',
                         marginTop: '3em',
                         borderRadius: '5px',
                         fontWeight: 'bold',fontFamily:'Inter-Medium',fontSize:'14px',
                         color: 'black',
                         backgroundColor: form.facilityType === 2 ? '#7CB9E8' : '',
                       }}
                     />
                     <Input
                       type="button"
                       disabled={true}
                       id="inp1"
                       value="Hybrid Interest"
                       onClick={(e) => console.log(e)}
                       style={{
                         height: '30px',
                         width: '150px',
                         marginTop: '3em',
                         marginRight: '1em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                       }}
                     />
                   </div>

                   <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Interest</p>
                   <div style={{ display: 'flex' }}>
                     {' '}
                     <Input
                       type="button"
                       value={
                         form.discountedInterestPercentage !== 0
                           ? form.discountedInterestPercentage
                           : 'N/A'
                       }
                       id="inp1"
                       placeholder="enter text"
                       onClick={(e) => console.log(e)}
                       style={{
                         height: '30px',
                         width: '165px',
                         marginTop: '3em',
                         borderRadius: '5px',
                         fontWeight: 'bold',fontFamily:'Inter-Medium',fontSize:'14px',
                         color: 'black',
                         backgroundColor: form.discountedInterestType === 1 ? '#7CB9E8' : '',
                       }}
                     />
                     <Input
                       type="button"
                       id="inp1"
                       disabled={true}
                       value={
                         form.standardInterestPercentage !== 0
                           ? form.standardInterestPercentage
                           : 'N/A'
                       }
                       placeholder="enter text"
                       onClick={(e) => console.log(e)}
                       style={{
                         height: '30px',
                         width: '165px',
                         marginTop: '3em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                         fontWeight: 'bold',
                         color: 'black',
                         backgroundColor: form.standardInterestType === 1 ? '#7CB9E8' : '',
                       }}
                     />
                   </div>

                   <div style={{ display: 'flex' }}>
                     <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Process Fee</p>
                     <span style={{ position: 'relative', marginTop: '30px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Flat</span>
                     <span style={{ position: 'relative', marginTop: '30px', marginLeft: '15px' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>
                       Per(%)
                     </span>
                     <Input
                       type="radio"
                       id="inp1"
                       disabled
                       style={{
                         height: '20px',
                         width: '30px',
                         position: 'absolute',
                         marginTop: '4em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                       }}
                       checked={form.processingfeeType === 1 ? true : false}
                     />
                     <Input
                       type="radio"
                       id="inp1"
                       disabled
                       style={{
                         height: '20px',
                         width: '30px',
                         position: 'absolute',
                         marginTop: '4em',
                         marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px',
                         borderRadius: '5px',
                       }}
                       checked={form.processingfeeType === 2 ? true : false}
                     />
                     <p style={{ position: 'absolute', marginLeft: '9em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                       Value
                     </p>
                     <Input
                       type="text"
                       id="inp1"
                       disabled
                       placeholder="value"
                       value={form.processingfeeValue}
                       style={{
                         height: '30px',
                         marginLeft: '1em',fontFamily:'Inter-Medium',fontSize:'14px',
                         width: '100px',
                         marginTop: '3.5em',
                         borderRadius: '5px',
                       }}
                     />

                     <p style={{ position: 'absolute', marginLeft: '16em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                       GST applicable
                     </p>
                     <Input
                       type="checkbox"
                       id="inp1"
                       disabled
                       placeholder="enter text"
                       checked={form.processingfeeValueGST ? true : false}
                       style={{
                         height: '30px',
                         width: '50px',
                         marginLeft: '4em',fontFamily:'Inter-Medium',fontSize:'14px',
                         marginTop: '3.5em',
                         borderRadius: '5px',
                       }}
                     />
                     <p style={{ position: 'absolute', marginLeft: '27em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                       Total Fee
                     </p>
                     <Input
                       type="text"
                       id="inp1"
                       disabled
                       placeholder="Total fee"
                       value={form.processingfeValueIncludingGST}
                       style={{
                         height: '30px',
                         width: '115px',
                         marginLeft: '3em',
                         marginTop: '3.5em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                       }}
                     />
                   </div>

                   <p style={{ position: 'absolute', marginTop: '45px',fontFamily:'Inter-Medium',fontSize:'14px'}}>FLDG(Yes/No)</p>

                   <div style={{ marginLeft: '8em', marginTop: '3em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>
                     {' '}
                     <Switch disabled onText="YES" offText="NO" value={form.fldgApplicable} />
                     {'  '}{' '}
                   </div>
                   <div style={{ display: 'flex' }}>
                     <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Advance EMIs</p>
                     <Input
                       type="text"
                       id="inp1"
                       disabled
                       title="number between 0-3"
                       placeholder="number between 0-3"
                       value={form.advanceEmis}
                       style={{
                         height: '30px',
                         width: '100px',
                         marginTop: '3em',
                         borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                       }}
                     />
                     <p style={{ position: 'absolute', marginTop: '10px', marginLeft: '13em',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                       IRR
                     </p>
                     <Input
                       type="text"
                       id="inp1"
                       disabled
                       title="number between 0-3"
                       placeholder="number between 0-3"
                       value={form.irr}
                       style={{
                         height: '30px',
                         width: '100px',
                         marginTop: '3em',
                         marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px',
                         borderRadius: '5px',
                       }}
                     />
                   </div>
                   <p style={{ position: 'absolute', marginTop: '15px', fontWeight: '650',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                     Disbursements
                   </p>
                   <div style={{ position: 'absolute', marginTop: '40px', display: 'flex',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                     <div style={{ display: 'block' }}>
                       <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Months:</p>{' '}
                       <ul
                         style={{
                           marginTop: '-55px',
                           marginLeft: '5em',
                           listStyle: 'none',
                           display: 'flex',
                           position: 'absolute',fontFamily:'Inter-Medium',fontSize:'14px'
                         }}
                       >
                         <li style={{ marginLeft: '3.5em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth1}</li>
                         <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth2}</li>
                         <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth3}</li>
                         <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth4}</li>
                       </ul>
                       <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Percentage:</p>{' '}
                       <ul
                         style={{
                           marginTop: '-55px',
                           marginLeft: '5em',
                           listStyle: 'none',
                           display: 'flex',
                           position: 'absolute',
                         }}
                       >
                         <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth1Percentage}</li>
                         <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth2Percentage}</li>
                         <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth3Percentage}</li>
                         <li style={{ marginLeft: '3em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth4Percentage}</li>
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>
              ) : 
              (
                <></>
              )}

              {(status === "In Review" || status === "In Process")?
              <>
               <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '330px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </>:(<></>)
              }

              
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{display:"flex",justifyContent:"center",backgroundColor:"#E9E9E9"}}>
          
          <Button style={{backgroundColor:"#D32028",color:"white",fontFamily:"Inter-Medium",fontSize:"13px",border:"solid",borderRadius:"5px",borderWidth:"1px",height:"30px"}} disabled={formLoader} onClick={() => handleStatusChange()}>
            Save and Submit
          </Button>
        </ModalFooter>
      </Modal>
      </>
      )}
    </div>
  );
}



