import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Autocomplete, TextField} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../actions/user.actions';
import CustomTextField from '../../../TextField/TextField'
import { Box } from '@mui/material'
import Button from '@mui/material/Button';
import './BasicLoanForm.css'
import { tenureOptions, subVerticals, propertyTypeOptions, vehicleTypeOptions, statesList } from '../../../../data';
import InputFileUpload from '../Fileupload/FileUpload';
import aadhaarImage from '../../../../assets/images/aadhar_image.png'
import panImage from '../../../../assets/images/pan-image.png'
import drivingLicenseImg from '../../../../assets/images/Driving-license-image.jpg'
import bankAccountImg from '../../../../assets/images/Bank-Account-image.jpg'
import saleDeed from '../../../../assets/images/saledeed.jpg'
import ec from '../../../../assets/images/ec.svg'
import legalOpinion from '../../../../assets/images/legal_opinion.jpg'
import electricityGasBillImg from '../../../../assets/images/electricity-gas-bill.jpg'
import othersImg from '../../../../assets/images/others-image.jpg'
import CustomAutoComplete from '../../../AutoComplete/AutoComplete'
import DeleteIcon from '@mui/icons-material/Delete';
import {Checkbox} from '@mui/material';
import axiosInstance from '../../../../helpers/axios';
import jwt_decode from 'jwt-decode'
import moment from 'moment'


const fileCardTypes = [
    { value: 'Aadhaar Front', label: 'Aadhaar Front', id: 'aadhaar_front', image:aadhaarImage, disabled:false },
    { value: 'Aadhaar Back', label: 'Aadhaar Back', id: 'aadhaar_back', image:aadhaarImage, disabled:false },
    { value: 'PAN', label: 'PAN', id: 'pan' , image:panImage, disabled:false},
    { value: 'Driving License Front', label: 'Driving License Front', id: 'driving_license_front', image:drivingLicenseImg , disabled:false},
    { value: 'Driving License Back', label: 'Driving License Back', id: 'driving_license_back', image:drivingLicenseImg , disabled:false},
    { value: 'Bank Statement 3m', label: 'Bank Statement 3m', id: 'bank_statement3', image:bankAccountImg , disabled:false},
    { value: 'Bank Statement 6m', label: 'Bank Statement 6m', id: 'bank_statement6', image:bankAccountImg , disabled:false},
    { value: 'Utility Bill', label: 'Utility Bill', id: 'utility_bill', image:electricityGasBillImg, disabled:false },
    { value: 'Others', label: 'Others', id: 'others', image:othersImg, disabled:false },

]

const mlFileCardTypes = [
  ...fileCardTypes, 
  { value: 'Original Sale deed copy', label: 'Original Sale deed copy', id: 'original_sale_deed_copy', image: saleDeed, disabled:false},
  { value: 'Original Sale deed copy', label: 'EC', id: 'ec', image:ec , disabled:false},
  { value: 'Original Sale deed copy', label: 'Legal Opinion', id: 'legal_opinion', image:legalOpinion, disabled:false },
]

const clientId = '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'

export default function BasicLoanForm({loanType}) {
  const dispatch = useDispatch()
  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user)
  const [employment, setEmployment] = useState('EMPLOYED')
  const [uploadedFile,setUploadedFile]=useState(null)
  const [documentType,setDocumentType]=useState(null)
  const [filePassword,setFilePassword]=useState()
  const [instituteName, setInistuteName] = useState('')
  const [instituteList,setInstituteList]=useState([])
  const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")
  const [studentFirstName, setStudentFirstName] = useState(null)
  const [studentLastName, setStudentLastName] = useState(null)
  const [studentEmail, setStudentEmail] = useState(null)
  const [studentPhone, setStudentPhone] = useState(null)
  const [courseName, setCourseName] = useState(null)
  const [courseFees, setCourseFees] = useState(null)
  const [tenure, setLoanTenure] = useState(null)
  
  const [borrowerFirstName,setBorrowerFirstName]=useState(null)
  const [borrowerLastName,setBorrowerLastName]=useState(null)
  const [borrowerEmail,setBorrowerEmail]=useState(null)
  const [borrowerPhone,setBorrowerPhone]=useState(null)
  const [borrowerAadhaar,setBorrowerAadhaar]=useState(null)
  const [borrowerAddress,setBorrowerAddress]=useState(null)
  const [borrowerCity,setBorrowerCity]=useState(null)
  const [borrowerState,setBorrowerState]=useState(null)
  const [borrowerPin,setBorrowerPin]=useState(null)
  const [borrowerPan,setBorrowerPan]=useState(null)
  const [borrowerDOB,setBorrowerDOB]=useState(null)
  const [isStudentApplicant,setIsStudentApplicant]=useState(false)

  const [employerName, setEmployerName] = useState(null)
  const [borrowerSalary, setBorrowerSalary] = useState(null)

  const [vehicleType, setVehicleType] = useState(null)
  const [vehicleBrand, setVehicleBrand] = useState(null)
  const [vehicleModel, setVehicleModel] = useState(null)
  const [vehicleInsuranceDate, setVehicleInsuranceDate] = useState(null)
  const [otherDetails, setOtherDetails] = useState(null)

  const [propertyType, setPropertyType] = useState(null)
  const [propertySize, setPropertySize] = useState(null)
  const [propertyAddress, setPropertyAddress] = useState(null)
  const [propertyDescription, setPropertyDescription] = useState(null)

  const [disableFields, setDisabledFields] = useState(false)
  const [address, setAddress]= useState({})
  const [sameAddress, setSameAddress] = useState(null)
  const [permanentPincode, setPermanentPinCode] = useState(null)
  const [currentPincode, setCurrentPinCode] = useState(null)
  const [fileUploadCards, setFileUploadCards] = useState(loanType==='Mortgage Loan'?mlFileCardTypes:  fileCardTypes)

  let [uploadedFilesArray,setUploadedFilesArray]=useState([])

  const [errors, setErrors] = useState({})
  const [panApiLimit, setPanApiLimit] = useState(1)
  
  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const handleEmploymentChange = (e) => {
    setEmployment(e.target.value)
  }

  const handleFiles = (newFile, documentType) => {
    setUploadedFile(newFile)
    setDocumentType(documentType)
  }
  useEffect(() => {
    if (uploadedFile && documentType) {
      handleFileUpload(documentType);
    }
  }, [uploadedFile, documentType]);

  const handleFileUpload= async (documentType)=>{
    const urlUpload='/file/upload'
    const uploadObj=new FormData()
    if(uploadObj){
        uploadObj.append('file',uploadedFile)
        uploadObj.append('type',documentType)
      try{
        
        const res = await axiosInstance.post(urlUpload,uploadObj)
        const obj={
          userId: "",
          type:documentType==="aadhaar_back"||documentType==="aadhaar_front"?"aadhaar":documentType==="driving_license_back"||documentType==="driving_license_front"?"driving_license":documentType,
          url1:documentType!=="aadhaar_back"&&documentType!=="driving_license_back"? res?.data?.data?.s3Url: "",
          url2:(documentType==="aadhaar_back"||documentType==="driving_license_back")?res?.data?.data?.s3Url:"",
          documentId:'string',
          documentType:res?.data?.data?.s3Url,
          documentPassword:filePassword,
          fileType:documentType,
          fileName:uploadedFile.name
        }

        setFileUploadCards(prev =>
          prev.map(item => item.id === documentType ? {...item, disabled:true}: item)
        )

        if(uploadedFilesArray.length === 0){
          setUploadedFilesArray([obj])
        }
        else{
          setUploadedFilesArray(old => [...old, obj])
        }
        setUploadedFile(null) 
        setFilePassword(null)
        }
      catch(e){
        console.log('error', e)
        alert('Network Error, Please reupload the file')
      }
    }
      
  }

  const getPinCodeAddress = async (pincode) => {
    const url = `/pincodes/pincode?pincode=${pincode}`;
    try {
      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${user}` }
      });

      if (response?.status === 200) {
        const data = {stateName: response?.data?.data?.stateName, districtName:  response?.data?.data?.districtName}
        return data
      }
    } catch (error) {
      console.error("Error fetching address with pincode:", error);
    }
  }
  
  const getPermanentAddressWithPin =async  (pincode) => {
    setBorrowerPin(pincode)
    if (pincode.length === 6) {
      const {stateName, districtName} = await getPinCodeAddress(pincode)
      setBorrowerCity(districtName);
      setBorrowerState(stateName);
      setAddress({ ...address,permanentCity: districtName, permanentState: stateName}) 
    }
  };

  const getCurrentAddressWithPin = async (pincode) => {
    if (pincode.length === 6) {
      const {stateName, districtName} = await getPinCodeAddress(pincode)
      setAddress({ ...address,currentCity: districtName, currentState: stateName})
    }

  };

  const handleDeleteFile = (fileType) => {
      const updatedFileUploadCards = fileUploadCards.map(item => item.id===fileType ? {...item, disabled:false}: item)
      setFileUploadCards(updatedFileUploadCards)
      const finalFiles = uploadedFilesArray.filter(file => file.fileType !== fileType)
      setUploadedFilesArray(finalFiles)
  }

  const getInstituteInfo=(text)=>{
    setInistuteName(text)
    const instituteInfoUrl=`/institute/info/name?name=${text}`;
    if(text?.length>=4)
    {
      axiosInstance.get(instituteInfoUrl,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res)=>{
        if(res.data.data==[])
        {
          setInstituteList([])
          setStarterPhrase("No institute Found")
        }
        else
        setInstituteList(res.data.data)
        setStarterPhrase("No institute Found")
      })
    }
    else{
      setInstituteList([])
      setStarterPhrase("Type Institute Name")
    }
  }

  const handleStudentBorrower=(event)=>{
      
    if(event.target.checked)
    {
      setIsStudentApplicant(true)
      setBorrowerFirstName(studentFirstName)
      setBorrowerLastName(studentLastName)
      setBorrowerPhone(studentPhone)
      setBorrowerEmail(studentEmail)
      setDisabledFields(true)
    }
    else
    { 
      setIsStudentApplicant(false)
      setBorrowerFirstName(null)
      setBorrowerLastName(null)
      setBorrowerPhone(null)
      setBorrowerEmail(null)
      setDisabledFields(false)
    }



  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

  const getPanPro=()=>{
   if(panApiLimit > 5){
     alert("You've reached your maximum API call limit for PAN details. Try again later.")
     window.location.reload(); 
     return
   }

    const panProUrl='/pan-pro'
    const panBody={
        
      panNumber     :     borrowerPan,
      dateOfBirth   :     moment(borrowerDOB).format('DD/MM/YYYY')  
    }

    if(getAge(borrowerDOB)<=18)
      {
       window.alert("Check borrower age ! must be above 18 years old")
      }
      else
      {
      axiosInstance.post(panProUrl,panBody)
      .then((res)=>{
        
        if(res?.data?.data?.pan_number)
        {
          if(decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'){          
            createBijayApplications(res?.data?.data, uploadedFilesArray)  
          }
          else{
            createApplications(res?.data?.data, uploadedFilesArray)
  
          }
          
          window.scrollTo(0, document.body.scrollHeight);
          // window.alert("Saved Details! Upload Documents Below.")
          
        }
        else
        {
          setPanApiLimit(prev => prev+1)
          alert("Invalid PAN Details or Date of Birth")
        }

      })
      .catch((err)=>{
        alert("Invalid PAN Details or Date of Birth")
      })
    }
  }


  const defineBodyData = (panProDetails, uploadedArray) => {
    const get1=uploadedArray
    const get2=uploadedArray
    const get3=uploadedArray
    const aadhaarUrl1=get1?.filter(item=>item.type==="aadhaar")[0]?.url1
    const aadhaarUrl2=get2?.filter(item=>item.type==="aadhaar")[0]?.url2
    const panUrl=get3?.filter(item=>item.type==="pan")[0]?.url1
    let body = {
       // "ocrId": "string",
      mobile: borrowerPhone,
      instituteName: loanType,
      firstName: borrowerFirstName,
      lastName: borrowerLastName,
        // "studentName": "string",
      dateOfBirth: borrowerDOB,
      gender: panProDetails?.user_gender === "M" ? "Male":"Female",
      isCoapplicant: false,
      // courseName: loanType,
      courseFees: courseFees,
      panId: borrowerPan.toUpperCase(),
      panImage: panUrl,
      aadhaarId: panProDetails?.masked_aadhaar,
      aadhaarFrontImage: aadhaarUrl1,
      aadhaarBackImage: aadhaarUrl2,
      employmentType: employment === "EMPLOYED" ? "salaried": employment === "SELF_EMPLOYED" ? "self-employed": "unemployed",
      employerName: employment==="EMPLOYED"?employerName:"",
      salary: employment==="EMPLOYED"?borrowerSalary:"",
      incomePerMonth:  employment==="SELF_EMPLOYED"?borrowerSalary:"",
      typeOfBusiness: employment==="SELF_EMPLOYED"?employerName:"",
      email: borrowerEmail,
      currentAddress: address.currentAddress,
      currentCity: address.currentCity,
      currentState: address.currentState,
      currentPincode: currentPincode,
      loanTenure: tenure,
      permanentAddress: address.permanentAddress,
      permanentCity: address.permanentCity,
      permanentState: address.permanentState,
      permanentPincode: permanentPincode,
      // "bankStatementS3": "string",
      // "productId": "string",
      // "accountNumber": "string",
      // "ifsc": "string",
      // beneficiaryName: borrowerFirstName + " " + borrowerLastName,

       // "propertyType": "string",
        // "propertyDescription": "string",
        // "propertyAddress": "string",
        // "propertySize": "string",
        // "otherDetails": "string",
        clientId: clientId
    };
    if(loanType==='Consumer Loan'){
      body={...body, productType: 1}
    }
    else if(loanType==='Personal Loan'){
      body={...body, productType: 2}
    }
    else if(loanType === 'Vehicle Loan'){
      body={
        ...body,
        productType: 3,
        subProductType: subVerticals[2]['New'],
        vehicleType: vehicleType,
        vehicleBrandName: vehicleBrand,
        vehicleModel: vehicleModel,
        vehicleInsuranceFromDate: vehicleInsuranceDate,
        otherDetails: otherDetails,
      }
    }
    else if(loanType === 'Mortgage Loan'){
      body={
       ...body,
        productType: 4,
        subProductType: subVerticals[3]['New'],
        propertyType: propertyType,
        propertySize: propertySize,
        propertyAddress: propertyAddress,
        propertyDescription: propertyDescription,
        otherDetails: otherDetails,
      }
    }
    return body
  }
  
  const createBijayApplications=(panProDetails,uploadedArray)=>{
    const body = defineBodyData(panProDetails, uploadedArray)
    const url='/summary/create'
      
    dispatch(userActions.createApplication(url,body,user,uploadedArray))
  }

  const createApplications=(panProDetails,uploadedArray)=>{

    const get1=uploadedArray
    const get2=uploadedArray
    const get3=uploadedArray
    const aadhaarUrl1=get1?.filter(item=>item.type==="aadhaar")[0]?.url1
    const aadhaarUrl2=get2?.filter(item=>item.type==="aadhaar")[0]?.url2
    const panUrl=get3?.filter(item=>item.type==="pan")[0]?.url1
    
      const urlCreate='/summary/create/admin'
      
      

        const body={
          
           mobile: isStudentApplicant?studentPhone:borrowerPhone,
           instituteName:instituteName,
           firstName: isStudentApplicant?studentFirstName:borrowerFirstName,
           lastName: isStudentApplicant?studentLastName:borrowerLastName,
           studentName: `${studentFirstName} ${studentLastName}`,
           dateOfBirth: borrowerDOB,
           gender: panProDetails?.user_gender === "M" ? "Male":"Female",
           isCoapplicant: false,
           courseName: courseName,
           courseFees: courseFees,
           panId: borrowerPan.toUpperCase(),
           panImage: panUrl,
           aadhaarId: panProDetails?.masked_aadhaar,
           aadhaarFrontImage: aadhaarUrl1,
           aadhaarBackImage: aadhaarUrl2,
           employmentType:
                        employment === "EMPLOYED"
                          ? "salaried"
                          : employment === "SELF_EMPLOYED"
                          ? "self-employed"
                          : "unemployed",
            employerName: employment==="EMPLOYED"?employerName:"",
            salary: employment==="EMPLOYED"?borrowerSalary:"",
            incomePerMonth: employment==="SELF_EMPLOYED"?borrowerSalary:"",
            typeOfBusiness: employment==="SELF_EMPLOYED"?employerName:"",
           email: isStudentApplicant?studentEmail:borrowerEmail,
           currentAddress:borrowerAddress,
           currentCity: borrowerCity ,
           currentState: borrowerState,
           currentPincode: borrowerPin,
           loanTenure: tenure,
           channel:decode?.role!=="institute_user"&&decode?.role!=="institute_admin"?null:3
         
   } 
         dispatch(userActions.createApplication(urlCreate,body,user,uploadedArray)) 

  }

  const validateFields = () => {
    let formErrors = {}
    if(loanType === 'Education Loan'){
      if(!instituteName){
        formErrors.instituteName = "Please enter institute name"
  
      }
      if(!studentFirstName){
        formErrors.studentFirstName = "Enter student's first name"
  
      }
      if(!studentLastName){
        formErrors.studentLastName = "Enter student's Last name"
  
      }
      if(!validateEmail(studentEmail)){
        formErrors.studentEmail = "Please enter a valid email address"
  
      }
      if(studentPhone?.length !== 10){
        formErrors.studentPhone = "Number must be 10 digits"
  
      }
      if(!courseName){
        formErrors.courseName = "Please enter course name"
  
      }
    }
    if(loanType === 'Vehicle Loan'){
      if(!vehicleType){
        formErrors.vehicleType = "Select Vehicle Type"
  
      }
      if(!vehicleBrand){
        formErrors.vehicleBrand = "Enter Vehicle Brand"
  
      }
      if(!vehicleModel){
        formErrors.vehicleModel = "Enter Vehicle Model"
  
      }
      // if(!vehicleInsuranceDate){
      //   formErrors.vehicleInsuranceDate = "Enter Insurance Date"
  
      // }
    }
    if(loanType === 'Mortgage Loan'){
      if(!propertyType){
        formErrors.propertyType = "Select Property Type"
  
      }
      if(!propertySize){
        formErrors.propertySize = "Enter Property Size"
  
      }
      if(!propertyAddress){
        formErrors.propertyAddress = "Enter Property Address"
  
      }
      if(!propertyDescription){
        formErrors.propertyDescription = "Enter Property Description"
  
      }
    }
    if(!courseFees > 0){
      formErrors.courseFees = "course fees must be more than 0"

    }

    if(!tenure){
      formErrors.tenure = "Select loan tenure"

    }

    //Borrower details check
    
    if(!borrowerFirstName){
      formErrors.borrowerFirstName = "Enter barrower's first name"

    }
    if(!borrowerLastName){
      formErrors.borrowerLastName = "Enter barrower's last name"

    }
    if(!validateEmail(borrowerEmail)){
      formErrors.borrowerEmail = "Please enter a valid email address"

    }
    if(borrowerPhone?.length !== 10){
      formErrors.borrowerPhone = "Number must be 10 digits"

    }
    if(borrowerAadhaar?.length !== 12){
      formErrors.borrowerAadhaar = "Aadhaar must be 12 digits"

    }
    if(borrowerPan?.length !== 10){
      formErrors.borrowerPan = "Please enter a valid PAN"

    }
    if (!borrowerDOB) {
      formErrors.borrowerDOB = "Date of Birth is required";
    } else if (getAge(borrowerDOB) < 18) {
      formErrors.borrowerDOB = "Borrower must be at least 18 years old";
    }
    if(decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'){
      if(!borrowerAddress){
        formErrors.borrowerAddress = "Please enter address"
  
      }
      if(borrowerPin?.length !== 6){
        formErrors.borrowerPin = "PIN must be 6 digits"
  
      }
      if(!borrowerCity){
        formErrors.borrowerCity = "Please enter city"
  
      }
      if(!borrowerState){
        formErrors.borrowerState = 'Please enter state'
  
      }
    }
    
    
    if(employment==='EMPLOYED' || employment ==='SELF_EMPLOYED'){
      if(!employerName){
        formErrors.employerName = "Enter employer name"
  
      }
      if(!borrowerSalary){
        formErrors.borrowerSalary = "Please enter salary"
  
      }
    }
    //Permanent & Current Address
    if(decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'){
      if(!address.permanentAddress){
        formErrors.permanentAddress = 'Enter address'
      }
      if(!permanentPincode){
        formErrors.permanentPincode = 'PIN must be 6 digits'
      }
      if(!address.permanentCity){
        formErrors.permanentCity = 'Enter city'
      }
      if(!address.permanentState){
        formErrors.permanentState = 'Please enter state'
      }
      if(!address.currentAddress){
        formErrors.currentAddress = 'Enter address'
      }
      if(!currentPincode){
        formErrors.currentPincode = 'PIN must be 6 digits'
      }
      if(!address.currentCity){
        formErrors.currentCity = 'Enter city'
      }
      if(!address.currentState){
        formErrors.currentState = 'Please enter state'
      }
      
    }
    
   
    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const validateFiles = () => {
    return uploadedFilesArray.length > 0
  }
   
  const handleSubmit = () => {
    if(validateFields()){
      if(validateFiles()){
        getPanPro()
     }
     else{
      alert('Please upload Aadhaar/PAN')
     }
    }
    else{
      alert('Incomplete information Given, Please Check the Details once')
    }
   
  }

  const handleCheckboxChange = (event) => {
    if(event.target.checked){
      setAddress({
        ...address,
        permanentAddress: address.permanentAddress || '',
        permanentCity : address.permanentCity || '',
        permanentState: address.permanentState || '' ,
        permanentPincode: address.permanentPincode || '',
        currentAddress: address.permanentAddress || '',
        currentCity: address.permanentCity || '',
        currentState: address.permanentState || '',
        currentPincode: permanentPincode,
      })
      setCurrentPinCode(permanentPincode)
    }
    else{
      setAddress({...address, 
        permanentAddress: address.permanentAddress,
        permanentCity : address.permanentCity || '',
        permanentState: address.permanentState || '' ,
        permanentPincode: address.permanentPincode || '',
        currentAddress: '',
        currentCity: '',
        currentState: '',
        currentPincode: '',
      })
      setCurrentPinCode(null)
    }
    setSameAddress(event.target.checked);
  };

  return (
    <>
      {loanType=== 'Education Loan' && 
          <Box  className='fields-container'>
          <h5 className='form-heading'>Student Details</h5>
            <Box
            sx={{ display:'flex' ,justifyContent:'space-around', flexWrap:'wrap'}}

          > 
            <CustomAutoComplete label={'Institute Name'} id='instituteName' value={decode?.role==="institute_user"||decode?.role==="institute_admin"?decode?.instituteName:instituteName} error={!!errors.instituteName} disabled={decode?.role==="institute_user"||decode?.role==="institute_admin"?true:false} helperText={errors.instituteName} noOptionsText={startingPhrase} options={instituteList} handleAutoCompleteChange={(name) => getInstituteInfo(name)} /> 
            <CustomTextField label={'First Name'} value={studentFirstName}  error={!!errors.studentFirstName} helperText={errors.studentFirstName} handleTextFieldOnChange={(stFtName) => setStudentFirstName(stFtName)}/>
            <CustomTextField label={'Last Name'} value={studentLastName}  error={!!errors.studentLastName} helperText={errors.studentLastName}  handleTextFieldOnChange={(stLtName) => setStudentLastName(stLtName)}/>
            <CustomTextField label={'Mobile'} type='number' value={studentPhone} error={!!errors.studentPhone} helperText={errors.studentPhone}    handleTextFieldOnChange={(stPhone) => setStudentPhone(stPhone)}/> 
            <CustomTextField label={'Email'} type='email' value={studentEmail}  error={!!errors.studentEmail} helperText={errors.studentEmail}  handleTextFieldOnChange={(stEmail) => {setStudentEmail(stEmail)}}/>
            <CustomTextField label={'Course Name'} value={courseName} error={!!errors.courseName} helperText={errors.courseName}  handleTextFieldOnChange={(cName) => setCourseName(cName)}/>
            <CustomTextField label={'Course Fees'} type='number' value={courseFees}   error={!!errors.courseFees} helperText={errors.courseFees} handleTextFieldOnChange={(cFee) => setCourseFees(cFee)}/>
            <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={tenureOptions} size='small'
                 onChange={(e, value) => setLoanTenure(value?.label)}
                renderInput={(params) => <TextField {...params} label="Loan Tenure" 
                error={!!errors.tenure} helperText={errors.tenure}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
                
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            /> 
          </Box>
            <span style={{display:'flex', alignItems:'center', fontFamily:'Outfit', fontWeight:'bold'}}>
              <Checkbox checked={isStudentApplicant} onChange={(e) => handleStudentBorrower(e)}  id="studentBorrowerCheck"/> Is Student the borrower   
              </span>
            </Box>
      }
      <Box  className='fields-container'>
        <h5 className='form-heading'>Borrower Details</h5>
        <Box sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}} >
          <CustomTextField label={'First Name'} id='borrowerFirstName' disabled={disableFields} value={borrowerFirstName}  error={!!errors.borrowerFirstName} helperText={errors.borrowerFirstName}  handleTextFieldOnChange={(bftName) => setBorrowerFirstName(bftName)}/>
          <CustomTextField label={'Last Name'} id='borrowerLastName' disabled={disableFields} value={borrowerLastName}  error={!!errors.borrowerLastName} helperText={errors.borrowerLastName}  handleTextFieldOnChange={(bltName) => setBorrowerLastName(bltName)}/>
          <CustomTextField label={'Phone'} type={'number'} id='borrowerPhone' disabled={disableFields} value={borrowerPhone} error={!!errors.borrowerPhone} helperText={errors.borrowerPhone} handleTextFieldOnChange={(bPhone) => setBorrowerPhone(bPhone)}/>
          <CustomTextField label={'Email'} type={'email'} id='borrowerEmail' disabled={disableFields} value={borrowerEmail} error={!!errors.borrowerEmail} helperText={errors.borrowerEmail} handleTextFieldOnChange={(bEmail) => {setBorrowerEmail(bEmail)}}/>
          <CustomTextField label={'Aadhaar No'} type='number'  value={borrowerAadhaar} error={!!errors.borrowerAadhaar} helperText={errors.borrowerAadhaar}  handleTextFieldOnChange={(bAadhaar) => setBorrowerAadhaar(bAadhaar)}/>
          <CustomTextField label={'PAN'} value={borrowerPan} error={!!errors.borrowerPan} helperText={errors.borrowerPan} handleTextFieldOnChange={(bPan) => setBorrowerPan(bPan.toUpperCase())}/>
          <CustomTextField label='Date Of Birth' type='date' value={borrowerDOB} error={!!errors.borrowerDOB} helperText={errors.borrowerDOB} handleTextFieldOnChange={(dob) => setBorrowerDOB(dob)}/>
          {loanType!=='Education Loan' && 
          <>
           <CustomTextField label={'Loan Amount'} type='number' value={courseFees}   error={!!errors.courseFees} helperText={errors.courseFees} handleTextFieldOnChange={(cFee) => setCourseFees(cFee)}/>
            <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={tenureOptions} size='small'
                 onChange={(e, value) => setLoanTenure(value?.label)}
                renderInput={(params) => <TextField {...params} label="Loan Tenure" 
                error={!!errors.tenure} helperText={errors.tenure}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
                
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            /> 
            </>
          }
          { decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' &&<> 
          <CustomTextField label={'Address'} value={borrowerAddress}  error={!!errors.borrowerAddress} helperText={errors.borrowerAddress} handleTextFieldOnChange={(bAddress) => setBorrowerAddress(bAddress)}/>
          <CustomTextField label={'PIN Code'} type='number' value={borrowerPin} error={!!errors.borrowerPin} helperText={errors.borrowerPin} handleTextFieldOnChange={(bPin) => {
                  getPermanentAddressWithPin(bPin)
              }
          }/>
          <CustomTextField label={'City'} value={borrowerCity} error={!!errors.borrowerCity} helperText={errors.borrowerCity}   handleTextFieldOnChange={(bCity) => setBorrowerCity(bCity)}/>
          <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={statesList} size='small'
                 value={borrowerState}
                 onChange={(e, value) => setBorrowerState(value?.label)}
                renderInput={(params) => <TextField {...params} label="State" 
                error={!!errors.borrowerState} helperText={errors.borrowerState}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
                
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            />
            </>
            }
        </Box>
      </Box>
      {
       decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && 
       <Box  className='fields-container'>
        <h5 className='form-heading'>Permanent Address</h5>
          <Box sx={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}>
          <CustomTextField label={'Address'} value={address.permanentAddress}  error={!!errors.permanentAddress} helperText={errors.permanentAddress} handleTextFieldOnChange={(doNo) => setAddress({...address, permanentAddress: doNo})}/>
          <CustomTextField label={'PIN Code'} type='number' value={permanentPincode} error={!!errors.permanentPincode} helperText={errors.permanentPincode} handleTextFieldOnChange={(pin) =>{
             console.log(pin, 'pinnnnnnnnnn')
             setPermanentPinCode(pin)
             getPermanentAddressWithPin(pin)
          }}/>
          <CustomTextField label={'City'} value={address.permanentCity} error={!!errors.permanentCity} helperText={errors.permanentCity}   handleTextFieldOnChange={(city) => setAddress({...address, permanentCity: city})}/>
          <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={statesList} size='small'
                 value={address.permanentState || null}
                 onChange={(e, value) => setAddress({...address, permanentState: value})}
                renderInput={(params) => <TextField {...params} label="State" 
                error={!!errors.permanentCity} helperText={errors.permanentCity}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
                
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            />
          </Box>
          <span style={{display:'flex', alignItems:'center', fontFamily:'Outfit', fontWeight:'normal'}}>
              <Checkbox size='small' checked={sameAddress} onChange={(e) => handleCheckboxChange(e)}  id="studentBorrowerCheck"/>Is this your Current Address?  
          </span>
          <h5 className='form-heading'>Current Address</h5>
          <Box sx={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}>
          <CustomTextField label={'Address'} value={address.currentAddress}  error={!!errors.currentAddress} helperText={errors.currentAddress} handleTextFieldOnChange={(doNo) => setAddress({...address, currentAddress: doNo})}/>
          <CustomTextField label={'PIN Code'} type='number' value={currentPincode} error={!!errors.currentPincode} helperText={errors.currentPincode} handleTextFieldOnChange={(pin) =>{
              setCurrentPinCode(pin)
              getCurrentAddressWithPin(pin)
          }}/>
          <CustomTextField label={'City'} value={address.currentCity|| null} error={!!errors.currentCity} helperText={errors.currentCity}   handleTextFieldOnChange={(city) => setAddress({...address, currentCity: city})}/>
          <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={statesList} size='small'
                 value={address?.currentState || null}
                 onChange={(e, value) => setAddress({...address, currentState: value})}
                renderInput={(params) => <TextField {...params} label="State" 
                error={!!errors.currentState} helperText={errors.currentState}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
               
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            />
          </Box>
          
          
      </Box>
      }
      <Box  className='fields-container'>
          <h5 className='form-heading'>Employment</h5>
          <Box sx={{display:'flex', justifyContent:'center',mt:-4, mb:3}}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row 
              value={employment}
              onChange={handleEmploymentChange}
            >
              <FormControlLabel value="EMPLOYED" control={<Radio />} label="Salaried" sx={{mr:5,  '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
              <FormControlLabel value="SELF_EMPLOYED" control={<Radio />} label="Self Employed" sx={{mr:5, '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
              <FormControlLabel value="UNEMPLOYED" control={<Radio />} label="Unemployed" sx={{mr:5, '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
            </RadioGroup>
          </Box>
          {employment==='EMPLOYED' && <Box       sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            <CustomTextField label={'Employer Name'} value={employerName} error={!!errors.employerName} helperText={errors.employerName} handleTextFieldOnChange={(eName) => setEmployerName(eName)}/>
            <CustomTextField label={'Salary'} type='number' value={borrowerSalary}  error={!!errors.borrowerSalary} helperText={errors.borrowerSalary} handleTextFieldOnChange={(bSalary) => setBorrowerSalary(bSalary)}/>
          </Box>}
          {employment==='SELF_EMPLOYED' && <Box       sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            <CustomTextField label={'Type of Business'} value={employerName}  error={!!errors.employerName} helperText={errors.employerName} handleTextFieldOnChange={(eName) => setEmployerName(eName)}/>
            <CustomTextField label={'Income Per Month'} type='number' value={borrowerSalary}  error={!!errors.borrowerSalary} helperText={errors.borrowerSalary} handleTextFieldOnChange={(bSalary) => setBorrowerSalary(bSalary)}/>
          </Box>}
      
      </Box>
      {loanType==='Vehicle Loan' && <Box  className='fields-container'>
          <h5 className='form-heading'>Vehicle Details</h5>
  
      <Box sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}>
        <CustomAutoComplete label={'Vehicle Type'} options={vehicleTypeOptions} value={vehicleType} error={!!errors.vehicleType} helperText={errors.vehicleType} handleAutoCompleteChange={(vType) => setVehicleType(vType)}/>
        <CustomTextField label={'Brand Name'} value={vehicleBrand} error={!!errors.vehicleBrand} helperText={errors.vehicleBrand} handleTextFieldOnChange={(vBrand) => setVehicleBrand(vBrand)}/>
        <CustomTextField label={'Model'}  value={vehicleModel} error={!!errors.vehicleModel} helperText={errors.vehicleModel} handleTextFieldOnChange={(vModel) => setVehicleModel(vModel)}/>
        {/* <CustomTextField label={'Insurance Date From'} type='date' value={vehicleInsuranceDate} error={!!errors.vehicleInsuranceDate} helperText={errors.vehicleInsuranceDate} handleTextFieldOnChange={(vInsuranceDate) =>{ 
          setVehicleInsuranceDate(vInsuranceDate)
          console.log(vInsuranceDate)
          }}/> */}
        <CustomTextField label={'Other Details'}  value={otherDetails}   handleTextFieldOnChange={(details) => setOtherDetails(details)}/>
      
      </Box>
          </Box>
        }
        {
          loanType==='Mortgage Loan' &&  <Box  className='fields-container'>
            <h5 className='form-heading'>Property Details</h5>
              <Box sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}>
                <CustomAutoComplete label={'Property Type'} options={propertyTypeOptions} value={propertyType} error={!!errors.propertyType} helperText={errors.propertyType} handleAutoCompleteChange={(pType) => setPropertyType(pType)}/>
                <CustomTextField label={'Property Size'} value={propertySize} error={!!errors.propertySize} helperText={errors.propertySize} handleTextFieldOnChange={(pSize) => setPropertySize(pSize)}/>
                <CustomTextField label={'Address'} value={propertyAddress} error={!!errors.propertyAddress} helperText={errors.propertyAddress} handleTextFieldOnChange={(pAddress) => setPropertyAddress(pAddress)}/>
                <CustomTextField label={'Property Description'} value={propertyDescription} error={!!errors.propertyDescription} helperText={errors.propertyDescription} handleTextFieldOnChange={(pDes) => setPropertyDescription(pDes)}/>
                <CustomTextField label={'Other Remarks'} value={otherDetails} handleTextFieldOnChange={(details) => setOtherDetails(details)}/>
              
              </Box>
              </Box>
        }
      <Box  className='fields-container'>
          <h5 className='form-heading'>File Uploads</h5>
          <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-evenly', padding: '10px 10px 10px 10px' }}>
            {
              fileUploadCards.map((fileCard, index) => (
                <InputFileUpload image={fileCard.image} label={fileCard.label} id={fileCard.id} handleFiles={handleFiles} disabled={fileCard.disabled}/>
              ))
            }
          </Box>
          {uploadedFilesArray.length > 0 && 
            <Box className='files-container'>
              <p className='file-count'>{`${uploadedFilesArray?.length} Files uploaded`}</p>
              {uploadedFilesArray.map(file => <Box className='file-card'>
                      <p>{`${file?.fileType} (${file?.fileName}) - uploaded`}</p>
                    <DeleteIcon fontSize='small' onClick={() => handleDeleteFile(file?.fileType)} sx={{cursor:'pointer', m:0}}/>
                    </Box>)}
              </Box>
          }
          
      </Box>
     
      <Button  className="submit-button" onClick={()=>handleSubmit()}>Submit</Button>
    </>
   
  )
}
