import Sidebar from '../Sidebar';
import React,{useRef} from 'react';
import Topbar from '../Topbar';
import { useState, useEffect,useCallback  } from 'react';
import clear from "../../assets/images/clear.png"
import search from "../../assets/images/search.png"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import calender from "../../assets/images/calender.png"
import {Row,Col,Input} from "reactstrap"
import jwt_decode from 'jwt-decode';
import axiosInstance from '../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import { userActions } from '../../actions/user.actions';
import PaginationTable from '../status/PaginationTable';
import Datetime from 'react-datetime';
// import "./Emis.css"
import edit from "../../assets/images/edit.png"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import download from "../../assets/images/download.png"
import plus from "../../assets/images/plus.png"
 import Allemis from './Allemi';
import repayment from '../../assets/images/repayment.png'
import {Label, FormGroup, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import copy from "../../assets/images/copy.png"
import { useLocation,useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import close from "../../assets/images/close.png"
import file_upload from '../../assets/images/file_upload.png'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import share from "../../assets/images/createmanual.png"
import CallIcon from '@mui/icons-material/Call';
import active from "../../assets/images/progress.png"
import overdue from "../../assets/images/active.png"
import DatePicker from "react-multi-date-picker"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { data } from 'jquery';
import Addcall from "./Addcall";
import sortdown from '../../assets/images/sort-down.png'
import sortup from '../../assets/images/sort-up.png'









const style = {
   
   
  justifyContent: 'center',
  width: 500,
  bgcolor: 'background.paper',
  height:'auto',
  overflowY: 'scroll',
  boxShadow: 20,
  borderRadius:5,
  marginTop:'2em',
  marginBottom:'2em'
   
  
};

const OverduetTable = ()=>{
  const state = useSelector((state) => state?.Repayments?.res);
  
  const dispatch = useDispatch();
  const [sortOrder,setSortOrder] = useState(null)
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [newtocollection, setNewtocollection] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [loanId, setloanId] = useState("");
  const [LoanId, setLoanId] = useState("");
  const [searchText, setSearchText] = useState("")
  const [selectedValue, setSelectedValue] = useState("all");
  const [isLoading, setIsLoading] = useState(false); 
  const user = sessionStorage.getItem('user');
  const [endDate,setEndDate] = useState(null)
  const [emiAmount,setEmiAmount] = useState(null)
  const [updateLoanId,setUpdateLoanId] = useState(null)
  const [emiNo,setEmiNo] = useState(null)
  const [emiId,setEmiId] = useState([])
  const [status,setStatus] = useState("")
  const [paymentMode,setMode] = useState("")
  const [mode,setPaymentMode] = useState(null)
  const [selectStatus,setSelectStatus] = useState(null)
  const [remainingAmount,setRemainingAmount] = useState(null)
  const [selectedEmiIds, setSelectedEmiIds] = useState([]);
  const [notes, setNotes] = useState(''); 
  const [remarks,setRemarks] = useState(sessionStorage.getItem("legacyLoanId"))
  const [paymentLink,setPaymentLink] = useState(null)
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
  const [customerName, setCUstomerName] = useState("")
  const [mobile, setMobile] = useState("")
  const [searchQuery, setsearchQuery] = useState("")
  const [valueRadio, setValueRadio] = React.useState('A/C Transfer');
  const [selectedDate,setSelectedDate]=React.useState(null)
  const [proof,setProof]=React.useState(null)
  const [values, setValues] = useState([
    new Date().setDate(new Date().getDate() - 4), 
    new Date().setDate(new Date().getDate() + 4)  
  ]);
  const [overdueCount, setOverdueCount] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [lumpsumAmount, setLumpsumAmount] = useState('');


  const handleClick = () => {
    if (clickCount === 2) {
      setClickCount(0); 
    } else {
      setClickCount(prevCount => prevCount + 1);
    }
  };

  const clearState = () => {
    setClickCount(0); 
  };




  useEffect(() => {
   
    axiosInstance.get('/overdues/count',{
      headers: {
        Authorization: `Bearer ${user}`,
    }

    })
        .then((res) => {
          
            setOverdueCount(res?.data?.data[0].count);
            console.log(res?.data?.data[0]?.count,"overdue count")
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}, []); 



  const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]); 
    const [paidfilterDateFrom, setPaidFilterDateFrom] = useState("");
    const [paidfilterDateTo, setPaidFilterDateTo] = useState("");
    const [duefilterDateFrom, setDueFilterDateFrom] = useState("");
    const [duefilterDateTo, setDueFilterDateTo] = useState("");
    const valid = (current) => {
      return current.isAfter(paidfilterDateFrom);
      
    };
   
    const url = `/overdues?perPage=${rowsPerPage}&pageNo=${currentPage}`
 

  
   useEffect(() => {
    if(duefilterDateFrom === "" & duefilterDateTo === "" && paidfilterDateFrom === "" && paidfilterDateTo === "" & searchText === "" && loanId==="" && sortOrder ===null){
      setIsLoading(true);
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setNewtocollection(res?.data?.data?.newToCollectionCount)
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300)
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
  
   }, []);

   const paginate = (pageNumber)=>{
    console.log("sortorder",sortOrder)

    let queryParams = {}
    if(searchQuery.startsWith("MCL")){
      queryParams.loanId =searchQuery
    }else if( searchQuery.startsWith("GBP")){
      queryParams.legacyLoanId =searchQuery
    }
    else if( searchQuery){
      queryParams.searchField =searchQuery
    }
    
    if(status){
      queryParams.callStatus = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = pageNumber
    } 
    if(sortOrder){
      queryParams.sortOrder=sortOrder
    }
    if(paymentMode){
      queryParams.bucket = paymentMode
    }

    axiosInstance.get('/overdues', {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setNewtocollection(res?.data?.data?.newToCollectionCount)
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
     
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
   }

   console.log(searchQuery,'searchQuery')

   useEffect(() => {
    fetchingData();
}, []); 

useEffect(() => {
    console.log("duefilterDateFrom && !duefilterDateTo", duefilterDateFrom && !duefilterDateTo);
    fetchingData(); 
}, [duefilterDateFrom && !duefilterDateTo]);

const fetchingData = () => {
    setIsLoading(true);
   
    setIsLoading(true);
   
    let queryParams = {}
      if(searchQuery.startsWith("MCL")){
        queryParams.loanId =searchQuery
      }else if( searchQuery.startsWith("GBP")){
        queryParams.legacyLoanId =searchQuery
      }
      else if( searchQuery){
        queryParams.searchField =searchQuery
      }
      
      if(status){
        queryParams.callStatus = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
     
      if(id){
        queryParams.instituteId = id
      }
     
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }

      if(paymentMode){
        queryParams.bucket = paymentMode
      }
    
    
  axiosInstance.get('/overdues', {
        params: queryParams,
        headers: {
            Authorization: `Bearer ${user}`,
        },
    })
    .then((res) => {
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setNewtocollection(res?.data?.data?.newToCollectionCount)
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  

        setIsLoading(false);
    })
    .catch((error) => {
        alert(error?.response?.data?.message);
        console.error("Error fetching filtered data:", error);

        setIsLoading(false);
    });
};
   
  //  const handleDateFilterApi = (duefilterDateFrom,duefilterDateTo) => {
  //   setIsLoading(true)
  
  //   if (duefilterDateFrom && !duefilterDateTo) {
  //     alert('Enter Due Date Range to Filter');
  //     setIsLoading(false);
  //     return;
  //   }
    
 
  // };

  
  const [id,setId]=useState(null)
  // const getInsituteInfo=(text)=>{
    
  //   const urlGetInstitute=`/institute/info/name?name=${text}`;
      
  //   if(text.length>=4)
  //   {
  //     axiosInstance.get(urlGetInstitute,{
  //       headers:{
  //         Authorization:`Bearer ${user}`
  //       }
  //     }).then((res) => {
  //       console.log("text",res?.data?.data[0]?.id)
  //       setId(res?.data?.data[0]?.id)
  //     }).catch((error)=>{
  //       console.log("error",error?.response?.data?.message)
  //       alert(error?.response?.data?.message)
  //     });
  
   
  //   }
 
  // }
  useEffect(() => {
    fetchData(); 
}, []); 

useEffect(() => {
    console.log("searchQuery", searchQuery);
    fetchData(); 
}, [searchQuery]);

const fetchData = () => {
    setIsLoading(true);
   
    let queryParams = {}
      if(searchQuery.startsWith("MCL")){
        queryParams.loanId =searchQuery
      }else if( searchQuery.startsWith("GBP")){
        queryParams.legacyLoanId =searchQuery
      }
      else if( searchQuery){
        queryParams.searchField =searchQuery
      }
      
      if(status){
        queryParams.callStatus = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
     
      if(id){
        queryParams.instituteId = id
      }
     
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
    

    axiosInstance.get('/overdues', {
        params: queryParams,
        headers: {
            Authorization: `Bearer ${user}`,
        },
    })
    .then((res) => {
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setNewtocollection(res?.data?.data?.newToCollectionCount)
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  

        setIsLoading(false);
    })
    .catch((error) => {
        alert(error?.response?.data?.message);
        console.error("Error fetching filtered data:", error);

        setIsLoading(false);
    });
};


//   const handleInputChange = (event) => {
//     setsearchQuery(event.target.value);
// };

// const handleModeChange = (event)=>{
//   setIsLoading(true)
//   const paymentMode = event.target.value
//   let url
//   console.log("paymentMode",paymentMode)

//   let queryParams = {}
//       if(searchQuery){
//         queryParams.searchField = searchQuery
//       }
      

//       if(duefilterDateFrom && duefilterDateTo){
//         queryParams.fromDueDate = duefilterDateFrom;
//         queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
//       }
//       if(paidfilterDateFrom && paidfilterDateTo){
//         queryParams.fromPaidDate = paidfilterDateFrom;
//         queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
//       }
//       if(id){
//         queryParams.instituteId = id
//       }
//       if(loanId){
//         queryParams.loanId = loanId
//       }
//       if(rowsPerPage && currentPage){
//         queryParams.perPage = rowsPerPage;
//         queryParams.pageNo = 1
//       }
//       if(paymentMode){
//         queryParams.bucket = paymentMode
//       }
    
      
//       axiosInstance.get('/overdues', {
//         params: queryParams,
//         headers: {
//           Authorization: `Bearer ${user}`,
//         },
//       })
//       .then((res) => {
    
//         setTableData(res?.data?.data?.data);
//         setcurrentPage(res?.data?.data?.pagination.currentPage);
//         setNewtocollection(res?.data?.data?.newToCollectionCount)
//         setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//         setrowsPerPage(res?.data?.data?.pagination.perPage);  
//         const loadingTimeout = setTimeout(() => {
//           setIsLoading(false);
//         }, 300);
//       })
//       .catch((error) => {
//         alert(error?.response?.data?.message)
//         console.error("Error fetching filtered data:", error);
//       });

// }
const [link,setLink] = useState("")
const [showModeModal, setShowModeModal] = useState(false);

const toggleModeModal = (item)=>{
  console.log("item",item)
  setShowModeModal(!showModeModal)
  setLink(item?.paymentLink)
}
const copyToClipboarded = () => {
  navigator.clipboard.writeText(link);
  alert('Payment link copied to clipboard!');
  // setTimeout(() => window.location.reload(true), 500);
};


// const handleStatusChange = (event) => {
//   setIsLoading(true)

//   const status = event.target.value
//   console.log("status",status)

//   let queryParams = {}
//   if(searchQuery){
//     queryParams.searchField = searchQuery
//   }

//   if(status){
//     queryParams.callStatus = status
//   }
//   if(duefilterDateFrom && duefilterDateTo){
//     queryParams.fromDueDate = duefilterDateFrom;
//     queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
//   }
//   if(paidfilterDateFrom && paidfilterDateTo){
//     queryParams.fromPaidDate = paidfilterDateFrom;
//     queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
//   }
//   if(id){
//     queryParams.instituteId = id
//   }
//   if(loanId){
//     queryParams.loanId = loanId
//   }
//   if(rowsPerPage && currentPage){
//     queryParams.perPage = rowsPerPage;
//     queryParams.pageNo = 1
//   }

  
//   axiosInstance.get('/overdues', {
//     params: queryParams,
//     headers: {
//       Authorization: `Bearer ${user}`,
//     },
//   })
//   .then((res) => {

//     setTableData(res?.data?.data?.data);
//     setcurrentPage(res?.data?.data?.pagination.currentPage);
//     setNewtocollection(res?.data?.data?.newToCollectionCount)
//     setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//     setrowsPerPage(res?.data?.data?.pagination.perPage);  
//     const loadingTimeout = setTimeout(() => {
//       setIsLoading(false);
//     }, 300);
//   })
//   .catch((error) => {
//     alert(error?.response?.data?.message)
//     console.error("Error fetching filtered data:", error);
//   });

// };

   function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }
 
 const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return month + ' ' + day + ', ' + year;
  }

  const [open, setOpen] = React.useState(false);

  const [modal, setModal] = useState(false);
  const handleClose = () => setOpen(false);
  const uploadState=useSelector((state)=>state?.uploadFile?.res?.data?.data)
  const [mandateEmiId,setMandateEmiId] = useState("")
  const [mandateEmiAmount,setMandateEmiAmount] = useState("")

  const [tokenId,setTokenId] = useState("")

  const [customerId,setCustomerId] = useState("")

  const [name,setName] = useState("")

  const [email,setEmail] = useState("")

  const [contact,setContact] = useState("")

  const [legacyLoanId,setLegacyLoanId] = useState("")

  const [mandateNotes,setMandateNotes] = useState("")


  const toggleMandate = (item)=>{
    setModal(!modal)
    setMandateEmiId(item?.emiId)
    setMandateEmiAmount(item?.emiAmount)
    setTokenId(item?.tokenId)
    setCustomerId(item?.customerId)
    setName(item?.userName)
    setEmail(item?.email)
    setContact(item?.mobile)
    setLegacyLoanId(item?.legacyLoanId)

    if(modal){
    setMandateEmiId("")
    setMandateEmiAmount("")
    setTokenId("")
    setCustomerId("")
    setName("")
    setEmail("")
    setContact("")
    setLegacyLoanId("")
    }
  }

  const presentMandate =()=>{
    const url = `/loan-repayment/now`;

    const data ={
      emiId: mandateEmiId,
      amount:mandateEmiAmount,
      tokenId:tokenId,
      customerId:customerId,
      name:name,
      emailId:email,
      contact:contact,
      legacyLoanId:legacyLoanId,
      notes:mandateNotes
    }
    axiosInstance.post(url, data, {
      headers: {
        Authorization: `Bearer ${user}`
      }
    }).then((res) => {
      console.log("emissss", res);
        alert(res?.data?.message);
        setTimeout(() => window.location.reload(true), 200);

    });
  }

  const toggle = (item) =>{
    if(uploadState)
    {
      setProof(uploadState?.s3Url)
    }
    console.log("clicked")
    setOpen(true)
    console.log("open",open)
    setEmiAmount(item?.emiAmount)
    setUpdateLoanId(item?.loanId)
    setEmiNo(item?.emiNo)
    setEmiId(item?.emiId)


  } ;

  // const updateEmi = () => {
  //   if (mode === null || selectStatus === null) {
  //     alert("Please select both Payment Mode and Status.");
  //     return; 
  //   }
  
  //   const amountToBePaid = remainingAmount !== null ? remainingAmount : emiAmount;

  //   if (!notes.trim()) {
  //     alert("Notes cannot be empty.");
  //     return;
  //   }
  
  //   const updateUrl = `/emis?emiId=${emiId}&paymentMode=${mode}&remainingAmountToBePaid=${amountToBePaid}&status=${selectStatus}&notes=${notes}`;
  
  //   const data = {
  //     emiId: emiId,
  //     paymentMode: mode,
  //     remainingAmountToBePaid: amountToBePaid,
  //     status: selectStatus,
  //     notes: notes

  //   };
  
  //   axiosInstance.post(updateUrl, data, {
  //     headers: {
  //       Authorization: `Bearer ${user}`
  //     }
  //   }).then((res) => {
  //     console.log("emissss", res);
  //     if (res?.data?.message === 'Successful') {
  //       alert("Updated Successfully");
  //       setTimeout(() => window.location.reload(true), 500);

  //     }
  //   });
  // };
  const [transactionId,setTransactionId]=React.useState(null)
  const [reason,setReason]=React.useState("1")
  const [adjustmentAmount,setAdjustmentAmount]=React.useState(null)
  const parsedDate = moment(selectedDate);
  const adjustedDate = parsedDate.subtract(5, 'hours').subtract(30, 'minutes');
  const adjustedFormattedDate = adjustedDate.format('YYYY-MM-DD HH:mm:ss');
  const [receivedAmount,setReceivedAmount]=React.useState(null)
  const uploadRef=useRef()

  const openProofUpload=()=>{
    uploadRef.current.click()
    
  }
  const handleProofUpload=(event)=>{
   
    const body=new FormData();
    body.append("file",event.target.files[0]);
    body.append("type",'transaction_proof');
        
     
     const urlUpload='/file/upload'
     
     dispatch(userActions.uploadFile(urlUpload,body))
     
   
   
   }
   const handleRadioChange = (event) => {
    setValueRadio(event.target.value);
  };
  const generateReceipt=()=>{
    const urlGenerateReceipt='/loan-repayment/manual-repayment'
    const body={
      loanId: updateLoanId,
      emiIds: [emiId],
      transactionId: transactionId?.trim(),
      transactionProofUrl: uploadState?.s3Url,
      repaymentId: "string",
      paymentAmount: emiAmount,
      paymentMode: valueRadio==="A/C Transfer"?3:valueRadio==="UPI"?2:1,
      paidAt: adjustedFormattedDate ,
      manualPaymentReason:reason,
      discountAmount: adjustmentAmount,
      overdueCharges: 0,
      checkBounceCharges: valueRadio==="Cheque"?0:0
    }
    dispatch(userActions.generateManualPaymentReceipt(urlGenerateReceipt,body))
  }



 
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "130px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    // document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`/excel/overdue-details `
    
    let queryParams = {}
    if(searchQuery.startsWith("MCL")){
      queryParams.loanId =searchQuery
    }else if( searchQuery.startsWith("GBP")){
      queryParams.legacyLoanId =searchQuery
    }
    else if( searchQuery){
      queryParams.searchField =searchQuery
    }
    
    if(status){
      queryParams.callStatus = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paidfilterDateFrom && paidfilterDateTo){
      queryParams.fromPaidDate = paidfilterDateFrom;
      queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
    }
    if(id){
      queryParams.instituteId = id
    }
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = 200;
      queryParams.pageNo = 1
    }
    if(paymentMode){
      queryParams.bucket = paymentMode
    }
    axiosInstance.get("/excel/overdue-details", {
      params: queryParams,
      responseType: 'arraybuffer',
      headers: {
          Authorization: `Bearer ${user}`
      }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      // document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='20px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
    }, 1000);
  })
  } 



  const [expandedRows, setExpandedRows] = useState([]);
  const [emiid,setemiId] = useState("")
  const [repaymentTable,setRepaymentTable] = useState([])
  const [isOpen, setIsOpen] = useState(Array(tableData?.length).fill(false));
  useEffect(() => {
    setIsOpen(Array(tableData?.length).fill(false));
  }, [tableData]);

  const toggleRow = (index,item) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
    
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finstaLoanId,setFinstLoanId] = useState("")
  const [reasonCode,setReasonCode]=useState("2")
  const [discountAmount, setDiscountAmount] = useState(null);
  const [selectedEMIs, setSelectedEMIs] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [SMSappChecked, setSMSappChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  const handleSMSappCheckboxChange = () => {
    setSMSappChecked(!SMSappChecked);
  };
  
  const handleWhatsappCheckboxChange = () => {
    setWhatsappChecked(!whatsappChecked);
  };

  const handleEmailCheckboxChange = () => {
    setEmailChecked(!emailChecked);
  };


  const toggleModal = (item) => {
    setIsModalOpen(!isModalOpen);
    setEmiAmount(item?.emiAmount);
    setFinstLoanId(item?.legacyLoanId)
    setLoanId(item?.loanId)
    sessionStorage.setItem("legacyLoanId",item?.legacyLoanId)

    if (!isModalOpen) {
      setTotalAmount(null);
      setReasonCode('2');
      setDiscountAmount(null);
      setRemarks(sessionStorage.getItem("legacyLoanId"));
      setSelectedEMIs([]);
      setWhatsappChecked(false)
      setEmailChecked(false)
      setPaymentLink(null)
      const url = `/emis/overdue?loanId=${item?.loanId}`;
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`
        }
      }).then((res) => {
        setRepaymentTable(res?.data?.data);
      });
    }
 

  };

  // const  togglePaymentLinkModal = ()=>{
  //   setShowPaymentLinkModal(!showPaymentLinkModal)
  // }
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
 
  const handleCheckboxChange = (emiId, emiAmount) => {
    console.log("type",typeof(emiAmount))


    let updatedSelectedEMIs;
    let updatedTotalAmount;
  
    if (selectedEMIs.includes(emiId)) {
     
      updatedSelectedEMIs = selectedEMIs.filter((id) => id !== emiId);
      updatedTotalAmount = totalAmount - emiAmount;
    } else {
      
      updatedSelectedEMIs = [...selectedEMIs, emiId];
      updatedTotalAmount = totalAmount + emiAmount;
    }
    setSelectedEMIs(updatedSelectedEMIs);
    setTotalAmount(updatedTotalAmount);
    setIsCheckboxChecked(selectedEMIs.includes(emiId));
   
  };

  
 
  const generatelink = ()=>{
    setIsLoading(true);
    const data = {
      loanId:LoanId,
      amount:lumpsumAmount?lumpsumAmount:(discountAmount ? totalAmount-discountAmount : totalAmount),
      reason:reasonCode,
      whatsappNotification: whatsappChecked ? 1 : 0,
      emailNotification: emailChecked ? 1 : 0,
      emiIds:selectedEMIs,
      discount:discountAmount ? Number(discountAmount) : 0,
      adjustment: 0,
      remarks:finstaLoanId,
    };

    console.log("datatatay",data)
    const createUrl = `/loan-repayment/payment-link`;

    axiosInstance.post(createUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      console.log("paymentlink",res?.data?.data)
      setPaymentLink(res?.data?.data)
      setShowPaymentLinkModal(true);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }).catch((error)=>{console.log(error)
    alert(error?.response?.data?.message)})
   
}



  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink);
    alert('Payment link copied to clipboard!');
    // setTimeout(() => window.location.reload(true), 500);
  };
  
  const navigate = useNavigate();

  const handleLoanProfile = (loanId,legacyLoanId)=>{
    sessionStorage.setItem("legacyLoanId",legacyLoanId)
    sessionStorage.setItem("loanid",loanId)
    const urlUsers = `/loan-repayment/profile?loanId=${sessionStorage.getItem("loanid")}`;
    const callDetailsUrl = `/call-history/details?loanId=${sessionStorage.getItem("loanid")}`;
    const urlGetReminder=`/call-history/reminder-details?loanId=${sessionStorage.getItem("loanid")}`
    const emihistoryUrl=`/emis/for-loan?loanId=${sessionStorage.getItem("loanid")}`

    dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
    dispatch(userActions.fetchLoanDetails(urlUsers, user),[])
    dispatch(userActions.getReminder(urlGetReminder))
    dispatch(userActions.getEmiHistory(emihistoryUrl,user))
  
    const newWindow = window.open("/Loanprofile", "_blank");      
  }
  const [activePage, setActivePage] = useState(15); 


  const handleChange = (number) => {
    
    setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    if(searchQuery.startsWith("MCL")){
      queryParams.loanId =searchQuery
    }else if( searchQuery.startsWith("GBP")){
      queryParams.legacyLoanId =searchQuery
    }
    else if( searchQuery){
      queryParams.searchField =searchQuery
    }
    
    if(status){
      queryParams.callStatus = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paymentMode){
      queryParams.bucket = paymentMode
    }
   
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
   axiosInstance.get('/overdues', {
  params: queryParams,
  headers: {
    Authorization: `Bearer ${user}`,
  },
})
   
    .then((res) => {

      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setNewtocollection(res?.data?.data?.newToCollectionCount)
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  
  };



const handleSortUp = () => {
  setIsLoading(true);
 setSortOrder(1)
  
    let queryParams = {}
    if(searchQuery.startsWith("MCL")){
      queryParams.loanId =searchQuery
    }else if( searchQuery.startsWith("GBP")){
      queryParams.legacyLoanId =searchQuery
    }
    else if( searchQuery){
      queryParams.searchField =searchQuery
    }
    
    if(status){
      queryParams.callStatus = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paymentMode){
      queryParams.bucket = paymentMode
    }
   
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    if(sortOrder){
      queryParams.sortOrder=1
    }
  
    
    axiosInstance.get('/overdues', {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
       
        .then((res) => {
    
          setTableData(res?.data?.data?.data);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setNewtocollection(res?.data?.data?.newToCollectionCount)
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
  
 
};
const handleSortDown = () => {
  setIsLoading(true);
  setSortOrder(-1)
   
     let queryParams = {}
     if(searchQuery.startsWith("MCL")){
      queryParams.loanId =searchQuery
    }else if( searchQuery.startsWith("GBP")){
      queryParams.legacyLoanId =searchQuery
    }
    else if( searchQuery){
      queryParams.searchField =searchQuery
    }
     
     if(status){
       queryParams.callStatus = status
     }
     if(duefilterDateFrom && duefilterDateTo){
       queryParams.fromDueDate = duefilterDateFrom;
       queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
     }
     if(paymentMode){
       queryParams.bucket = paymentMode
     }
    
     if(loanId){
       queryParams.loanId = loanId
     }
     if(rowsPerPage && currentPage){
       queryParams.perPage = rowsPerPage;
       queryParams.pageNo = 1
     }
     if(sortOrder){
       queryParams.sortOrder=-1
     }
   
     
     axiosInstance.get('/overdues', {
       params: queryParams,
       headers: {
         Authorization: `Bearer ${user}`,
       },
     })
        
         .then((res) => {
     
           setTableData(res?.data?.data?.data);
           setcurrentPage(res?.data?.data?.pagination.currentPage);
           setNewtocollection(res?.data?.data?.newToCollectionCount)
           setTotalFetchRows(res?.data?.data?.pagination.totalRows);
           setrowsPerPage(res?.data?.data?.pagination.perPage);  
           const loadingTimeout = setTimeout(() => {
             setIsLoading(false);
           }, 300);
         })
         .catch((error) => {
           alert(error?.response?.data?.message)
           console.error("Error fetching filtered data:", error);
         });
   
  
 };

 console.log(status,'status')

const handleUser = (event) => {
  console.log("event",event)

  const Id = event.userId;
  sessionStorage.setItem('repaymentsUserId',Id)
  sessionStorage.setItem('repaymentsAppId',event.applicationId)
  const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
  const ckycUrl=`/ckyc/details?userId=${Id}`;
const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
//cibil
const urlCIBIL = `/cibil/report?userId=${Id}`;
    const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
      dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])

  dispatch(userActions.getCkycDetails(ckycUrl,user));
  dispatch(userActions.getCibilDetails(urlCIBIL));
  dispatch(userActions.getPanStatus(panUrl));
  dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
  dispatch(userActions.getDigiLocker(digilockerUrl,user));
  dispatch(userActions.getProfileDetails(profileUrl));
  dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
  dispatch(userActions.getActivity(getActivityUrl,user));
  
  
    window.open(`${process.env.REACT_APP_DASHBOARD_URL}/status/disbursed/profile`,"_blank")

  
};
const clearDateFilter = () => {
  const url = `/overdues?perPage=${rowsPerPage}&pageNo=${currentPage}`
  window.location.reload()
  setsearchQuery("")
  setDueFilterDateFrom("");
  setStatus("")
  setDueFilterDateTo("");
  setMode("")
  axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res) => {

    setTableData(res?.data?.data?.data);
    setcurrentPage(res?.data?.data?.pagination.currentPage);
    setNewtocollection(res?.data?.data?.newToCollectionCount)
    setTotalFetchRows(res?.data?.data?.pagination.totalRows);
    setrowsPerPage(res?.data?.data?.pagination.perPage);  
  })
  .catch((error) => {
    alert(error?.response?.data?.message)
    console.error("Error fetching filtered data:", error);
  });
};

const [isOpen1,setIsOpen1]=useState(true)

    return(
        <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
            <Sidebar isOpenSidebar={isOpen1} handler={setIsOpen1}/>
            <div style={{width:isOpen1?'78%':'98%', overflow:"auto",marginLeft:isOpen1?"21%":'0%',}}>
                <Topbar/>
                <div >
       
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{display:"flex"}}>
          <div  style={{cursor:'pointer',borderColor:'#C4C4C4',backgroundColor:'#F1EFEF', borderRadius:'16px',display:'flex',margin: "15px 0px 0px 0px",alignItems:'center',height:'80px',width:'300px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 

                                style={{ 'white' : '#D32028',fontSize:'30px',fontWeight:'600',fontFamily:'Inter-Medium'}}>{overdueCount !== null ? overdueCount : '-'}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',}}>New To Collection</span></p>
                            <img style={{height:'50px',width:'50px' }}  src={active}/>
                        </div>
                     <div  style={{cursor:'pointer',backgroundColor:'#F1EFEF',borderColor:'#C4C4C4',borderRadius:'16px',display:'flex', margin: "15px 0px 0px 90px" ,alignItems:'center',height:'80px',width:'250px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 
                                style={{ 'white' : '#D32028',fontSize:'30px',fontWeight:'600',fontFamily:'Inter-Medium'}}>{totalFetchRows}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',}}>Overdue</span></p>
                            <img style={{height:'50px',width:'50px'}} src={overdue}/>
                        </div>
          </div>
                     
                        <div style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer'}} onClick={clearDateFilter}>
                      <img src={clear} style={{height:'18px'}}/>
                      <div style={{color:'white',display:'flex',alignItems:'center'}}>Clear Filter</div>
                    </div>
          </div>        
      
        
        <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'10px',marginBottom:'20px'}}>

            <div>
              <div style={{display:'flex',justifyContent:"space-between",marginRight:"30px"}}>
              <div style={{ height: '7px',width:'13em'}}>
            <Input
              type="text"
              placeholder="Name/Loan Id/mobile"
              onChange={(e) => {
                
                setsearchQuery(e.target.value);
              }}
              style={{
                 fontSize: '14px',
                paddingLeft: '26px',
                height: '23px',
                fontFamily: 'Inter-Medium',
                backgroundImage: `url(${search})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px',
                paddingRight: '5px', 
                borderColor:'#cccccc',
                borderStyle:'solid',
                borderRadius:"5px",
                borderWidth:'1px',
                height:'34px',
                marginLeft:"5px"
              }}
            />
            </div>

               <div style={{ height: '7px',width:'9.5em',marginLeft:"30px",}}>
              <Input type="select"  style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium'}} onChange={(e)=>{setMode(e.target.value)}}>
             <option value=""  >Buckets</option>
            <option value="s1" >s1(1-30)</option>
            <option value="s2">s2(31-60)</option>
            <option value="s3">s3(61-90)</option>
            <option value="m1">m1( 90 to 120)</option>
            <option value="m2">m2( 120 to 180)</option>
            <option value="m3">m3( 180 Above )</option>
              </Input>
              </div>
              <div style={{ height: '7px',width:'9.5em',marginLeft:"10px"}}>
            <Input type="select" id="status" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium'}} onChange={(e)=>{setStatus(e.target.value)}}>
            <option value="" >Status</option>
            <option value="1">PTP</option>
            <option value="2">RTP</option>
            <option value="3">CB</option>
            <option value="4">RNR</option>
            <option value="5">Legal</option>
            <option value="6">SW</option>
            <option value="7">Disc</option>
            

          </Input>
              </div>
              </div>
             
            </div>
            
           
            <div>
              <div style={{ height:'30px',width:'15em', borderRadius: '20px',marginRight:"60px",marginTop:'-2px'}}>
              <DatePicker
                style={{ height:'33px', BorderRadius: '5px',padding: '2px', backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px', backgroundImage: `url(${calender})`, paddingLeft: '30px',fontSize:"14px", border:'1px solid #ccc',fontWeight:"normal", border:'1px solid #ccc'}}
                placeholder="Due date range"
                allowClear={true}
                format="DD MMM YYYY"
                onChange={(dates, dateStrings) => {
                  console.log("dateStrings:", dateStrings);
                
                    
                  setDueFilterDateFrom(dateStrings?.validatedValue[0])
                  setDueFilterDateTo(dateStrings?.validatedValue[1])
              }}
                range
                />
                </div>
              </div>
          
               <div >
          <div > 
        
          <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer'}}
         id="downloadButton"  onClick={() => handleDownloadList()}  >
               
           <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
           <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
          
            </div>
            </div>
          </div>
             
            
         
            <div >
            <div > 
           
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
              onClick={() => fetchingData()}
              >
                Search Results
              </button>
              </div>
            </div>
            
         
        </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static" size="xl">
        <ModalHeader toggle={toggleModal}>Payment Link</ModalHeader>
        <ModalBody >
        <div className='tables2' >
                    <table hover  style={{width:'100%'}}>
                    <thead >
                    <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                    <th style={{ textAlign: 'left', fontFamily: 'Inter-Medium', fontSize: '0.9vw', color: '#667085', paddingLeft: '10px', borderTopLeftRadius: '8px' }}>
                      <span style={{ color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw' }}>Select</span>
                    </th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EMI No</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EMI Amount</span></th>
                    {/* <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',width:'10em' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>InstName</span></th> */}
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085' ,paddingLeft:'10px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Repymt Due On</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Delay Charges</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Ovd Days</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Penalty</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Bounce Charges</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Total Ovd</span></th>
                    </tr>
                    </thead>
                    {repaymentTable?.map((item,index) => (
                        <>
                      <tbody className='table-body1'> 
                      <tr >
                      <td style={{paddingLeft:'20px'}}>
                      <input
                        type="checkbox"
                        // checked={() => handleCheckbox(item?.emiId)}
                        onChange={() => handleCheckboxChange(item?.emiId, item?.overdueAmount)}
                        
                      />
                      </td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.emiNo}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.emiAmount}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.dueDate.substr(0, 10)}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.delayCharges}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.overdueDays}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.penalty}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.bounceCharges ? item?.bounceCharges : "-"}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.overdueAmount}</span></td>

                      </tr>
                      {index !== repaymentTable.length - 1 && (
        <tr>
          <td colSpan="12" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
               </tbody>
                  </>
                    ))}
                    
                   
                   </table>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div style={{width:'100%'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'20px'}}>LoanId</p>
                      <InputGroup>
                          <Input value={LoanId} disabled/>
                        </InputGroup>
                      </div>
                      <div style={{ width: '100%', marginLeft: '20px' }}>
                        <p style={{ lineHeight: '1em', fontFamily: 'Inter-Medium', fontSize: '0.9vw', marginTop: '20px' }}>Amount</p>
                        <InputGroup>
                        <Input 
                          value={totalAmount-discountAmount} 
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setTotalAmount(isNaN(value) ? 0 : value);
                          }} 
                        />                        
                        </InputGroup>
                      </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div style={{width:'100%'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Category</p>
                    <select onChange={(e)=>setReasonCode(e.target.value)} value={reasonCode}  style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px'}}  >
                      <option value="1">Preclosure</option>
                      <option value="2">Overdue</option>
                      <option value="3">Single EMI payment</option>
                      <option value="4">Multiple EMIs/Custom Amount</option>
                      <option value="5">Settlement</option>
                      <option value="6">Refund By Institute</option>
                    </select>
                      </div>
                      {reasonCode === "4" ? (
                      <div style={{width:'100%',marginLeft:'20px'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Custom Amount</p>
                      <InputGroup>
                          <Input value={discountAmount} onChange={(e) => setLumpsumAmount(e.target.value)}/>
                        </InputGroup>
                      </div>
                                            ):(
                      <div style={{width:'100%',marginLeft:'20px'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Discount Amount</p>
                      <InputGroup>
                          <Input value={discountAmount} onChange={(e) => setDiscountAmount(e.target.value)}/>
                        </InputGroup>
                      </div>
                      )
                      }
                     
                      
                    </div>
                    
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Remarks</p>
                    <InputGroup>
                        <Input onChange={(e)=>setRemarks(e.target.value)} defaultValue={finstaLoanId} />
                      </InputGroup>
                      
                      <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                      <FormGroup check style={{marginTop:'10px'}}>
                        <Label check >
                          SMS
                        </Label>
                        <Input type="checkbox" style={{marginTop:'12px'}} onChange={handleSMSappCheckboxChange} checked={SMSappChecked} />{' '}
                      </FormGroup>
                      <FormGroup check style={{marginTop:'10px'}}>
                        <Label check >
                          WhatsApp
                        </Label>
                        <Input type="checkbox" style={{marginTop:'12px'}} onChange={handleWhatsappCheckboxChange} checked={whatsappChecked} />{' '}
                      </FormGroup>
                      <FormGroup check style={{marginTop:'10px'}}>
                        <Label check >
                          Email
                        </Label>
                        <Input type="checkbox" style={{marginTop:'12px'}} onChange={handleEmailCheckboxChange} checked={emailChecked}/>{' '}
                      </FormGroup>
                      </div>
          
        </ModalBody>
        <div style={{display:"flex" ,justifyContent:"center"}}>
        <div style={{display:"flex" , marginBottom:"15px",marginTop:"10px",}}>
        <Button style={{backgroundColor:'#D32028',border:'none'}} onClick={generatelink} disabled={isCheckboxChecked} >
            Generate Link
          </Button>
          
          <p style={{fontFamily:'Inter-Medium',marginRight:'50px'}}> 
          <span onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'20px'}} title='copy to clipboard'/>
            </span>{" "} {paymentLink}
          </p>
          </div>
          </div>
         
        

        
        </Modal>
        <Modal isOpen={showModeModal} toggle={ toggleModeModal} backdrop="static" style={{top:'10%'}}>
        <ModalHeader toggle={ toggleModeModal}>Payment Link</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'Inter-Medium'}}> 
          <span onClick={copyToClipboarded}style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'50px',marginRight:'30px'}} title='copy to clipboard'/>
            </span> {link}
          </p>
        </ModalBody>
      </Modal>
        
          <Modal isOpen={modal}>
        <ModalHeader toggle={toggleMandate}>Mandate Presentation</ModalHeader>
        <ModalBody>
        <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em'}}>FinstLoan Id</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={legacyLoanId} onChange={(e)=>setLegacyLoanId(e.target.value)}>
          
          </Input>
        <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>EmiId</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={mandateEmiId} onChange={(e)=>setMandateEmiId(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>EMI Amount</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={mandateEmiAmount} onChange={(e)=>setMandateEmiAmount(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Token Id</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={tokenId} onChange={(e)=>setTokenId(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Customer Id</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={customerId} onChange={(e)=>setCustomerId(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Name</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={name} onChange={(e)=>setName(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Email</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={email} onChange={(e)=>setEmail(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Contact</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={contact} onChange={(e)=>setContact(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Notes(Razorpay description)</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} value={mandateNotes} onChange={(e)=>setMandateNotes(e.target.value)}>
          
          </Input>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <button style={{paddingLeft:'10px',fontFamily:'Inter-medium',color:'white',backgroundColor:'#D32028',paddingRight:'10px',borderRadius:'10px'}} onClick={()=>presentMandate()}>Submit</button>
          </div>
        </ModalBody>
        
      </Modal>
        <Modal
        isOpen={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display:'flex',justifyContent:'center'}}
      >
       
          <Typography style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',backgroundColor:'#F0F0F0' ,borderBottom:'1px solid #D0D0D0'}}      >
           <p style={{ textAlign:'center',fontSize:'20px',paddingTop:'10px',fontWeight:'bold'}}> Create Manual Payment <img src={close} style={{marginLeft:'35%',cursor:'pointer'}} onClick={() => {setOpen(!open)}}/></p>
          
          </Typography>
         <div style={{padding:'1em'}}>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Total EMI Amount</p>
          <input disabled value={emiAmount} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          <div style={{display:'block' ,marginTop:'15px'}}>
          <p style={{fontWeight:'bold'}}>Category</p>
          <select onChange={(e)=>setReason(e.target.value)}   style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}}  >
            <option value="1">Preclosure</option>
            <option value="2">Overdue</option>
            <option value="3">Single EMI payment</option>
            <option value="4">Multiple EMIs</option>
            <option value="5">Settlement</option>
            <option value="6">Refund By Institute</option>
          </select>
        
          </div>
          <div style={{display:'block',marginTop:'1em',marginBottom:'1em'}}>
           
        
          <RadioGroup  
          style={{display:'flex',flexDirection: 'row',justifyContent: 'space-between',}}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueRadio}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="A/C Transfer" control={<Radio />} label="A/C Transfer" />
              <FormControlLabel value="Cheque" control={<Radio />} label="Cheque" />
              <FormControlLabel value="UPI" control={<Radio />} label="UPI" />
           </RadioGroup>
           {valueRadio==="Cheque"?<div style={{ marginTop:'10px',display:'block'}}>
          <p style={{fontWeight:'bold'}}>Cheque Bounce Charges</p>
          <input disabled  style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>:null}
            <div style={{marginTop:'1em'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                            'DesktopDateTimePicker',
                            ]}
                        >
                            <DemoItem label="Paid At ( Date and Time )" >
                            <DesktopDateTimePicker value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)}/>
                            </DemoItem>
                        </DemoContainer>
          </LocalizationProvider>
          </div>
        
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Transaction Id / Cheque No.</p>
          <input  onChange={(e)=>setTransactionId(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="text"/>
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Upload Proof</p>
          <img onClick={()=>openProofUpload()}style={{cursor:'pointer',width:'100%'}} src={file_upload}/>
          <input ref={uploadRef} onChange={(e)=>handleProofUpload(e)}  type="file" />
          <p style={{textAlign:'center',backgroundColor:'#FFCCCB',boxShadow:'0px 0px 2px 2px #F0F0F0'}}>{proof?<u>{proof.name}</u>:""}</p>
          </div>
          <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Received Amount</p>
          <input  onChange={(e)=>setReceivedAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Adjustment Amount</p>
          <input  onChange={(e)=>setAdjustmentAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          
        <div style={{display:'flex', justifyContent: 'center',backgroundColor:'#FFF2EA',marginTop:'1em'}}>
          <div>
            <p style={{fontFamily:'Outfit',color:'#d32028',textAlign:'center'}}>Adjustment Amount</p>
            <p style={{fontFamily:'Outfit',color:'#0D9857',textAlign:'center'}}>  {adjustmentAmount?`+ ${adjustmentAmount}`:null}</p>
            <p style={{fontSize:'15px',fontFamily:'Outfit', textAlign:'center'}}>( Will be adjusted in the next EMI )</p>

            <Button onClick={()=>generateReceipt()} style={{width:'100%',borderRadius:'1em',backgroundColor:'#D32028',color:'white',marginBottom:'1em'}}>Submit</Button>
          </div>
          
        </div>
        </div>
        
      </Modal>
              
                {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ):(
                  <>
                  <div className='tables' style={{marginTop:'20px'}}>
                      <table hover  style={{width:'100%'}}>
                      <thead className='table-heading'>
                          <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',paddingLeft:'5px',width:'7em',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Mobile</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'9em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>CustName</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'9em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>{ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Product Type': 'Inst Name'}</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'6em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>LoanAmt</span></th> 
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'5em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>EMI No.</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'6em'  }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>EMI Amt.</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'5em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Penalty</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'6em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>B.Chrgs</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'6em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Ovd.Amt</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'4em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Dpd</span></th>
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'7em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}><div  style={{ cursor: 'pointer',display:'flex' }}>Due Date
                          {sortOrder === -1 ?   <img
          src={sortup} 
          alt="Upward Image"
          onClick={handleSortUp}
          style={{ display: 'block', maxWidth: '13px', maxHeight: '13px', cursor: 'pointer',margin:"10px"}} 
        /> : <img
        src={sortdown}   
        alt="Downward Image"
        onClick={handleSortDown}
        style={{ display: 'block', maxWidth: '13px', maxHeight: '13px', cursor: 'pointer',margin:"10px" }}
      />}
     
      </div>
    </span></th>  
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'5em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Pmnt.link</span></th>   
                          <th title='Call History' style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'4em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}><CallIcon/></span></th> 
                          <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085' ,width:'9em',borderTopRightRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap',marginLeft:"5px"}}>Actions</span></th>

                          </tr>
                      </thead>
                      <tbody className='table-body1'> 
                          {tableData?.map((item,index) =>
                            (
                              <>
                          <tr className='table-row' key={index} style={{margin:'40px'}}>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',paddingLeft:'5px',maxWidth:'8em',cursor:'pointer' }}><span onClick={()=>handleLoanProfile(item?.loanId,item.legacyLoanId)} style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#101828',fontWeight:'500',}}>{item?.mobile}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'9em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#101828',fontWeight:'500',display:'block',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',lineHeight:'1.5em',cursor:'pointer'}} onClick={() => handleUser(item)}>{item?.userName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'9em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500',display:'block',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',lineHeight:'1.5em'}}>{ item?.legacyLoanId.includes("ISF") ? "InsurFin" : item?.instituteName}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'6em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.loanAmount? (<>₹{formatNumberWithCommas(item.loanAmount)}</>):'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'5em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.emiPaidCount?item.emiPaidCount:'-'}/{item.tenure?item.tenure:'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'4em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.emiAmount? (<>₹{formatNumberWithCommas(item.emiAmount)}</>):'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'4em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.penalty?(<>₹{formatNumberWithCommas(item.penalty)}</>):'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'4em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.bounceCharges?(<>₹{formatNumberWithCommas(item.bounceCharges)}</>):'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw' ,maxWidth:'6em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.overdueAmount?( <>₹{formatNumberWithCommas(item.overdueAmount)}</>) : ( '-')}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'4em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.dpd?item.dpd:'-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'8em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.nextEmiPaymentDate? formatDate(item.nextEmiPaymentDate) : '-'}</span></td>
                              <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'4em',cursor:'pointer' }}><div style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}} onClick={()=>toggleModeModal(item)}> <img src={copy} style={{height:'20px',width:'20px'}} title='copy to clipboard'/></div></td>
                              <td style={{ maxWidth: '9em',color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',cursor:'pointer'}} >
                              
                            <Addcall item={item}/> 
                          </td>
                          
                
                          <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',maxWidth:'12em' }}>
                            <span style={{fontFamily:'Inter',fontSize:'0.9vw',}}><a title={item?.mobile} href={`https://wa.me/+91${item.mobile}`} target="_blank" rel="noreferrer"><WhatsAppIcon color="action" style={{cursor:'pointer' }}/></a></span>
                            
                            <img style={{height:'22px',width:'21px',marginLeft:'4px',cursor:'pointer'}} src={repayment} onClick={()=>toggleModal(item)}/>
                            <img style={{height:'20px',width:'20px',marginLeft:'4px',cursor:'pointer',borderColor:'gray',borderWidth:'2px',borderStyle:'solid',borderRadius:'50%',padding:'4px'}} src={plus} onClick={() => toggleRow(index,item)}/> 
                            <img title='Mandate Presentation' style={{height:'25px',width:'20px',marginLeft:'4px',cursor:'pointer'}} src={share} onClick={()=>toggleMandate(item)}/> 
                            </td>

                          </tr>
                          {isOpen[index] && (
                          <tr>
                            <td colSpan="10">
                              <Allemis item={item} />
                            </td>
                          </tr>
                        )}
               

             
                   {index !== tableData.length - 1 && (
        <tr>
          <td colSpan="15" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
                        </>
                          ))}
                          
                      </tbody>
                      </table>
                  </div>
                  
                  {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'700'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handleChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handleChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handleChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handleChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'700'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handleChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handleChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handleChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handleChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
              <PaginationTable
                startPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalRows={totalFetchRows}
                handleChange={handleChange}
                paginate={paginate}
                searchText={searchText}
                duefilterDateFrom={duefilterDateFrom}
              />
                  </>
                )}
            
               
                </div>
            </div>
        </div>
    )
}
export default OverduetTable;