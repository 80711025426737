/* eslint-disable no-useless-escape */
import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie'
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import axiosInstance from '../../helpers/axios'
import Group_1 from '../../assets/images/Group_1.png'
import FeeMonk_HighRes_Logo from '../../assets/images/FeeMonk_HighRes_Logo.png'
import './index.css'
import {userActions} from '../../actions/user.actions'
import BijayLogo from '../../assets/images/bijayfinance_logo.png'
// import {useUserAuth} from "../../AuthContext"
let logo = FeeMonk_HighRes_Logo
const domainName = window.location.href.split('.').slice(-2, -1)[0]
// const domainName = 'http://localhost.credityantra.com'.split('.').slice(-2, -1)[0]
if(domainName==='credityantra'){
    logo= BijayLogo
    document.title = 'BIJAY LMS'
}

export default function Login() {

//   const {loggedIn} = useUserAuth();
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state?.authenticationOtp);
   


  const [email,setEmail]=useState('')
  const [otp,setOtp]=useState('')
  const [emailError,setEmailError]=useState('')
  const [otpError,setOtpError]=useState('')
 
  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };
  
  const validate = () => {
    
    
     if (otp.length !==4) {
      setOtpError("Otp must be at least 4 Digit long");
    }else{
      setOtpError("");
      
    }
    
    if (otpError) {
      setOtpError(otpError)
      return false;
    }
    
    return true;
  };
  
  
  const [timer,setTimer]=useState(45)
  
  
React.useEffect(() => {
 
  if(sessionStorage.getItem("user"))
  {

    navigate('/dashboard')
    window.location.reload()
  }
  let timerInterval= setInterval(() => {
        if(timer>0)
        setTimer(old=>old-1)
      if(timer===0)
        clearInterval(timerInterval)
        
      }, 1000);

    return()=>{
      clearInterval(timerInterval)
    }

}, [loginState,timer])


    
 
  


  
 const  onChangeEmail = event => {
    const email = event.target.value;
    const isValid = validateEmail(email);
    
    setEmail(email)
    setEmailError(!isValid ? "Invalid email address" : null)
   
  };
  
  

 const  onChangeOtp = event => {
    setOtp(event.target.value)
    if(event.target.value.length>=8)
    setOtpError("")
    
  }

  const [emailVerify,setEmailVerify]=useState(false)
  const triggerOtp=(e)=>{
    setTimer(45)
    e.preventDefault()
    const urlTriggerOtp='/auth/trigger-otp'
    const body={
      emailId:email
    }
    axiosInstance.post(urlTriggerOtp,body)
    .then((res)=>{
      console.log("login",res)
      if(res?.data?.message === "Successful"){
        setEmailVerify(true)
      }else{
        alert(res?.data?.message)
      }
      
    })
    .catch((err)=>{
      alert(err?.response?.data?.message)
    })
  }
  
 const  submitForm = async event => {
    event.preventDefault()
    
   
    // const userDetails = {emailId,otp}

    const isValid =validate();
    if (isValid) { 
  
        dispatch(userActions.loginOtp(email,otp))

  }
  }






    
    // const jwtToken = Cookies.get('jwt_token')
    // if (jwtToken !== undefined) {
    //   return <Navigate to="/" />
    // }
  
    return (
      <>
         <div className="login-form-container">
        <div className="login-background">
          {/* <div className="login-background-2"> */}
          <img src={Group_1} className="login-image" alt="login" />

          <img
            src={logo}
            className="feemonk-image"
            alt="website logo"
            
          />

         {domainName==='credityantra' ? <p className="caption">Non-Banking Finance Company (NBFC) registered with Reserve Bank of India.</p>: 
         <p className="caption">Simplified education fee payments & fiscal management</p>}
        {/* </div> */}
        </div>
        <form className="form-container" >

          <p style={{margin:'0'}}>To get started</p>
          <h3 style={{color:'#D32028', fontFamily:"sans-serif", fontWeight:'bold'}}>Log In</h3>
         
          <div className="input-container">
            <label className="input-label" htmlFor="email">
          Email
        </label>
        <input
          type="text"
          id="email"
          className="email-input-filed"
          value={email}
          onChange={(e)=>onChangeEmail(e)}
        />
        <div style={{display:'flex',flexDirection: 'row-reverse',}}>
       {emailVerify?<small style={{float:'right',color:'blue',cursor:'pointer'}} onClick={()=>{setEmailVerify(false)
      setTimer(45)}}>Edit</small>:null}
       </div>
        {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
        
     </div>
          {/* <div className="input-container">

        <label className="input-label" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          id="password"
          className="password-input-filed"
          value={password}
          onChange={(e)=>onChangePassword(e)}
        />
          {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
        
      </div> */}
         {emailVerify? <div className="input-container">

        <label className="input-label" htmlFor="otp">
          OTP
        </label>
        <input
          type="number"
          id="otp"
          onWheel={(e) => {
              e.target.blur()
              e.stopPropagation()
              setTimeout(() => {
              e.target.focus()
           }, 0)}}
          className="password-input-field"
          value={otp}
          onChange={(e)=>onChangeOtp(e)}
        />
          {otpError && <div style={{ color: 'red' }}>{otpError}</div>}
        <div style={{display:'flex',flexDirection: 'row-reverse',}}>
          {<small onClick={timer?null:(e)=>triggerOtp(e)} style={{color:timer?"grey":"blue",cursor:'pointer'}}>Resend {timer?timer:null}</small>}
        </div>
      </div>:null}
          <div className="buttons-container1">
              {/* onClick={()=>navigate('/forgotPassword')} */}
           <p  style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}}><span style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}} onClick={()=>navigate('/forgotPassword')}>Forgot Password ?</span></p>
          {!emailVerify?<button  type="submit" className="login-button" onClick={(e)=>triggerOtp(e)}>
            Get OTP
          </button>
          :
          <button  type="submit" className="login-button" onClick={(e)=>submitForm(e)}>
            Submit
          </button>
          }

          {/* <div style ={{display:'flex', flexDirection : 'row', alignContent: 'center'}}><p style={{marginTop : '20px' , marginRight : '10px'}}>Don't have an account? </p><SignUpForm /> </div> */}
         </div>
        
        </form>   
      </div>
      </>
    )
  
}


