import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import Sidebar from '../../Sidebar';
import Topbar from '../../Topbar';
import search from "../../../assets/images/search.png"
import { useState, useEffect } from 'react';
import Datetime from 'react-datetime';
import selectdates from "../../../assets/images/selectdates.png"
import 'react-datetime/css/react-datetime.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'bootstrap/dist/css/bootstrap.css';
import jwt_decode from 'jwt-decode';
import statusimage from "../../../assets/images/status.png";
import rightarrow from "../../../assets/images/chevron-right.png";
import { useLocation,useNavigate } from 'react-router-dom';
import EditForm from '../EditForm/EditForm';
import MoveStatus from '../MoveStatus/MoveStatus';
import ResendConsent from '../ResendConsent/ResendConsent';
//import RequestBankStatement from './RequestBankStatement';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import Sort from "../../../assets/images/sort.png";
import download from "../../../assets/images/download.png";
import PaginationTable from '../PaginationTable';
//import '../../assets/scss/_variables.scss';
import './ProjectTable.css'
import axiosInstance from '../../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/en-gb');

const ProjectTables = () => {

  const insubstate = useSelector((state) => state?.Insubmission?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
  const downloadstate = useSelector((state) => state.fetchDownload?.res);
 const userstate = useSelector((state) => state.Handleuser?.res)
 const usersearchdefstate = useSelector((state) => state?.UserSearchDefault?.res?.data?.data);
  
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)
  const [activePage, setActivePage] = useState(15); 
 


  const [userDetails, setUserDetails] = useState(null)

  const dispatch = useDispatch();

  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.InSubmission);
  const localStatus = sessionStorage.setItem("status",status)

  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [isLoading, setIsLoading] = useState(true); 
  const [clicked,setClicked] = useState(false)

  const valid = (current) => {
    return current.isAfter(filterDateFrom);
    
  };

  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  //pagination
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [searchText, setSearchText] = useState("")

 //sorting
  const [sort,setSort]=useState(-1)
 

  const[toggleSort,setToggleSort]=useState(false);
  const [cra, newCra] = useState([]);
  const [selectedCRA, setSelectedCRA] = useState('');
 

  const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
        const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`
    
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.applicantDetails);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
    
  
  useEffect(() => {
    if(usersearchdefstate){
      setIsLoading(false);
      setUserDetails(usersearchdefstate?.applicantDetails)
        setTableData(usersearchdefstate?.applicantDetails);
        setFilterTableData(usersearchdefstate?.applicantDetails);
        setcurrentPage(usersearchdefstate?.pagination?.currentPage);
        setTotalFetchRows(usersearchdefstate?.pagination?.totalRows);
        setrowsPerPage(usersearchdefstate?.pagination?.perPage);
    }else{
      if(toggleSort)
      {
        setSort(1)
      } 
      else {
        setSort(-1)
       
      } 
  
      
      if(searchText === "" && filterDateFrom === '' && filterDateTo ==='' && selectedCRA===""){
        
        
        const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.applicantDetails);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
        
       
  
      }    
    }
    
    const userUrl = `/admin/users`;
    axiosInstance.get(userUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res)=>{
        newCra(res?.data?.data)
    })

  }, [toggleSort, insubstate,datestate,usersearchdefstate,selectedCRA]);
  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      }).catch((error)=>{
        alert(error?.response?.data?.message)
      })
  
   
    }
 
  }

  
  const handlepageChange = (number) => {


    setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

};







  const handleInputChange = (event) => {

    setSearchText(event.target.value)
    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
    })
    .catch((error)=>console.log(error))

};

const handleDateFilterApi = (startDate, endDate) => {
  setClicked(!clicked)
  setFilterDateFrom(startDate)
  setFilterDateTo(endDate)
  let queryParams = {}
    
  if(filterDateFrom && filterDateTo){
    queryParams.fromDueDate = startDate;
    queryParams.toDueDate = endDate+" "+"23:59:59"
  }
  
  if(id){
    queryParams.instituteId = id
  }
 
  if(status){
    queryParams.status = status
  }

  if(selectedCRA){
    queryParams.cra = selectedCRA
  }
  if(rowsPerPage && currentPage){
    queryParams.perPage = rowsPerPage;
    queryParams.pageNo = 1
  }
  axiosInstance.get('/admin/application',{
    params:queryParams,
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res)=>{    
    setTableData(res?.data?.data?.applicantDetails);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);
  })
  .catch((error)=>console.log(error))
};



  const navigate = useNavigate();

  const handleUser = (event) => {
    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Submission`;

    dispatch(userActions.fetchHandleuser(urlProfile, user),[])
     const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Submission`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    
    //  dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    // dispatch(userActions.getPanStatus(panUrl));
    // dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    // dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/submission/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Submission" },
    });
   
  };

 


  const handleCoApp = (event) => {
    
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Submission`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user),[])
     const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=In Submission`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   
    const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    //  dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    // dispatch(userActions.getPanStatus(panUrl));
    // dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    // dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/submission/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Submission" },
    });

     
   
  };


   //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("coappId",currCoAppId)
   sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/disbursed/appProfile")
   }
     
  //changepage
  const paginate = (pageNumber) => {
   
    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){
  const urlUsers = `/admin/application?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText === "" && filterDateFrom !== '' && filterDateTo !=='' && clicked ===  true){
      const urlUsers = `/admin/application?status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !=='' && clicked ===  true){
      const urlUsers = `/admin/application?instituteId=${id}&status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else{
      const urlUsers = `/admin/application?status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.applicantDetails);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
   


};
   


  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,
        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59"
       
    }
    axiosInstance.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    }, 1000);
  })
  }  
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

  const handleChange = (event) =>{
    setSelectedCRA(event.target.value)
    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }

    if(status){
      queryParams.status = status
    }

    if(event.target.value){
      queryParams.cra = event.target.value
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.applicantDetails);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
    })
    .catch((error)=>console.log(error))

   
  }

  const [isOpen,setIsOpen]=useState(true)
  
  if (tableData) {
   
    return (
      <Card className='card' style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
          <Topbar/>
        
        <CardBody className='card-body'> 
       
         <div>
          <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>{status}</span></p>
          
         </div>
         


        <Row>
          <div style={{ display: 'flex', flex: 'wrap'}}>
          <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? 'Product Type': 'Institute Name'
          } onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
               
              >
                Search Results
              </button>
              </div>
            </Col>
            <Col>
            <div>
          <Input type="select" name="select" id="exampleSelect" value={selectedCRA} onChange={(event)=>handleChange(event)}>
          <option value="">Select CRA</option> 
            {cra?.map((item,index)=>(
              <option>{item.name}</option>
            ))}
          </Input>
          </div>
            </Col>
            <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"150px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>

         {isLoading ? (
          <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
          <CircularProgress />
        </Box>
         ):(
          <>
<div className='tables' style={{ marginTop: '20px' }}>
            
            <table >
              <thead className='table-heading' >
                <tr >
                  <th  style={{  fontSize: '0.9vw',cursor:'pointer',width:'15%',fontFamily:"Inter",borderTopLeftRadius:'8px' }} id="appId">
                  <span style={{marginLeft:'10px'}}></span> Appl. Id 
                  {/* <img style={{cursor:'pointer',width:'14px',marginLeft:'5px,fontFamily:"Inter"'}} src={Sort} alt="sort"/> */}
                  </th>
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'20%' }} id="appname">
                  <span style={{marginLeft:'10px'}}></span> Appl. Name
                  </th>
                  {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                   <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'15%' }} id="appname">
                   <span style={{marginLeft:'10px'}}></span> Student Name
                   </th>:null
                  }
                 {
                  decode?.clientId === '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter" ,width:'20%'}} id="insName">
                <span style={{marginLeft:'10px'}}>Loan Type</span> 
                  </th>:
                 
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter" ,width:'20%'}} id="insName">
                <span style={{marginLeft:'10px'}}>Institute Name</span> 
                  </th>}
                  {
                  decode?.clientId !== '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' &&
                  <th  style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'15%'}} id="coursename">
                  <span style={{marginLeft:'10px'}}>Course</span>    
                  </th>
                  }
                  <th  style={{  fontSize: '0.9vw',width:'15%',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                   <span style={{marginLeft:'10px'}}></span>  Date
                   {/* <img style={{cursor:'pointer',width:'14px',marginLeft:'2px',fontFamily:"Inter"}} src={Sort} alt="sort"/> */}
                  </th>
                  <th  style={{  fontSize: '0.9vw' ,textAlign:'center',fontFamily:"Inter",width:'15%'}} id="cra">
                    CRA
                  </th>
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'8rem'}} id="amt">
                  <span style={{marginLeft:'10px'}}></span>   Amount
                  </th>
                 { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"?null:<th style={{  fontSize: '0.9vw',fontFamily:"Inter", }} id="actions" className="thead-text">
                  <span style={{marginLeft:'10px'}}></span>  Actions
                  </th>}
                </tr>
              </thead>
              {tableData.map((item,index) => {
               
                  return (
                    <tbody className='table-body' key={item.applicationId}>
                      <tr className="table-row" style={{ lineHeight: '20px' }}
                      >
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td>
                         <span onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter', width: '8rem' ,color:'#101828'}}> {item.applicationId}</span>
                        </td>:<td style={{  fontSize: '0.9vw', fontWeight: '400', width: '10rem',fontFamily:"Inter" }}>
                          {item.applicationId}
                        </td>
                        }
                         {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                             fontSize: '0.9vw',
                            fontFamily:"Inter",
                            width: '15rem',fontWeight:"500",color:'#101828'
                          }}
                         
                        >
                           <span title={item.name} style={{fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{ fontSize: '0.9vw',
                            fontFamily:"Inter",fontWeight:"500",color:'#101828',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                             <div className="forInstituteDetails">
                                <span>{item.coapplicantEmail}</span>
                                <span>{item.coapplicantMobile}</span>
                                </div>

                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                        
                        >
                          <span title={item.name} style={{ width: '15rem',fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer'}} onClick={() => handleUser(item)}> {item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{ width: '15rem',fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer',marginTop:'0.5rem'}} onClick={() => handleCoApp(item)}>
                             {' '}
                             {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>}
                        {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                         <span style={{width: '10rem', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                        </td>:null}
                        
                        <td title={item.instituteName} >
                         <span style={{width: '10rem', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.instituteName ? item.instituteName : "-"}</span>
                        </td>
                        { decode?.clientId !== '98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' &&
                          <td title={item.courseName} >
                          <span style={{width: '10rem', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.courseName ? item.courseName : "-"}</span>
                        </td>
                        }
                        
                        <td
                          title={item.applicationDate?.substr(10, 14)}
                          
                        >
                        <span style={{ width: '11rem', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter" }}>{item.applicationDate?.substr(0, 10) ? moment(item.applicationDate?.substr(0, 10)).format('DD MMM YY') : "-" }</span>  
                        </td>
                        <td title={item.CRA} style={{  fontSize: '0.9vw', fontWeight: '500',textAlign:'center', width: 'auto' ,fontFamily:"Inter", color: '#667085'}}>
                          {item.CRA?item.CRA[0]+item.CRA?.toUpperCase().substr(item.CRA?.indexOf(" "),item.CRA?.indexOf("")+2):null}
                        </td>
                        <td style={{marginLeft:'15px', fontSize: '0.9vw', width: '8rem',fontFamily:"Inter", color: '#667085'}}>
                         {item.courseFees ? ( <>&#8377;{formatNumberWithCommas(item.courseFees)}</>) : ( '-')}
                        </td>
                       { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"?null:
                       <td >
                       <div style={{display:'flex',flexDirection: 'row',justifyContent:'space-between'}}>
                         <span title="Edit Form" style={{cursor:'pointer',marginRight:'10px'}}><EditForm curruser={item} /></span> 

                         <span title="Move Status" style={{cursor:'pointer',marginRight:'10px'}}><MoveStatus ApplicantId={item} /></span> 
                        
                         <span title="Resend Consent" style={{cursor:'pointer',paddingRight:'10px'}}> <ResendConsent userInfo={item}/></span> 
                      </div>
                        
                      </td>
                      }
                      </tr>
                      
                    
                    </tbody>
                  );
              })}
            </table>
            </div>
         
            {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ): (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              handlepageChange={handlepageChange}
              searchText={searchText}
              filterDateFrom={filterDateFrom}
            />
          </>
         )}
          
          
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default ProjectTables;
