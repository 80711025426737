import axiosInstance from '../helpers/axios'
import { useNavigate } from 'react-router-dom';

export const userService = {
  
    login,
    loginOtp,
    logout,
    sendOtp,
    fetchInprogressUsers,
    fetchInsubmissionUsers,
    fetchIndisbursedUsers,
    fetchInpendingdisbursment,
    fetchInsanction,
    fetchInrejected,
    fetchInreview,
    fetchDropped,
    fetchReadytodisburse,
    fetchOnholdusers,
    fetchDatefilter,
    fetchHandleuser,
    fetchHandlcoappuser,
    fetchEditformdetails,
    fetchDisbursedstatuschange,
    fetchDroppedstatuschange,
    fetchUpdateStatusUrl,
    fetchReviewstatuschange,
    updateEditformdetails,
    handleApproveFormdetails,
    fetchhandleApproveapi,
    fetchColenderFundSource,
    fetchfundcode,
    fetchUrlapproveapifund,
    getAadharfront,
    getAadharback,
    getPandetails,
    addCoApplicant,
    fetchUrlforbankselfie,
    fetchUrlforApplicationdetails,
    fetchEmiUrl,
    getBankSelfieDetails,
    getPanStatus,
    getCkycDetails,
    getDigiLocker,
    getProfileDetails,
    getUploadedFiles,
    getCibilDetails,
    getActivity,
    submitActivity,
    getPanProStatus,
    fetchLoans,
    fetchRepayments,
    fetchSoftPullDetails,

    postIncomePdf,
    getIncomeProfileAnalytics,
    postFoirDetails,
    getFoirDetails,
    getInstituteInfo,
    createProduct,
    getProductList,
    updatingBankDetails,
    updateBankdetails,
    updateInstitutedetails,
    callBankdetails,callbankdetails,
    callRegdetails,
    createInstitute,
    userSearch,
    userSearchdefault,
    userSearchInst,
    fetchLoanDetails,
    fetchLoansCount,

    fetchCallDetails,
    generateManualPaymentReceipt,
    createReminder,
    getNotifications,
    getEmiHistory,
    uploadFile,
    getReminder,
    reportdetails,

    fetchInstitutes,
    createApplication,
    deleteReminder,
    fetchProducts,
    fetchfiltereddata,

    getCustomerDashboard


  };


async function login(emailId, password) {
  
    const payLoad = {
        emailId : emailId,
        password : password
    }
    
    const user =  axiosInstance.post(`/auth/verify`,payLoad)
    .then((res) => {
        sessionStorage.setItem("user", res?.data?.data);
        return res?.data?.data
    })
    .catch((err) => {
      alert("Invalid Username or password")
        console.log("Err", err)
        return err
    });
    

    return user;
}
async function sendOtp(mobile) {
  
    const payLoad = {
        mobile:mobile
    }
    
    const user =  axiosInstance.post(`/auth/send-otp`,payLoad)
    .then((res) => {
        alert("OTP is sent to "+ mobile)
    })
    .catch((err) => {
      alert("Check Mobile Number / Server Down")
        console.log("Err", err)
        return err
    });
    

    return user;
}
async function loginOtp(email, otp) {
  
    const payLoad = {
        emailId : email,
        otp : otp
    }
    
    const user =  axiosInstance.post(`/auth/verify-email-login`,payLoad)
    .then((res) => {
        sessionStorage.setItem("user", res?.data?.data);
        return res?.data?.data
    })
    .catch((err) => {
      alert("Invalid Username or otp")
        console.log("Err", err)
        return err
    });
    

    return user;
}


  function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem("user");

  }


  async function fetchInprogressUsers(url, user) {
  
    
    
    const response =await axiosInstance.get(url,{
      headers : {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
        
      }
    })
    .then((res) => {
        return res?.data?.data
    })
    .catch((err) => {
        console.log("Err", err)
        return err
    });
    

    return response;
}

async function fetchInsubmissionUsers(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchIndisbursedUsers(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchInpendingdisbursment(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchInsanction(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchInrejected(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchInreview(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}
  
async function fetchDropped(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}
  

async function fetchReadytodisburse(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchOnholdusers(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchDatefilter(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      
      return res?.data?.data
  })
  .catch((err) => {
  //  alert(err?.response?.data?.message)
   setTimeout(() => window.location.reload(true), 100);
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchHandleuser(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchHandlcoappuser(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
      
      return res?.data?.data
  })
  .catch((err) => {
    // alert(err?.response?.data?.message)
      console.log("Err", err)
      return err
  });
  

  return response;
}



async function fetchEditformdetails(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res) => {
    console.log("result of editform",res?.data?.data)
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchDisbursedstatuschange(url,data, user) {
  
    
    
  const response =  axiosInstance.post(url,data,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {

    
      return res?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchDroppedstatuschange(url,data, user) {
  
    
    
  const response =  axiosInstance.post(url,data,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
    
    
      return res?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchUpdateStatusUrl(url,data, user) {
  
    
    
  const response =  axiosInstance.post(url,data,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
    console.log("status resultnnnnnn",res)
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}
async function fetchReviewstatuschange(url,data, user) {
  
    
    
  const response =  axiosInstance.post(url,data,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
      return res?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function updateEditformdetails(url,data, user) {
  
    
    
  const response =  axiosInstance.post(url,data,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
    console.log("response of updated edit form",res?.data)
      return res?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function handleApproveFormdetails(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
    
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchhandleApproveapi(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
    console.log("result of handle approve api ",res?.data?.data)
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchColenderFundSource(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchfundcode(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchUrlapproveapifund(url, user) {
  
    
    
  const response =  axiosInstance.get(url,{
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function getAadharfront(url,data) {
  
    
    
  const response =  axiosInstance.post(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function getAadharback(url,data) {
  
    
    
  const response =  axiosInstance.post(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}
async function getPandetails(url,data) {
  
    
    
  const response =  axiosInstance.post(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function addCoApplicant(url,data,user) {
  
    
    
  const response =  axiosInstance.post(url,data,user,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}

async function fetchUrlforbankselfie(url,user) {
  
    
    
  const response =  axiosInstance.get(url,{
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      // alert(err?.response?.data?.message)
      return err
  });
  

  return response;
}
async function fetchUrlforApplicationdetails(url,user) {
  
    
    
  const response =  axiosInstance.get(url,{
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
    // alert(err?.response?.data?.message)
      console.log("Err", err)
      return err
  });
  

  return response;
}
async function fetchEmiUrl(url,user) {
  
    
    
  const response =  axiosInstance.get(url,{
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
  .then((res) => {
      return res?.data?.data
  })
  .catch((err) => {
      console.log("Err", err)
      return err
  });
  

  return response;
}
 async function getCkycDetails(url,user){

       const response= await axiosInstance.get(url, {
               headers: {
                  Authorization: `Bearer ${user}`,
                  'Content-type': 'application/json',
                                },
           }).then((res) => {
            
               if(res.data.data==null || ( typeof res.data.data.response==='string'|| res.data.data.response instanceof String))
              {
                 
                  return false
              } 
               else
               return res.data.data.response;
                         
         })
         .catch((err)=>{return err})

         return response
    }
  
    async function getDigiLocker(url,user){
      const response =await axiosInstance.get(url, {
               headers: {
                  Authorization: `Bearer ${user}`,
                  'Content-type': 'application/json',
               },
           }).then((res) => {
                          
               if(res?.data?.data!=null)
                  return res?.data?.data;
             
                              
         })
         .catch((err)=>{return err})

         return response;

    }
    async function getPanStatus(url){
       
      
      const response= await axiosInstance.get(url).then((res) => {
                          
               if(res.data.data.length>0)
               {
                 return res.data.data.find(obj => {
                return obj.document==2 
                  })
                }
              
               }
       )
         .catch((err)=>{
        //  alert(err?.response?.data?.message)
          return err})
       

       return response
  
    }

    async function getPanProStatus(url,data,user){
       
      
      const response= await axiosInstance.post(url,data,{
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }).then((res) => {
                          
              return res?.data?.data
              
               }
       )
         .catch((err)=>{return err})
       

       return response
  
    }


    async function getBankSelfieDetails(url,user){
      const response=await  axiosInstance.get(url, {
                   headers: {
                      Authorization: `Bearer ${user}`,
                     'Content-type': 'application/json',
                          },
             }).then((res) => {
                         
                      return{
                        bankDetails:res?.data?.data?.bankDetails,
                        selfieDetails:res?.data?.data?.selfieDetails,
                      facematchDetails:res?.data?.data?.facematchDetails
                      }

         })
         .catch((err)=>{return err})


         return response
    }

    async function getProfileDetails(url) {

      const response=await axiosInstance.get(url)
      .then((res)=>{
        return res.data
      })
      .catch((err)=>{
        console.log("err",err)
        // alert(err?.response?.data?.message)
        return err
      })

      return response
      
    }

    async function getUploadedFiles(url,user){

      const response =await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })

      return response

    }

    async function getCibilDetails(url) {
      const response=await axiosInstance.get(url)
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err
      })
      

      return response
    }
    async function fetchSoftPullDetails(url) {
      const response=await axiosInstance.get(url)
      .then((res)=>{
        console.log("cicil soft pull",res)
        return res;
      })
      .catch((err)=>{
        return err
      })
      

      return response
    }
    async function getActivity(url) {
      const response=await axiosInstance.get(url)
      .then((res)=>{
        console.log("response",res)
        return res;
      })
      .catch((err)=>{
        return err
      })
      

      return response
    }
    
    //post activity
    async function submitActivity(url,body,user) {
      const response=await axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        console.log("response",res)
        return res;
      })
      .catch((err)=>{
        return err
      })
      

      return response
    }

    async function postIncomePdf(url,body,user) {

      const response=await axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err;
      })

      return response;
      
    }
    async function getIncomeProfileAnalytics(url,user) {

      const response=await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        console.log(err)
        return err;
      })

      return response;
      
    }
    
    async function postFoirDetails(url,body,user) {

      const response=await axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err;
      })

      return response;
      
    }
    
    async function getFoirDetails(url,user) {

      const response=await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err;
      })

      return response;
      
    }
    async function getInstituteInfo(url,user) {

      const response=await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err;
      })

      return response;
      
    }
    async function createProduct(url,body) {

      const response=await axiosInstance.post(url,body)
      .then((res)=>{
        return res;
      })
      .catch((err)=>{
        return err;
      })

      return response;
      
    }
    
    async function fetchLoans(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;
    }
    async function fetchRepayments(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
          return res?.data?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;
    }

    async function getProductList(url,user){

      const response =await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })

      return response
    }
    async function updatingBankDetails(url,obj4,user) {
  
    
      const response = axiosInstance.post(url,obj4,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Success")
    })
    .catch((err) => {
      alert("Error !")
        
    });
    return response
    }
    async function updateBankdetails(url,obj3,user) {
  
    
      const response = axiosInstance.post(url,obj3,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Bank Details Added")
        setTimeout(()=>{
          window.location.reload();
        },1000);})
    .catch((err) => {
      alert("Error in Bank Details!!!")    
    });
    return response
    }
    async function updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user) {
  
    
      const response = axiosInstance.post(updateInstituteDetailsUrl,updateObj,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Details Updated")
        setTimeout(()=>{
          window.location.reload();
        },1000);})
    .catch((err) => {
      alert("Failed To Update Details Contact Team")
    });
    return response
    }
    
    async function callBankdetails(saveSqlBankDetailsUrl,obj4,user) {
  
    
      const response = axiosInstance.post(saveSqlBankDetailsUrl,obj4,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Success")
    })
    .catch((err) => {
      alert("Error !")
        
    });
    return response
    }
    async function callbankdetails(saveBankDetailsUrl,obj3,user) {
  
    
      const response = axiosInstance.post(saveBankDetailsUrl,obj3,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Bank Details Added")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    .catch((err) => {
      alert("Error in Bank Details!!!")
        
    });
    return response
    }
    async function callRegdetails(b2bsignupURL,obj,user) {
  
    
      const response = axiosInstance.post(b2bsignupURL,obj,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("Uploaded")
      })
    .catch((err) => {
     alert("Error ! Check Fields")
        
    });
    return response
    }
    async function createInstitute(createUrl,obj2,user) {
  
    
      const response = axiosInstance.post(createUrl,obj2,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        alert("Institute Details Added")
      })
    .catch((err) => {
      alert('Error ! Check Fields')
        
    });
    return response
    }
    async function userSearch(sanctionUrl,navigate,status,user) {
  
    
      const response =await axiosInstance.get(sanctionUrl,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("usersearch1",res.data.data)
        if(res.data.data!==null){
          if(status==='Pending Disbursement')
          {
            navigate(`/status/Pending`)
          }
          else if(status==='In Sanction')
          {
            navigate(`/status/Sanction`)
          
          }
          else if(status==='Ready to Disburse')
          {
            navigate(`/status/disbursement`)
          }
          else if(status==='In Process')
          {
            navigate(`/status/Process`)
          }
        }
     
        return res;
      })
    .catch((err) => {
      return err
        
    });
    return response
    }
    async function userSearchdefault(getAppUrl,status,navigate,user) {
  
    
      const response =await axiosInstance.get(getAppUrl,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("usersearch1",res.data.data)
        console.log("user status",status)
        if(res?.data?.data!==null)
        {
            if(status==='In Submission')
          {
            navigate(`/Status/submission`)
          }
          else if(status==='In Review')
          {
            navigate(`/Status/review`)
          
          }
          else if(status==='Disbursed')
          {
            navigate(`/Status/disbursed`)
          }
          else if(status==='Rejected')
          {
            navigate(`/Status/rejected`)
          }
          else if(status==='Dropped')
          {
            navigate(`/Status/dropped`)
          }
          else if(status==='On Hold')
          {
            navigate(`/Status/Hold`)
        }
        }
       return res
      })
    .catch((err) => {
      return err
        
    });
    return response
    }
    async function userSearchInst(urlUsers,status,navigate,user) {
  
    
      const response =await axiosInstance.get(urlUsers,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("usersearchINS",res.data.data)
        if(res?.data?.data!==null)
        {
            if(status === "In Process" || status === "In Submission" || status === "In Review" || status === "In Sanction" || status === "In Disbursement" || status === "Ready to Disburse")
          {
            navigate(`/applications`)
          }
          
          else if(status==='Disbursed')
          {
            navigate(`/Status/disbursed`)
          }
          else if(status==='Rejected')
          {
            navigate(`/Status/rejected`)
          }
          else if(status==='Dropped')
          {
            navigate(`/Status/dropped`)
          }
          else if(status==='On Hold')
          {
            navigate(`/Status/Hold`)
        }
        }
       return res
      })
    .catch((err) => {
      return err
        
    });
    return response
    }
    async function fetchLoanDetails(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;
    }
    async function fetchLoansCount(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;
    }

    async function fetchCallDetails(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;

    }
    async function getNotifications(url) {

      const response=await axiosInstance.get(url)
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })
      
      return response

    }
    async function generateManualPaymentReceipt(url,user,body) {

      const response=await axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        console.log("resreceipt",res)
        alert(res?.data?.message)
        return res
      })
      .catch((err)=>{
        alert(err?.response?.data?.message)
        console.log("errorr",err?.response?.data?.message)
        return err
      })
      
      return response

    }
    async function createReminder(url,body,user) {

      const response=await axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })
      
      return response

    }
    async function getEmiHistory(url,user) {

      const response=await axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })
      
      return response

    }
    async function uploadFile(url,body) {

      const response=await axiosInstance.post(url,body)
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })
      
      return response

    }
    async function getReminder(url) {

      const response=await axiosInstance.get(url)
      .then((res)=>{
        return res
      })
      .catch((err)=>{
        return err
      })
      
      return response

    }
    async function reportdetails(urlUsers,data, user) {
  
    
    
      const response =  axiosInstance.post(urlUsers,data,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("1",res?.data?.data)
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;

    }

    async function fetchInstitutes(getinst, user) {
  
    
    
      const response =  axiosInstance.get(getinst,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;

    }
     async function deleteReminder(url,body,user) {
      console.log(user,"delete body")
      const response=await axiosInstance.delete(url,{
        
        headers:{
          Authorization:`Bearer ${user}`
        },
        data:body
      })
      .then((res)=>{
       
        return res
      })
      .catch((err)=>{

        return err
        
      })
      
      return response


    }

    async function fetchProducts(getinst, user) {
  
    
    
      const response =  axiosInstance.get(getinst,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;

    }
    async function fetchfiltereddata(url, user) {
  
    
    
      const response =  axiosInstance.get(url,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
        console.log("ressssss",res?.data?.data)
          return res?.data?.data
      })
      .catch((err) => {
          console.log("Err", err)
          return err
      });
      
    
      return response;
    }
    async function createApplication(url, body,user,arr) {
  
      const response =  axiosInstance.post(url,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res) => {
          alert("Application Created Status: In Submission ")
          const userId = res.data.data.userId;
            
            if (userId) {
              const data2 = { userId ,
                        latitude : 0,
                        longitude : 0,};
        
              
              axiosInstance.post(`/end-user/submit/admin`, data2, { headers:{
                'Authorization': `Bearer ${user}`,
                'Content-Type': 'application/json',
              } })
                .then(() => {
                  
                  alert("Message has been sent to customer")
                })
                .catch((error) => console.log("error", error));
            }
           if(arr.length>0)
      { 
       
        for(let i=0;i<arr.length;i++)
        {
          arr[i].userId=res?.data?.data.userId
        }
        
        console.log("arr--->",arr)
        const urlUploadDocs='/users/documents/create/multiple'
        setTimeout(() => {
          
          axiosInstance.post(urlUploadDocs,arr.filter(item=>(item.type!=="aadhaar"&&item.type!=="pan")&&(item.url1!==""||item.url2!=="")),{
            headers:{
              Authorization:`Bearer ${user}`
            }
          })
          .then((res)=>{
           setTimeout(() => {
             window.location.reload();
           }, 1000);
          })
          .catch((err)=>{
            console.log("Error")
          })
        }, 1000);
      }
          return res?.data?.data
      })
      .catch((err) => {
          alert(err?.response?.data?.message.map((item)=>{
            return(
              ` ${item}\n`
    
            )
          }))
          
      });
      
    
      return response;
    }



async function getCustomerDashboard(url, user, mob) {
  const payLoad = {
    mobile: mob,
  };

  const response = axiosInstance
    .post(url, payLoad, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      console.log("Err", err);
      return err;
    });

  return response;
}