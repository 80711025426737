 
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import plus from "../../assets/images/plus_icon.png"
import close from "../../assets/images/close.png"
import Box from '@mui/material/Box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Checkbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import jwt_decode from 'jwt-decode'

import {
   Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
  Form,
  Input ,
  FormGroup,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
} from 'reactstrap';
import {useDispatch,useSelector} from 'react-redux';
import {userActions} from '../../actions/user.actions'
import './index.css'
import increase from '../../assets/images/increase.png'
import minus from '../../assets/images/minus.png'
import Tooltip from '@mui/material/Tooltip';
import axiosInstance from '../../helpers/axios';
 
const CustomTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
});
 

export default function AddProducts(){

  const [load,setLoading]=useState(false)
   const [status, setStatus] = useState("Select Category of Product");
  const user=sessionStorage.getItem("user")
  const decode=jwt_decode(user)

  const [formOpen, setFormOpen] = useState(false);

  

  const FacilityTypeArray = ["", "Dicounted Interest", "Standard Interest", "Hybrid", "Business To Business"];
  const InterestTypeArray = ["", "Per Annum", "Flat"];
  const ProcessingFeeTypeArray = ["", "Flat", "Percentage"];

  const [activeTab, setActiveTab] = useState('1');
  const location = useLocation();
  const [open, setOpen] = useState(null);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };


  //modal
   const [confirmationDialog, setConfirmationDialog] = useState(false);
   const [resultDialog, setResultDialog] = useState(false);
  const [resultMessage, setResultMessage] = useState('Hold On...');
 


  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [formDropDownOpen, setFormDropDownOpen] = useState(false);
  const [fldgFormDropDownOpen, setFldgFormDropDownOpen] = useState(false);
  const [advanceEMIDropDownOpen, setAdvanceEMIDropDownOpen] = useState(false);
  const [disbursementMonth1DropDownOpen, setDisbursementMonth1DropdownOpen] = useState(false);
  const [disbursementMonth2DropDownOpen, setDisbursementMonth2DropdownOpen] = useState(false);
  const [disbursementMonth3DropDownOpen, setDisbursementMonth3DropdownOpen] = useState(false);
  const [disbursementMonth4DropDownOpen, setDisbursementMonth4DropdownOpen] = useState(false);
 

  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const handleFormToggle = () => {
    setFormDropDownOpen(!formDropDownOpen);
  };
  const handleFLDGFormToggle = () => {
    setFldgFormDropDownOpen(!fldgFormDropDownOpen);
  };
  const handleAdvanceEMIToggle = () => {
    setAdvanceEMIDropDownOpen(!advanceEMIDropDownOpen);
  };
  const handleDisbursementMonth1Toggle= () => {
    setDisbursementMonth1DropdownOpen(!disbursementMonth1DropDownOpen);
  };
  const handleDisbursementMonth2Toggle= () => {
    setDisbursementMonth2DropdownOpen(!disbursementMonth2DropDownOpen);
  };
  const handleDisbursementMonth3Toggle= () => {
    setDisbursementMonth3DropdownOpen(!disbursementMonth3DropDownOpen);
  };
  const handleDisbursementMonth4Toggle= () => {
    setDisbursementMonth4DropdownOpen(!disbursementMonth4DropDownOpen);
  };
  //add a user
  const [number, setNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  //form
  const [prodTenure,setTenure]=useState("Tenure")
  const [fldgReturn,setFldgReturn]=useState("FLDG")
  const [advanceEMIValue,setAdvanceEMI]=useState("Advance EMI")
  const [disbursementMonth1,setDisbursementMonth1]=useState("Month 1")
  const [disbursementMonth2,setDisbursementMonth2]=useState("Month 2")
  const [disbursementMonth3,setDisbursementMonth3]=useState("Month 3")
  const [disbursementMonth4,setDisbursementMonth4]=useState("Month 4")
  const tenureArray=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const disbursementMonth1Array=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const disbursementMonth2Array=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const disbursementMonth3Array=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const disbursementMonth4Array=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const fldgArray=[3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
  const advanceEMIArray=[0,1,2,3,4,5,6,7,8,9,10,11,12]
  const [DITChoice,setDitChoice]=useState(true)
  const [SITChoice,setSitChoice]=useState(true)
  const [interestChoice,setInterestChoice]=useState(true)
  const [fldg,setFldg]=useState(true)
  const [pfType,setPftype]=useState(null)
  const [pfDropdown,handlePfDropdown]=useState(false)
  const [limitAmt,setPfLimitAmount]=useState(null)
  const [gst,setGST]=useState(true)
  const [disburse,setDisbursement]=useState(false)
  const [disbursermentPercentage1,setDisbursementPercentage1]=useState(null)
  const [disbursermentPercentage2,setDisbursementPercentage2]=useState(null)
  const [disbursermentPercentage3,setDisbursementPercentage3]=useState(null)
  const [disbursermentPercentage4,setDisbursementPercentage4]=useState(null)
  const [addDisbursement,setAddDisbursement]=useState(false)
  const [addDisbursement2,setAddDisbursement2]=useState(false)
  const [addDisbursementNext,setAddDisbursementNext]=useState(true)

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  
const [productCode,setProductCode]=useState(null)
  
const handleAddDisbursement=()=>{
    setAddDisbursement2(!addDisbursement2)
    setAddDisbursementNext(!addDisbursementNext)
    
  }

const handleAddDisbursement2=()=>{
  setAddDisbursementNext(!addDisbursementNext)
}



const handleStandardToggle=()=>{
  setSitChoice(!SITChoice)
  if(SITChoice)
  document.getElementById("switch2").className="switchOff"
  else
  document.getElementById("switch2").className="switch"

}
const handleDisbursementToggle=()=>{
  setDisbursement(!disburse)
  if(disburse)
  document.getElementById("switch6").className="switchOffNo"
  else
  document.getElementById("switch6").className="switchYes"

}
const handleDiscountToggle=()=>{
  setDitChoice(!DITChoice)
  if(DITChoice)
  document.getElementById("switch1").className="switchOff"
  else
  document.getElementById("switch1").className="switch"

}
const handleInterestToggle=()=>{
  setInterestChoice(!interestChoice)
  if(interestChoice)
  document.getElementById("switch0").className="switchOff"
  else
  document.getElementById("switch0").className="switch"

}
const handleFldgToggle=()=>{
  setFldg(!fldg)
  if(fldg)
  document.getElementById("switch3").className="switchOffNo"
  else
  document.getElementById("switch3").className="switchYes"

}
const handleGSTToggle=()=>{
  setGST(!gst)
  if(gst)
  document.getElementById("switch5").className="switchOffNo"
  else
  document.getElementById("switch5").className="switchYes"

}
const handlePfToggle=()=>{
  setPftype(!pfType)
  if(pfType)
  document.getElementById("switch4").className="switchOffPF"
  else
  document.getElementById("switch4").className="switchPF"

}

const popUpForm = () => {
  setFormOpen(true);
}




//get institute Id
const [instituteList,setInstituteList]=useState(null)
const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")
const [instituteCode,setInstituteCode]=useState(null)


const dispatch=useDispatch();

const instituteState=useSelector((state)=>state?.GETINSTITUTE?.res?.data?.data)
const getInsituteInfo=(text)=>{
  const urlGetInstitute=`/institute/info/name?name=${text}`;
    
  if(text.length>=4)
  {
   dispatch(userActions.getInstituteInfo(urlGetInstitute,user))
   setInstituteList(instituteState)
  }
  else{
    setInstituteList(null)
    setStarterPhrase("Type Institute Name")
  }
}


//search products
const [productsList,setProductList]=useState(null)
const [searchInstituteName,setSearchInstituteName]=useState(null)

const getProductDetails=(text)=>{
  const instituteInfoUrl=`/institute/info/name?name=${text}`;
  if(text.length>=4)
  {
    axiosInstance.get(instituteInfoUrl,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
      if(res.data.data==[])
      {
        setInstituteList(null)
        setStarterPhrase("No institute Found")
      }
      else
      setInstituteList(res.data.data)

    })
  }
  else{
    setInstituteList(null)
    setStarterPhrase("Type Institute Name")
  }
}


const handleLoad=(e)=>{
    setDisbursement(false)
    setPftype("Select")
    setSitChoice(true)
    setDitChoice(true)
    setInterestChoice(true)
    setFldg(true)
    setGST(true)
    setLoading(false)
    setStatus(e.target.innerText)
    if(e.target.innerText==="Hybrid")
    setProductCode("HY")
    else if(e.target.innerText==="Standard EMI")
    setProductCode("ST")
    else if(e.target.innerText==="Subvention GST")
    setProductCode("SV")
    else if(e.target.innerText==="Subvention Non GST")
    setProductCode("SN")
    else if(e.target.innerText==="B2B")
    setProductCode("BB")
    setInstituteList(null)
    setInstituteCode(null)
    setTenure("Tenure")
    setFldgReturn("FLDG Return")
    setAdvanceEMI("Advance EMI")
    setDisbursementMonth1("Month 1")
    setDisbursementMonth2("Month 2")
    setDisbursementMonth3("Month 3")
    setDisbursementMonth4("Month 4")
    setTimeout(() => {
    setLoading(true)
  }, 1000);
  }

//Creating Product API
const [instituteID,setInstituteID]=useState(null)
const [productName,setProductName]=useState(null)
const [studShare,setStudentShare]=useState(null)
const [instShare,setInstituteShare]=useState(null)
const [FLDGpercentage,setFldgPercentage]=useState(null)
const [pfTypeValue,setPfTypeValue]=useState(null)
const [IRR,setIRR]=useState(null)
const [dir,setDIR]=useState(null)
const [sir,setSIR]=useState(null)
const [ir,setIR]=useState(null)

const handleInstituteDetails=(data)=>{
  document.getElementById("instituteNameBox").value=data.brandName
  setInstituteCode(data.code)
  setInstituteID(data.id)
  setInstituteList(null)

}



//create Product
const createProductState=useSelector((state)=>state?.CREATEPRODUCT?.res?.data?.message)
 
const handleCreateProduct=()=>{

  if(instituteCode&&IRR&&(pfTypeValue)&&(fldg?isNaN(fldgReturn)?false:true:true)&&(isNaN(advanceEMIValue)?false:true))
  {
       if(!productName)
  {
    alert("Product Name Missing or Check Length")
  }
  else
  {

  
    const productData={
  
    name:`${instituteCode}${productCode}${productName}`,
    tenure: prodTenure,
    instituteId: instituteID,
    facilityType: status==="Hybrid"?3:status==="Subvention GST"?1:status==="Subvention Non GST"?5:status==="BusinessToBusiness"?4:status==="Standard EMI"?2:0,
    irr: IRR * 0.01,
    discountedInterestPercentage: productCode==="SV"||productCode==="SN"?ir*0.01:productCode==="ST"||productCode==="BB"?0:productCode==="HY"?dir*0.01:0,
    instituteShare:productCode==="HY"?instShare*0.01:productCode==="SV"||productCode==="SN"?100:productCode==="ST"?0:0,
    studentShare:productCode==="HY"?studShare*0.01:productCode==="SV"||productCode==="SN"?0:productCode==="ST"?100:0,
    discountedInterestType: DITChoice==true?2:1,
    standardInterestPercentage: productCode==="SV"||productCode==="SN"?0:productCode==="ST"||productCode==="BB"?ir*0.01:productCode==="HY"?sir*0.01:0,
    standardInterestType: SITChoice==true?2:1,
     processingfeeType: pfType==="Varied"?3:pfType==="Flat"?1:2,            
    limitAmount:pfType==="Varied"?limitAmt:0,                           
    processingfeeValue: pfType==="Flat"?pfTypeValue:pfTypeValue*0.01,
    processingfeeValueGST: gst==true?(pfType==="Flat"?pfTypeValue*0.18:pfTypeValue*0.01*0.18):0,
    processingfeValueIncludingGST: gst==true?(pfType==="Flat"?pfTypeValue-(-pfTypeValue*0.18):(pfTypeValue*0.01-(-(pfTypeValue*0.01)*0.18))):pfType==="Flat"?pfTypeValue:pfTypeValue*0.01,
    fldgApplicable: fldg==true?true:false,
    fldgPercentage: FLDGpercentage,
    fldgReturnMonth: fldgReturn,
    advanceEmis: advanceEMIValue,
    disbursementMonth1Percentage: disburse?disbursermentPercentage1:100,
    disbursementMonth2Percentage: disburse?disbursermentPercentage2:0,
    disbursementMonth3Percentage: addDisbursement?disbursermentPercentage3:0,
    disbursementMonth4Percentage: addDisbursement2?disbursermentPercentage4:0,
    disbursementMonth1: disburse?disbursementMonth1:0,
    disbursementMonth2: disburse?disbursementMonth2:0,
    disbursementMonth3: addDisbursement?disbursementMonth3:0,
    disbursementMonth4: addDisbursement2?disbursementMonth4:0,
    createdBy: decode?.display_name
    }
  
    const createProductUrl=`/products/create`

    dispatch(userActions.createProduct(createProductUrl,productData));

    
    // axios.post(createProductUrl,productData)
    // .then((res) => {
    //           if (res.data.message === 'Successful') {
    //             setConfirmationDialog(!confirmationDialog);
    //             setResultMessage('Product Added Successfully');
    //             setResultDialog(!resultDialog)
    //             setTimeout(() => setResultDialog(false), 2000);
    //             setTimeout(() => window.location.reload(true), 1500);
    //           }
    //         },
    //         (error) => {
    //           setResultMessage(`${error}`);
    //           setTimeout(() => setResultDialog(!resultDialog), 2500);
    //             setTimeout(() => setResultDialog(false), 2750);
    //         },
    // );
   }
  }
  else
  alert("Some Fields are missing or in wrong Format")
}


useEffect(() => {
  if(createProductState==='Successful')
    {
      setResultMessage('Product Added Successfully');           
      setResultDialog(!resultDialog)           
      setTimeout(() => setResultDialog(false), 2000);        
      setTimeout(() => window.location.reload(true), 1500);
    }
  
}, [createProductState])

const [policies,setPolicies] = useState([])
const [rules,setRules] = useState([])

const handleHover = (item)=>{
  const rulesArray = JSON.parse(item.rulesArray);
  const rules = rulesArray.map(ruleId => {
    const url = `/rules/id?ruleId=${ruleId}`;
    return axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res)=>res?.data?.data);
  });
  let resultsArray = [];
  Promise.all(rules)
  .then(results => {
    resultsArray = results; 
    setRules(resultsArray)
  })
  .catch(error => {
    console.error(error);
  });
}

const toolTip = () => {
  return (
    <div>
      {rules?.map((rule, index) => (
        <div key={`rule-${index}`}>
          {rule?.description}
        </div>
      ))}
    </div>
  );
};
const [addConsentOpen, setAddConsentOpen] = useState(false);
const [selectedSection, setSelectedSection] = useState(null);

const handleToggleConsent = () => {
  setSelectedSection('After Consent');
  setAddConsentOpen(!addConsentOpen);
  const url = `/rules/all-policies`
  axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }).then((res)=>{
    setPolicies(res?.data?.data)
  })
};
const [inProcessOpen, setInProcessOpen] = useState(false);

const handleToggleInProcess = () => {
  setSelectedSection('In Process');
  setInProcessOpen(!inProcessOpen);
  const url = `/rules/all-policies`
  axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }).then((res)=>{
    setPolicies(res?.data?.data)
  })
};
const [sanctionOpen, setSanctionOpen] = useState(false);

const handleToggleSanction = () => {
  setSelectedSection('After Sanction');
  setSanctionOpen(!sanctionOpen);
  const url = `/rules/all-policies`
  axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }).then((res)=>{
    setPolicies(res?.data?.data)
  })
};
const [checkedItems, setCheckedItems] = useState({});

const handleCheckboxChange = (item, section,index) => {
  setCheckedItems((prevState) => {
    const newCheckedItems = { ...prevState };

    if (newCheckedItems[item.name] === section) {
      delete newCheckedItems[item.name];
    } else {
      newCheckedItems[item.name] = section;
      console.log(`${section.toLowerCase().split(' ').join('')} ${index}`);
    }

    return newCheckedItems;
  });
};

const isChecked = (item, section) => checkedItems[item.name] === section;
const isDisabled = (item, section) => checkedItems[item.name] && checkedItems[item.name] !== section;

const renderCheckboxes = (section) => {
  return policies?.map((item, index) => (
    <Tooltip title={toolTip()} onMouseEnter={() => handleHover(item)} key={index} arrow placement='left'>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '-1.5em', marginLeft: '2em',cursor:'pointer'}}>
        <Checkbox
          checked={isChecked(item, section)}
          onChange={() => handleCheckboxChange(item, section,index)}
          disabled={isDisabled(item, section)}
        />
        <p style={{ fontFamily: 'Inter', fontWeight: 'bold', color: 'black', paddingTop: '1em' }}>{item.name}</p>
      </div>
    </Tooltip>
  ));
};

 return(

  <div>
     <p style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',cursor:'pointer',width:'180px',height:'34px',fontFamily:'Inter-Medium',fontSize:'16px',backgroundColor:'#D22129',display:'flex',alignItems:'center',justifyContent:'center',color:'white',borderRadius:'6px'}} onClick={() => popUpForm()}><img src={plus} style={{marginRight:'10px'}}/>Add Product</p>
    
      {formOpen && (
      <>
       <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal 
      style={{
      
        top: '0%',
        bottom:'10%',
        backgroundColor: 'white'}} size="xl" className='edit-form ' isOpen={formOpen}>
          
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Add Product</p>
          <img style={{height:'1.5em',width:'1.5em'}}  className="image1" onClick={()=>setFormOpen(!formOpen)} src={close}/>
          </div>
        
        <ModalBody>

          {/* <div style={{padding:'10px',boxShadow:'0px 0px 1px 1px #D0D0D0',borderRadius:'20px',marginTop:'1em'}}> */}
             <Modal isOpen={resultDialog}>
                    <ModalHeader>Waiting For Confirmation</ModalHeader>
                    <ModalBody>{resultMessage}</ModalBody>
              </Modal>
            <Form>
            <FormGroup>
            <Row>
              <Col >
                <Row>
                <div>
                  <Form>
                  <div style={{display:'block'}}>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                      <FormGroup  style={{marginRight:'10px'}}>
              <div style={{paddingLeft:'1em'}}>
                <p style={{fontWeight:'600'}}>Select Product Category</p>
                <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
                <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                  {status}
                </DropdownToggle>
                <DropdownMenu style={{overflowY:'scroll'}}>
                <DropdownItem
                    onClick={(e) =>handleLoad(e)}
                  >
                    Subvention GST
                  </DropdownItem>
                  <DropdownItem onClick={(e) => handleLoad(e)}>
                    Subvention Non GST
                  </DropdownItem>
                  <DropdownItem onClick={(e) => handleLoad(e)}>
                    Standard EMI
                  </DropdownItem>
                  <DropdownItem onClick={(e) => handleLoad(e)}>
                    B2B
                  </DropdownItem>
                  <DropdownItem onClick={(e) =>handleLoad(e)}>
                    Hybrid
                  </DropdownItem>
                
                </DropdownMenu>
              </Dropdown>
            </div>
            </FormGroup>
                      </div>
                      </div>
              
          { !load&&status!=="Select Category of Product"?<div style={{textAlign:'center',fontSize:'2em'}}> Loading...</div>:<div>

                  
              {
                load?<>
                <div key='1' style={{display:'flex'}}>
                  
                <div style={{display:'block',width:'45%',marginLeft:'5%'}}>
                <div style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                  {/* <FormGroup > */}

                      <p style={{fontWeight:'600',width:'7em'}}>Inst. Name</p>
                      <div style={{flex:'block',overflowX: 'hidden',}} >
                      <Input id="instituteNameBox" placeholder="Enter First 4 letters of Inst." onChange={(e)=>getInsituteInfo(e.target.value)} type="text" style={{width:'15em'}}/>
                      
                        {
                          instituteList?instituteList.map((item)=>{
                          return(
                            <div onClick={()=>handleInstituteDetails(item)} className="instituteName" style={{border:'1px solid grey',borderRadius:'0.5em'}}>
                            <p  style={{fontWeight:'600',paddingTop:'0.5em',paddingLeft:'0.5em'}}>{item.brandName}</p>
                            </div>
                          )
                          }
                          ):<div/>
                        }
                      </div>
                      {/* </FormGroup> */}

                  </div>
    
                  <div style={{display:'flex',marginTop:'1em'}}>
                      <p style={{fontWeight:'600',width:'5em'}}>Tenure</p>
                      <FormGroup>
                      <Dropdown style={{marginBottom:'1em',marginLeft:'3em'}}  isOpen={formDropDownOpen} toggle={() => handleFormToggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {prodTenure}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {tenureArray.map((item)=>{return(<><DropdownItem onClick={(e)=>setTenure(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                  </div>
                    <div style={{display:'flex', marginTop:'1em'}}>
                      <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em'}}>IRR</p>
                      <Input onChange={(e)=>setIRR(e.target.value)} type="number" style={{width:'15em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                  { status==="Hybrid"?<>
                  
                    <div style={{display:'flex', marginTop:'1em'}}>
                      <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',lineHeight:'1.5em'}}>Discounted Interest Type</p>
                      <div className="switchDiv" onClick={()=>handleDiscountToggle()}><p  className="switch" id="switch1">{DITChoice==true?"Flat":"Per Annum"}</p></div>
                      </FormGroup>
                  </div>
                  
                  <div style={{marginTop:"1em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',lineHeight:'1.5em'}}>Discounted Interest Rate<br/></p>
                      <Input type="number" onChange={(e)=>setDIR(e.target.value)}  style={{width:'15em',height:'2.5em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                  
                  <div style={{marginTop:"1em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',lineHeight:'1.5em'}}>Standard Interest Type<br/><small>(Click to Toggle)</small></p>
                      <div className="switchDiv" onClick={()=>handleStandardToggle()}><p  className="switch" id="switch2">{SITChoice==true?"Flat":"Per Annum"}</p></div>
                      </FormGroup>
                      
                  </div>
                  <div style={{marginTop:"1em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',lineHeight:'1.5em'}}>Standard Interest Rate<br/></p>
                      <Input type="number" onChange={(e)=>setSIR(e.target.value)}  style={{width:'15em',height:'2.5em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                
                <div style={{display:'block'}}>
                  <div style={{height:'3em',marginTop:"2em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',color:'blue',width:'8em',}}>Institute Share<br/></p>
                      <Input type="number" onChange={(e)=>setInstituteShare(e.target.value)}  style={{width:'15em',height:'2.5em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                  <div style={{display:'flex', marginTop:'3em'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',color:'blue',width:'8em'}}>Student Share</p><Input type="number" onChange={(e)=>setStudentShare(e.target.value)} style={{width:'15em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                </div></>
                
                :
                
                <>
                  
                    <div style={{height:'3em', marginTop:'1em', display:'flex'}}>
                      <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',}}>Interest Type</p>
                      <div className="switchDiv" onClick={()=>handleInterestToggle()}><p className="switch" id="switch0">{interestChoice==true?"Flat":"Per Annum"}</p></div>
                      </FormGroup>
                  </div>
                  
                  <div style={{height:'3em',marginTop:"2.5em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',}}>Interest Rate<br/></p>
                      <Input type="number" onChange={(e)=>setIR(e.target.value)}  style={{width:'15em',height:'2.5em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                </>}
                
                  <div style={{height:'3em',marginTop:"1em",display:'flex'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',}}>FLDG</p>
                      <div className="switchDiv" onClick={()=>handleFldgToggle()}><p  className="switchYes" id="switch3">{fldg==true?"Yes":"No"}</p></div>
                      </FormGroup>
                      
                  </div>
                  {
                    fldg==true?<> <div style={{height:'3em',marginTop:"2em",display:'flex'}}>
                      <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',}}>FLDG %<br/></p>
                      <Input type="number" onChange={(e)=>setFldgPercentage(e.target.value)} style={{width:'15em',height:'2.5em',marginBottom:'1em'}}/>
                      </FormGroup>
                  
                  </div>
                  <div style={{display:'flex', marginTop: '2em'}}>
                    <FormGroup style={{marginRight:'10px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'7em',marginTop:'0.5em'}}>FLDG Return</p>
                      <Dropdown style={{marginBottom:'1em',marginTop:'0.5em',marginLeft:'1em'}}  isOpen={fldgFormDropDownOpen} toggle={() => handleFLDGFormToggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {fldgReturn}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {fldgArray.map((item)=>{return(<><DropdownItem onClick={(e)=>setFldgReturn(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                  </div></>:null
                  }
                  
                </div>
                <div style={{display:'block',borderLeft:'1px dashed black',width:'45%'}}>
                <div style={{display:'flex',marginLeft:'1em'}}>
                    <FormGroup style={{marginRight:'8px' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em',}}>Advance EMI</p>
                      <Dropdown style={{marginBottom:'1em'}}  isOpen={advanceEMIDropDownOpen} toggle={() => handleAdvanceEMIToggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {advanceEMIValue}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {advanceEMIArray.map((item)=>{return(<><DropdownItem onClick={(e)=>setAdvanceEMI(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                  </div>
                  <div style={{display:'block',marginLeft:'1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em'}}>Product Name</p>
                      <div style={{display:'flex', flexDirection:'column'}}>
                      <span> <span style={{color:'white',backgroundColor:'#d32028',borderRadius:'10px',width:'6em',padding:'10px',boxShadow:'0px 0px 1px 1px #D0D0D0',fontWeight:'550'}}>{instituteCode}-{productCode} </span><Input type="text" onChange={(e)=>setProductName(e.target.value)} style={{width:'10em',marginBottom:'1em', position:'absolute',marginLeft:'5.5em',marginTop:'-2.4em' ,}}/></span>
                       
                        
                      </div>
                      </FormGroup>
                     
                  </div>
                 
                  <div style={{display:'flex',marginLeft:'1em', marginTop:'1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                            <p style={{ fontWeight:'600',width:'6em',}}>PF Type</p>
                              <Dropdown style={{marginBottom:'1em',marginLeft:'2em'}}  isOpen={pfDropdown} toggle={() => handlePfDropdown(!pfDropdown)}>
                              <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                                {pfType}
                              </DropdownToggle>
                                    <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                                     <DropdownItem onClick={(e)=>setPftype(e.target.innerText)}>
                                      Flat
                                    </DropdownItem>  
                                     <DropdownItem onClick={(e)=>setPftype(e.target.innerText)}>
                                      Percentage
                                    </DropdownItem>  
                                     <DropdownItem onClick={(e)=>setPftype(e.target.innerText)}>
                                      Varied
                                    </DropdownItem>  
                                    
                                    </DropdownMenu>
                            </Dropdown>
                            </FormGroup>
                          
                        </div>
                   {pfType==="Varied"?<div style={{display:'flex',marginLeft:'1em', marginTop:'1em'}}>
                     <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                            <p style={{fontWeight:'600',width:'8em'}}>Limit Amount</p>
                            <Input type="number" onChange={(e)=>setPfLimitAmount(e.target.value)}  style={{width:'15em',marginBottom:'1em'}}/>
                            </FormGroup>
                        </div>:null}
                  <div style={{display:'flex',marginLeft:'1em', marginTop : '1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em'}}>PF Value</p>
                      <Input type="number" onChange={(e)=>setPfTypeValue(e.target.value)}  style={{width:'15em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
                  <div style={{display:'flex',marginLeft:'1em', marginTop:'1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',}}>GST</p>
                      <div  className="switchDiv"><p onClick={()=>handleGSTToggle()} className="switchYes" id="switch5">{gst==true?"Yes":"No"}</p></div>
                      </FormGroup>
                  </div>
                  <div style={{display:'flex',marginLeft:'1em', marginTop:'1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em'}}>PF + GST</p><Input type="number" value={gst&&pfTypeValue?(pfTypeValue-(-pfTypeValue*0.18)):pfTypeValue}  disabled style={{width:'15em',marginBottom:'1em'}}/>
                      </FormGroup>
                  </div>
             
                

                  <div style={{display:'block'}}>
                  <div style={{display:'flex',marginLeft:'1em', marginTop : '1em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{ fontWeight:'600',width:'8em',lineHeight:'1.5em'}}>Disbursement in Tranches</p>
                      <div className="switchDiv">
                        <p  onClick={()=>handleDisbursementToggle()} className="switchOffNo" id="switch6">{disburse==true?"Yes":"No"}</p></div>
                      </FormGroup>
                  </div>
                      {
                        disburse===true?<>
                      <div style={{display:'flex',marginLeft:'3em', marginTop:'3em'}}>
                        <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8em',}}>Disbursement 1</p>
                      <Dropdown style={{marginBottom:'1em',marginLeft:'2em'}}  isOpen={disbursementMonth1DropDownOpen} toggle={() => handleDisbursementMonth1Toggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {disbursementMonth1}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {disbursementMonth1Array.map((item)=>{return(<><DropdownItem onClick={(e)=>setDisbursementMonth1(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      <Input type="number"  onChange={(e)=>setDisbursementPercentage1(e.target.value)} placeholder="% Percentage"  style={{height:'2.5em',width:'7.5em',marginLeft:'1em',}}/>
                      </FormGroup>
                      
                  </div>
                  <div style={{display:'flex',marginLeft:'3em', marginTop:'3em'}}>
                    <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8.2em',}}>Disbursement 2</p>
                      <Dropdown style={{marginBottom:'1em',marginLeft:'2em'}}  isOpen={disbursementMonth2DropDownOpen} toggle={() => handleDisbursementMonth2Toggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {disbursementMonth2}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {disbursementMonth2Array.map((item)=>{return(<><DropdownItem onClick={(e)=>setDisbursementMonth2(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      <Input type="number"  onChange={(e)=>setDisbursementPercentage2(e.target.value)} placeholder="% Percentage"  style={{height:'2.5em',width:'7.5em',marginLeft:'1em',}}/>
                      </FormGroup>
                    {  !addDisbursement?<div onClick={()=>setAddDisbursement(true)} ><span style={{ }}><img style={{width:'1.5em',height:'1.5em',marginLeft:'10px',marginLeft:'10px'}} src={increase}/></span>
                      
                  </div>:null}
                  
                  
                  </div>{
                      addDisbursement?
                      <div style={{display:'flex',marginLeft:'3em', marginTop:'3em'}}>
                        <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8.2em',}}>Disbursement 3</p>
                      <Dropdown style={{marginBottom:'1em',marginLeft:'2em'}}  isOpen={disbursementMonth3DropDownOpen} toggle={() => handleDisbursementMonth3Toggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {disbursementMonth3}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {disbursementMonth3Array.map((item)=>{return(<><DropdownItem onClick={(e)=>setDisbursementMonth3(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      <Input type="number"  onChange={(e)=>setDisbursementPercentage3(e.target.value)} placeholder="% Percentage"  style={{height:'2.5em',width:'7.5em',marginLeft:'1em',}}/>
                      </FormGroup>
                      <div  style={{display:'flex'}} ><span onClick={()=>setAddDisbursement(false)} style={{marginLeft:'10px'}}><img style={{width:'1.5em',height:'1.5em',marginLeft:'10px'}} src={minus}/></span>
                      {  addDisbursementNext?
                      <div>
                        <span  onClick={()=>handleAddDisbursement()} style={{  }}><img style={{width:'1.5em',height:'1.5em',marginLeft:'10px'}} src={increase}/></span>  
                  </div>:null
                  }
                  
                      </div>
                      
                    </div>
                    
                    :null
                    

                    }
                      {!addDisbursementNext?<div style={{display:'flex',marginLeft:'3em', marginTop:'3em'}}>
                        <FormGroup style={{marginRight:'1em' , display:'flex',flexDirection: 'row'}}>
                      <p style={{fontWeight:'600',width:'8.3em',}}>Disbursement 4</p>
                      <Dropdown style={{marginBottom:'1em',marginLeft:'2em'}}  isOpen={disbursementMonth4DropDownOpen} toggle={() => handleDisbursementMonth4Toggle()}>
                        <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none'}}>
                          {disbursementMonth4}
                        </DropdownToggle>
                              <DropdownMenu style={{height:"12em",overflowY: 'scroll',}}>
                              {disbursementMonth4Array.map((item)=>{return(<><DropdownItem onClick={(e)=>setDisbursementMonth4(e.target.innerText)}>
                                {item}
                              </DropdownItem></>)})}
                              
                              </DropdownMenu>
                      </Dropdown>
                      <Input type="number" onChange={(e)=>setDisbursementPercentage4(e.target.value)} placeholder="% Percentage"  style={{height:'2.5em',width:'7.5em',marginLeft:'1em',}}/>
                      </FormGroup>
                    {  !addDisbursementNext?<div onClick={()=>handleAddDisbursement2()} ><span style={{  }}><img style={{width:'1.5em',height:'1.5em',marginLeft:'10px'}} src={minus}/></span>
                      
                  </div>:null}
                  
                  
                  </div>:null}</>:null
                      
                }
                  </div>
                  </div>
                  {/* <div style={{display:'block',borderLeft:'1px dashed black'}}>
                 
              <p style={{marginLeft:'10px',fontSize:'1vw',fontWeight:'bold'}}>Policies</p>
              <div>
              <div style={{display:'flex',flexDirection:'row',marginLeft:'1em',cursor:'pointer'}} onClick={handleToggleConsent}>
                 {addConsentOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
                    <p style={{fontFamily:'Inter',fontWeight:'bold',color:'black',marginLeft:'0.5em'}}>After Consent</p>
               </div>
               {addConsentOpen 
               && renderCheckboxes('After Consent')
               }
              </div>
              <div>
              <div style={{display:'flex',flexDirection:'row',marginLeft:'1em',cursor:'pointer'}} onClick={handleToggleInProcess}>
                 {inProcessOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
                    <p style={{fontFamily:'Inter',fontWeight:'bold',color:'black',marginLeft:'0.5em'}}>In Process</p>
               </div>
               {inProcessOpen && renderCheckboxes('In Process')}
              </div>
              <div>
              <div style={{display:'flex',flexDirection:'row',marginLeft:'1em',cursor:'pointer'}} onClick={handleToggleSanction}>
                 {sanctionOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
                    <p style={{fontFamily:'Inter',fontWeight:'bold',color:'black',marginLeft:'0.5em'}}>After Sanction</p>
               </div>
               {sanctionOpen && renderCheckboxes('After Sanction')}

              </div>
                  </div> */}
              </div>
                </>:null
              }
            
            </div>
}
              <div style={{display:'flex',marginTop:'1em',justifyContent: 'space-around',}}>
                
                <Button
                  onClick={() => {
                    handleCreateProduct()
                  }}
                  style={{backgroundColor:'#d32028',border:'none'}}
                
                >
                  Submit
                </Button>
              
              </div>
              </Form>
              </div>
              </Row>
              </Col>
              </Row>
              </FormGroup>
              </Form>
        {/* </div> */}
        </ModalBody>
        </Modal>
        </>
        
      )}
      </div>

  );
 }