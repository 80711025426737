import * as React from 'react';
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import download from "../../assets/images/download.png"
import Datetime from 'react-datetime';
import calender from "../../assets/images/calender.png"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';




const Monthlydisbursements=()=>{
    const user = sessionStorage.getItem('user');
    const [monthlyDisburse, setMonthlyDisburse] = useState([]);
    const [duefilterDateFrom, setDueFilterDateFrom] = useState("");
  const [duefilterDateTo, setDueFilterDateTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
   
      useEffect(() => {
        setIsLoading(true)
        axiosInstance.get('/analytics/monthWise-disburements', {
          headers: {
            Authorization: `Bearer ${user}`,
          }
        })
          .then(function (response) {
            setMonthlyDisburse(response?.data?.data);
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          })
          .catch(function (error) {
            console.log(error);
          });
    
      }, []);
      function formatNumberWithCommas(number) {
        if (number === null || number === undefined) {
            return "-";
        }
        
        // Convert number to an integer to remove decimals
        number = Math.floor(number);
    
        return number.toLocaleString('en-IN');
    }
      const clearDateFilter = () => {
        const url = `/analytics/monthWise-disburements`
    
       
        setDueFilterDateFrom("");
        setDueFilterDateTo("");
        axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
          setMonthlyDisburse(res?.data?.data);
           
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      };
    
    
    
    
      const handleDownloadList=()=>{
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = String(currentDate.getFullYear());
        const formattedDate = `${day}-${month}-${year}`;
        document.getElementById("downloadButton").disabled=true;
        document.getElementById("downloadButton").innerText="Wait....";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").style.color= "white";
        document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
        document.getElementById("downloadButton").style.fontSize= "15px";
        document.getElementById("downloadButton").style.width= "130px";
        document.getElementById("downloadButton").style.cursor= "pointer";
        document.getElementById("downloadButton").style.height= "34px";
        document.getElementById("downloadButton").style.borderRadius= "5px";
        document.getElementById("downloadButton").style.textAlign= "center";
        // document.getElementById("downloadButton").style.marginLeft="150px";
        document.getElementById("downloadButton").style.backgroundImage = "none"
    
    
        const downloadTemplateUrl=`/excel/monthWise-disbursements `
        
        let queryParams = {}
       
        if(duefilterDateFrom && duefilterDateTo){
          queryParams.fromDate = duefilterDateFrom;
          queryParams.toDate = duefilterDateTo
        }
        
        axiosInstance.get("/excel/monthWise-disbursements", {
          params: queryParams,
          responseType: 'arraybuffer',
          headers: {
              Authorization: `Bearer ${user}`
          }
      }).then((res)=>{
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    
        }, 2000);
        const blob = new Blob([res.data], { type: "application/xlsx", });
        const url= window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href =url
        link.download = `${formattedDate}.xlsx`;
    
        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
      }
        
      ).catch((error)=>{
        alert("Error Downloading")
        document.getElementById("downloadButton").innerText="Error..";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          // document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='20px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
        }, 1000);
      })
      } 
    
    
      const handleDateFilterApi = (duefilterDateFrom,duefilterDateTo) => {
        setIsLoading(true);
       
        if (duefilterDateFrom && !duefilterDateTo) {
          alert('Enter Due Date Range to Filter');
          setIsLoading(false);
          return;
        }
       
        setDueFilterDateFrom(duefilterDateFrom);
        setDueFilterDateTo(duefilterDateTo);
        let queryParams = {}
       
        if(duefilterDateFrom && duefilterDateTo){
          queryParams.fromDate = duefilterDateFrom;
          queryParams.toDate = duefilterDateTo
        }
       
        
        axiosInstance.get('/analytics/monthWise-disburements',{
          params:queryParams
        }, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
      
          setMonthlyDisburse(res?.data?.data);
          
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
          
      };
      const [isOpen,setIsOpen]=useState(true)  
      
      return(
        <div style={{display:'flex',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
        <Topbar/>
        <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Monthly disbursements - POS </p>
        <div style={{display:'flex', justifyContent:'space-between'}} >
              <div style={{display:'flex', }}>
            <div style={{ height: '7px',width:'12em'}}>
            <Datetime
                    closeOnSelect="false"
                    id="fromDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    onChange={(event) =>{ 
                      if (event && event.format) {
                      setDueFilterDateFrom(event.format('DD MMM YYYY'));
                    }else{
                      setDueFilterDateFrom('');
                      clearDateFilter()
                    }
                    }}

                    locale="en-gb"
                    inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            <div style={{ height: '7px',width:'12em',marginLeft:'20px' }}>
            <Datetime
                  closeOnSelect="false"
                    id="toDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    locale="en-gb"
                    onChange={(event) => {
                      if (event && event.format) {
                        setDueFilterDateTo(event.format('DD MMM YYYY'));
                      } else {
                        setDueFilterDateTo(''); 
                        clearDateFilter()
                      }
                    }}

                    inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px',marginLeft:'20px'}}
            
            onClick={() => handleDateFilterApi(duefilterDateFrom,duefilterDateTo)}
            >
              Search Results
            </button>
            </div>
            <div style={{display:'flex', }} >
            <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'20px'}}
               id="downloadButton"  onClick={() => handleDownloadList()} >
             
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
        
          </div>
          
          </div>
          
          </div>
          {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ): (
                  <>
                  <div className='tables' style={{ marginTop: '20px' }}>
        <table  >
            <thead className='table-heading'>
         
                <tr>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'8%',fontFamily:"Inter",paddingLeft:'10px'}}>Month</th>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'10%',fontFamily:"Inter"}}>Opening</th>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'10%',fontFamily:"Inter"}}>Disbursed</th>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'10%',fontFamily:"Inter"}}>Recovered</th>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'11%',fontFamily:"Inter"}}>Closing</th>
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',width:'11%',fontFamily:"Inter"}}>Avg. Irr</th>
                    
                </tr>
            </thead>
            <tbody className='table-body'>
            {duefilterDateFrom && duefilterDateTo ? 
              monthlyDisburse?.map((item, index) => (
          <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
           <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month ? item.month:'-'}</td>
           <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.opening?(<>₹{formatNumberWithCommas(item.opening)}</>):"-"}</td>
          <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.disbursement_amount?(<>₹{formatNumberWithCommas(item.disbursement_amount)}</>):"-"}</td>
          <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.total_recovered?(<>₹{formatNumberWithCommas(item.total_recovered)}</>):'-'}</td>
          <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>  ₹{formatNumberWithCommas( Math.round((item.opening || 0) + (item.disbursement_amount || 0) - (item.total_recovered || 0)))}</td>
          <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.average_irr ? `${(item.average_irr * 100).toFixed(2)}%` : "-"}</td>

           {/* Repeat the above <td> for other values if needed */}
        </tr>
       )):  monthlyDisburse.slice(-12)?.map((item, index) => (
        <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
         <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month}</td>
         <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.opening?(<>₹{formatNumberWithCommas(item.opening)}</>):"-"}</td>
        <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.disbursement_amount?(<>₹{formatNumberWithCommas(item.disbursement_amount)}</>):"-"}</td>
        <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.total_recovered?(<>₹{formatNumberWithCommas(item.total_recovered)}</>):'-'}</td>
        <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>
             ₹{formatNumberWithCommas( Math.round((item.opening || 0) + (item.disbursement_amount || 0) - (item.total_recovered || 0)))}</td>
        <td style={{ fontSize: '0.9vw', width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.average_irr ? `${(item.average_irr * 100).toFixed(2)}%` : "-"}</td>

         {/* Repeat the above <td> for other values if needed */}
      </tr>
       ))
       }
</tbody>
        </table>
        </div>
                  </>)}
        
        </div>
      </div>  

        )
}

export default Monthlydisbursements;