import {
    Col,
    Card,
    Input,
    CardBody,
    Row,

  } from 'reactstrap';
  import { DateRangePicker } from 'rsuite';
  import 'rsuite/dist/rsuite.css';
  import Sidebar from '../Sidebar';
  import Topbar from '../Topbar';
  import search from "../../assets/images/search.png"
  import { useState, useEffect } from 'react';
  import Datetime from 'react-datetime';
  import documentImage from "../../assets/images/document.png"
  import 'react-datetime/css/react-datetime.css';
  import 'bootstrap/dist/css/bootstrap.css';
  import jwt_decode from 'jwt-decode';
  import { useLocation,useNavigate } from 'react-router-dom';
  import CircularProgress from '@mui/material/CircularProgress';
  import Box from '@mui/material/Box';
  import Sort from "../../assets/images/sort.png";
  import download from "../../assets/images/download.png";
  import PaginationTable from '../../components/status/PaginationTable';
  import "./Reports.css"
  import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
  
import { userActions } from '../../actions/user.actions';
import { error } from 'jquery';
import axiosInstance from '../../helpers/axios';
  
  require('moment/locale/fr');
  require('moment/locale/de');
  require('moment/locale/en-gb');
  
  const Reports = () => {
    const ApplicationStatusEnum = {
      InSubmission: 'In Submission',
      InReview: 'In Review',
      InSanction: 'In Sanction',
      InDisbursement: 'In Disbursement',
      PendingDisbursement: "Pending Disbursement",
      Disbursed: 'Disbursed',
      Rejected: 'Rejected',
      Dropped: 'Dropped',
      OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
    };
    const dispatch = useDispatch();
    const reportsstate = useSelector((state) => state.Reports?.res);
    const user = sessionStorage.getItem('user');
    const decode=jwt_decode(user)
    console.log("decode",decode)
    
      
    //filter
    const [filterInstituteName, setFilterInstituteName] = useState('');
    const [applicantName, setApplicantName] = useState('');
    const [filterDateFrom, setFilterDateFrom] = useState('');
    const [filterDateTo, setFilterDateTo] = useState('');
    const [isLoading, setIsLoading] = useState(true); 

    const valid = (current) => {
      return current.isAfter(filterDateFrom);
      
    };
    const [selectedStatus, setSelectedStatus] = useState('');
    //table
    const [tableData, setTableData] = useState([]);
   
    //pagination
    const [currentPage, setcurrentPage] = useState(1);
    const [rowsPerPage, setrowsPerPage] = useState(30);
    const [searchUser, setSearchUser] = useState(null);
    const [clicked,setClicked] = useState(false)
    const urlUsers = `/users/basic-info?searchField=${searchUser?searchUser.length>=5?searchUser:null:null}`;
    const [totalFetchRows, setTotalFetchRows] = useState(null);
    const createUrl = `/excel/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    const clearDateFilter =()=>{
      setClicked(false)
      setFilterDateFrom('')
      setFilterDateTo('')
      const data = {
          
        instituteId:decode.instituteId,
          
          pageNo:currentPage,
          perPage:rowsPerPage,
          fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
          toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo  + " 23:59:59" : undefined,
          status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
         
      };
      axiosInstance.post(createUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("reports resss",res?.data?.data)
        setTableData(res?.data?.data?.applicantDetails)
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage); 
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }).catch((error)=>console.log("error",error))
        }
  
      useEffect(()=>{
       
        if(searchUser)

      {
        axiosInstance.get(urlUsers,{
          headers:{
            Authorization:`Bearer ${user}`
        }}).then((res) => {
          console.log("resssss",res)
          if(res.data.data!==null)
          {

            
            setTableData(res.data.data);
            // setcurrentPage(res.data.data.pagination.currentPage);
            // setTotalFetchRows(res.data.data.pagination.totalRows);
            // setrowsPerPage(res.data.data.pagination.perPage);
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          }
        });
      } 
      else{

       if(selectedStatus == ""){
        const data = {
          
          instituteId:decode.instituteId,
            
            pageNo:currentPage,
            perPage:rowsPerPage,
            fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
            toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo + " 23:59:59" : undefined,
            status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
           
        };
        axiosInstance.post(createUrl,data,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.applicantDetails)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }).catch((error)=>console.log("error",error))
       }else

       {
        if(selectedStatus === "In Sanction"){
    
  
        
          const createUrl = `/integrations/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    
          axiosInstance.get(createUrl,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{
            console.log("reports resss",res?.data?.data)
            setTableData(res?.data?.data?.details)
            setcurrentPage(res?.data?.data?.pagination.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination.totalRows);
            setrowsPerPage(res?.data?.data?.pagination.perPage); 
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          }).catch((error)=>console.log("error",error))
          
        }else if(selectedStatus === "In Disbursement"){
          const urlUsers = `/disbursement/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
      
          axiosInstance.get(urlUsers,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{
            console.log("reports resss",res?.data?.data)
            setTableData(res?.data?.data?.details)
            setcurrentPage(res?.data?.data?.pagination.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination.totalRows);
            setrowsPerPage(res?.data?.data?.pagination.perPage); 
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          }).catch((error)=>console.log("error",error))
        }
        else if(selectedStatus === "In Process"){
          
          console.log("indd process")
          const urlUsers = `/admin/application/in-progress?perPage=${10}&pageNo=${currentPage}&sortOrder=-1`;
      
          axiosInstance.get(urlUsers,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{
            console.log("reports resss",res?.data?.data)
            setTableData(res?.data?.data?.applicantDetails)
            setcurrentPage(res?.data?.data?.pagination.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination.totalRows);
            setrowsPerPage(res?.data?.data?.pagination.perPage); 
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          }).catch((error)=>console.log("error",error))
        }else if(selectedStatus === "Disbursed"){
          console.log("indd sub")
  const data = {
    
          instituteId:decode.instituteId,
            
            pageNo:currentPage,
            perPage:rowsPerPage,
            fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
            toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo + " 23:59:59" : undefined,
            status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
           
        };
  
        
        const createUrl = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;
  
        axiosInstance.post(createUrl,data,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.details)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }).catch((error)=>console.log("error",error))
        
        }
        else{
          console.log("indd sub")
  const data = {
    
          instituteId:decode.instituteId,
            
            pageNo:currentPage,
            perPage:rowsPerPage,
            fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
            toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo + " 23:59:59" : undefined,
            status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
           
        };
  
        
        const createUrl = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;
  
        axiosInstance.post(createUrl,data,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.applicantDetails)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }).catch((error)=>console.log("error",error))
        
        }
       }
}


      },[selectedStatus,currentPage, searchUser])
      console.log("clicked",clicked)

    const handleDateFilterApi = (startDate, endDate) => {
      setClicked(!clicked)
      if (startDate === "" || endDate === "") alert('Enter Date Range to Filter');
      else {
        setIsLoading(true);
        const urlFilterUsers = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;

        const dataFilter = {
          pageNo:currentPage,
          perPage:rowsPerPage,
          fromDate:startDate,
          toDate:endDate+" "+"23:59:59",
          status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,

        }
        axiosInstance.post(`${urlFilterUsers}`,dataFilter,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res) => {
          if (selectedStatus === "Disbursed" || selectedStatus === "In Sanction" || selectedStatus === "In Disbursement") {
            setTableData(res?.data?.data?.details);
          } else {
            setTableData(res?.data?.data?.applicantDetails);
          }          
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error)=>console.log("error",error));
      }
    };

 
    
const handleDownloadList=()=>{
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = String(currentDate.getFullYear());

  const formattedDate = `${day}-${month}-${year}`;
  document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"

  const downloadTemplateUrl=`/excel/download`
  const downloadData={
      instituteId: decode.instituteId,
      status:selectedStatus,
      fromDate:filterDateFrom,
      toDate:filterDateTo+" "+"23:59:59"
  }
  axiosInstance.post(downloadTemplateUrl,downloadData, {
    responseType:'arraybuffer',
    headers:{
      Authorization:`Bearer ${user}`

  }
}).then((res)=>{
  setTimeout(() => {
    document.getElementById("downloadButton").disabled=false
    document.getElementById("downloadButton").innerText="Export";
    document.getElementById("downloadButton").style.paddingLeft = "30px";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft= "150px";
    document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
    document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
    // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
    document.getElementById("downloadButton").style.backgroundSize  ='15px' 
    document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

  }, 2000);
  const blob = new Blob([res.data], { type: "application/xlsx", });
  const url= window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href =url
  link.download = `${formattedDate}_${selectedStatus}.xlsx`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
    
  
).catch((error)=>{
  alert("Error Downloading")
  document.getElementById("downloadButton").innerText="Error..";
  document.getElementById("downloadButton").style.backgroundColor="#D22129";
  setTimeout(() => {
    document.getElementById("downloadButton").disabled=false
    document.getElementById("downloadButton").innerText="Export";
    document.getElementById("downloadButton").style.paddingLeft = "30px";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft= "150px";
    document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
    document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
    // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
    document.getElementById("downloadButton").style.backgroundSize  ='15px' 
    document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'


  }, 1000);
})
}   


    const paginate = (pageNumber) => {
      console.log("selected status",selectedStatus)
    
      setcurrentPage(pageNumber);
      console.log("page number",currentPage,pageNumber)
      
  
     
//       console.log("indd paginate sub")
//       if(selectedStatus === "In Sanction"){
    
  
        
//         const createUrl = `/integrations/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
  
//         axios.get(createUrl,{
//           headers:{
//             Authorization:`Bearer ${user}`
//         }
//         }).then((res)=>{
//           console.log("reports resss",res?.data?.data)
//           setTableData(res?.data?.data?.details)
//           setcurrentPage(res?.data?.data?.pagination.currentPage);
//           setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//           setrowsPerPage(res?.data?.data?.pagination.perPage); 
//         }).catch((error)=>console.log("error",error))
        
//       }else if(selectedStatus === "In Disbursement"){
//         const urlUsers = `/disbursement/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    
//         axiosInstance.get(urlUsers,{
//           headers:{
//             Authorization:`Bearer ${user}`
//         }
//         }).then((res)=>{
//           console.log("reports resss",res?.data?.data)
//           setTableData(res?.data?.data?.details)
//           setcurrentPage(res?.data?.data?.pagination.currentPage);
//           setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//           setrowsPerPage(res?.data?.data?.pagination.perPage); 
//         }).catch((error)=>console.log("error",error))
//       }
//       else if(selectedStatus === "In Process"){
        
//         console.log("indd process")
//         const urlUsers = `/admin/application/in-progress?perPage=${10}&pageNo=${currentPage}&sortOrder=-1`;
    
//         axiosInstance.get(urlUsers,{
//           headers:{
//             Authorization:`Bearer ${user}`
//         }
//         }).then((res)=>{
//           console.log("reports resss",res?.data?.data)
//           setTableData(res?.data?.data?.applicantDetails)
//           setcurrentPage(res?.data?.data?.pagination.currentPage);
//           setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//           setrowsPerPage(res?.data?.data?.pagination.perPage); 
//         }).catch((error)=>console.log("error",error))
//       }else{
//         console.log("indd sub")
// const data = {
  
//         instituteId:decode.instituteId,
          
//           pageNo:currentPage,
//           perPage:rowsPerPage,
//           fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
//           toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo : undefined,
//           status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
         
//       };

      
//       const createUrl = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;

//       axios.post(createUrl,data,{
//         headers:{
//           Authorization:`Bearer ${user}`
//       }
//       }).then((res)=>{
//         console.log("reports resss",res?.data?.data)
//         setTableData(res?.data?.data?.applicantDetails)
//         setcurrentPage(res?.data?.data?.pagination.currentPage);
//         setTotalFetchRows(res?.data?.data?.pagination.totalRows);
//         setrowsPerPage(res?.data?.data?.pagination.perPage); 
//       }).catch((error)=>console.log("error",error))
      
//       }

    };

  

    const handleStatusChange = () => {
     
      console.log("statuses",selectedStatus)
      if(selectedStatus === "In Sanction"){
    
  
        
        const createUrl = `/integrations/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
  
        axiosInstance.get(createUrl,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.details)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
        }).catch((error)=>console.log("error",error))
        
      }else if(selectedStatus === "In Disbursement"){
        const urlUsers = `/disbursement/applications?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    
        axiosInstance.get(urlUsers,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.details)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
        }).catch((error)=>console.log("error",error))
      }
      else if(selectedStatus === "In Process"){
        
        console.log("indd process")
        const urlUsers = `/admin/application/in-progress?perPage=${10}&pageNo=${currentPage}&sortOrder=-1`;
    
        axiosInstance.get(urlUsers,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          console.log("reports resss",res?.data?.data)
          setTableData(res?.data?.data?.applicantDetails)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
        }).catch((error)=>console.log("error",error))
      }else{
        console.log("indd sub")
const data = {
  
        instituteId:decode.instituteId,
          
          pageNo:currentPage,
          perPage:rowsPerPage,
          fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
          toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo + " 23:59:59" : undefined,
          status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
         
      };

      
      const createUrl = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;

      axiosInstance.post(createUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("reports resss",res?.data?.data)
        setTableData(res?.data?.data?.applicantDetails)
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage); 
      }).catch((error)=>console.log("error",error))
      
      }
      
    };
    // useEffect(() => {
 
    //   handleStatusChange();
    // }, [selectedStatus]);
    // console.log("select",selectedStatus)
    // console.log("table",tableData)
    // const filteredTableData = tableData?.filter((item) => {
    //   return selectedStatus === '' || item.status === selectedStatus;
    // });
  
  
    const handleSearch=()=>{
      if(searchUser){
        console.log("insideee if",searchUser)
        axiosInstance.get(urlUsers,{
          headers:{
            Authorization:`Bearer ${user}`
        }}).then((res) => {
          console.log("resssss",res)
          if(res.data.data!==null)
          {
    
            
            setTableData(res.data.data);
            // setcurrentPage(res.data.data.pagination.currentPage);
            // setTotalFetchRows(res.data.data.pagination.totalRows);
            // setrowsPerPage(res.data.data.pagination.perPage);
          }
        });
      }else{
        console.log("insideee else")
        const data = {
          
          instituteId:decode.instituteId,
            
            pageNo:currentPage,
            perPage:rowsPerPage,
            fromDate: filterDateFrom !== null && filterDateFrom !== undefined && filterDateFrom !== "" ? filterDateFrom : undefined,
            toDate: filterDateTo !== null && filterDateTo !== undefined && filterDateTo !== "" ? filterDateTo + " 23:59:59" : undefined,
            status: selectedStatus !== null && selectedStatus !== undefined && selectedStatus !== "" ? selectedStatus : undefined,
           
        };
        const createUrl = `/excel/applications?status=${selectedStatus}&perPage=${rowsPerPage}&pageNo=${currentPage}`;

        axiosInstance.post(createUrl,data,{
          headers:{
            Authorization:`Bearer ${user}`
        }
        }).then((res)=>{
          setTableData(res?.data?.data?.applicantDetails)
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage); 
        })
      }
   
  }
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }
  
  const [isOpen,setIsOpen]=useState(true)

    if (tableData) {
     
      return (
        <Card className='card' style={{overflowX: 'hidden',display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
          
          <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
          
          <div style={{width:isOpen?'78%':'98%', overflow:"auto",marginLeft:isOpen?"22%":'0%',}}>
            <Topbar/>
          
         
         
           <div>
            <p style={{display:'flex',alignItems:'center'}}><img src={documentImage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'18px',color:'#D32028'}}>Reports</span></p>
            
           </div>
           
  
  
          <Row>
            <div style={{ display: 'flex', flex: 'wrap'}}>
              <Col sm="12" md="10" lg="3">
              <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
              <Input
                type="text"
                id="userSearch"
                placeholder={'Applicant Name'
                }                onChange={(event) => setSearchUser(event.target.value)}
                style={{
                   fontSize: '0.8vw',
                  paddingLeft: '26px',
                  height: '23px',
                  fontFamily: 'Inter-Medium',
                  backgroundImage: `url(${search})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px',
                  paddingRight: '5px', 
                  borderColor:'#cccccc',
                  borderStyle:'solid',
                  borderRadius:"5px",
                  borderWidth:'1px',
                  height:'34px',
                  marginTop:'11px'
                }}
                

              />
            </div>
              </Col>
             
              <Col >
                <div style={{ height: '7px', marginRight: '10px' }}>
                  <Datetime
                   closeOnSelect="false"
                    id="fromDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    onChange={(event) =>{ 
                      if (event && event.format) {
                      setFilterDateFrom(event.format('DD MMM YYYY'));
                    }else{
                      setFilterDateFrom('');
                      clearDateFilter()
                    }
                    }}
  
                    locale="en-gb"
                    inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter-Medium',fontSize:"14px"} }}
                  />
                </div>
              </Col>
              <Col >
                <div style={{ height: '7px', marginRight: '10px' }}>
                  <Datetime
                   closeOnSelect="false"
                    id="toDate"
                    dateFormat="DD MMM YYYY"
                    isValidDate={valid}
                    timeFormat={false}
                    locale="en-gb"
                    onChange={(event) => {
                      if (event && event.format) {
                        setFilterDateTo(event.format('DD MMM YYYY'));
                      } else {
                        setFilterDateTo(''); 
                        clearDateFilter()
                      }
                    }}
  
                    inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter-Medium',fontSize:"14px"} }}
                  />
                </div>
              </Col>
            
              <Col >
              <div> 
              <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
                  onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
                 
                >
                  Search Results
                </button>
                </div>
              </Col>
              <Col>
              <Input type="select" id="status" style={{width:'200px'}}  onChange={(e) => {setSelectedStatus(e.target.value);setcurrentPage("1");document.getElementById("userSearch").value=null;setSearchUser(null)}}>
              <option value="" >Select Status</option>
                    
                    <option value={ApplicationStatusEnum.InSubmission}>In Submission</option>
                    <option value={ApplicationStatusEnum.InReview}>In Review</option>
                    <option value="In Process">In Process</option>
                    <option value="In Sanction">In Sanction</option>
                    <option value="In Disbursement">In Disbursement</option>
                    <option value="Pending Disbursement">Pending Disbursement</option>
                    <option value="Disbursed">Disbursed</option>
                    <option value="Dropped">Dropped</option>
                    <option value="Rejected">Rejected</option>
                    <option value="On Hold">On Hold</option>
                   
                    </Input>
              </Col>
              {/* <Col>
              <div>
                <button style={{width:'100px',backgroundColor:'gray',height:'36.5px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium"}} onClick={() => handleClearFilter()} color="primary">
                  Clear Filter
                </button>
                </div>
              </Col> */}
              <Col >
              <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex',marginLeft:'150px', alignItems: 'center',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium"}} id="downloadButton" onClick={() => handleDownloadList()} >
             <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
              </button>
              </Col>
              

            </div>
          </Row>
  
           {isLoading ? (
             <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
             <CircularProgress />
           </Box>
           ):(
            <>
            <div className='tables' style={{ marginTop: '20px', width: '100%' }}>
              
              <table>
                <thead className='table-heading' >
                  <tr >
                    <th style={{  fontSize: '0.9vw',cursor:'pointer',fontFamily:"Inter",width:'8em',borderTopLeftRadius:'8px' }} id="appId">
                    <span style={{marginLeft:'10px'}}></span> Appl. Id 
                    </th>
                    <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10%'}} id="appname">
                     <span  style={{paddingLeft:'10px'}}></span> Appl. Name
                    </th>
                    { decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'8em',whiteSpace:'nowrap',paddingLeft:'10px'}} id="appname">
                     Student Name
                    </th>}
                    <th  style={{  fontSize: '0.9vw',fontFamily:"Inter" }} >
                  <span style={{marginLeft:'10px'}}>Mobile</span> 
                    </th>
                    <th  style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'12em'}}>
                      <span  style={{paddingLeft:'10px'}}>Email</span>
                    </th>
                    <th className="thead-text" style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'10em'}} >
                      <span  style={{paddingLeft:'10px'}}>{ decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' ? 'Client': 'Course'}</span>
                    </th>
                    <th className="thead-text" style={{  fontSize: '0.9vw',cursor:'pointer',fontFamily:"Inter",width:'14em' }}>
                     <span style={{marginLeft:'15px'}}></span>  Date
                    </th>
                    
                    <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'8em' }}>
                    <span  style={{paddingLeft:'10px'}}>Amount</span>
                    </th>
                    <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'8em'}}>
                    Tenure
                    </th>
                    <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter",whiteSpace:'nowrap',width:'8em'}}>
                    PF.Amt
                    </th>
                    <th className="thead-text" style={{  fontSize: '0.9vw',fontFamily:"Inter",borderTopRightRadius:'8px',width:'5em'}}>
                    <span style={{marginLeft:'15px'}}>Status</span> 
                    </th>
                   
                  </tr>
                </thead>
               
                {tableData?.map((item,index) => {
                 const applicant = applicantName.toLowerCase();
                 const currentApplicantName = (item.name || '').toLowerCase(); 
                 const applicantMobile = applicantName.toLowerCase()
                 const currentApplicantPhone = (item.mobile || '').toLowerCase(); 

                if (currentApplicantName.indexOf(applicant) !== -1 || currentApplicantPhone.indexOf(applicantMobile) !== -1)
                  return (
                    <tbody className='table-body' key={item.applicationId}>
                      <tr className="table-row" style={{ lineHeight: '14px' }}
                      >
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td>
                         <span  style={{cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter' ,color:'#101828'}}> {item.applicationId}</span>
                        </td>:<td style={{  fontSize: '0.9vw', fontWeight: '400',fontFamily:"Inter" ,color:'#101828'}}>
                          {item.applicationId}
                        </td>
                        }
                         {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                             fontSize: '0.9vw',
                            fontFamily:"Inter",
                           fontWeight:"500",color:'#101828'
                          }}
                         
                        >
                           <span title={item.name} style={{fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw', lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'10em'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{ fontSize: '0.9vw',
                            fontFamily:"Inter",fontWeight:"500",color:'#101828', lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'10em'}}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                             <div className="forInstituteDetails">
                                <span>{item.coapplicantEmail}</span>
                                <span>{item.coapplicantMobile}</span>
                                </div>

                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            
                            
                            fontFamily:"Inter",
                          }}
                        >
                          <span title={item.name} style={{maxWidth:'10em',fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{maxWidth:'10em',fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw', lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>}
                        { decode?.clientId!=='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361' && <td title={item.studentName}>
                          <span style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'8em' }}>{item.studentName ? item.studentName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "-"}</span>
                        </td>}
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter" }}>
                          {item.mobile ? item.mobile : "-"}
                        </td>
                        <td title={item.email}>
                         <span style={{width:'12em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",maxWidth:'7em',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{item.email ? item.email : "-"}</span> 
                        </td>
                        <td title={item.courseName}>
                         <span style={{width:'10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{item.courseName ? item.courseName : "-"}</span> 
                        </td>
                        <td
                          title={item.applicationDate?.substr(10, 14)}
                          style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter" ,width:'14em',whiteSpace:'nowrap'}}
                        >
                          {item.applicationDate?.substr(0, 10) ? moment(item.applicationDate?.substr(0, 10)).format('DD MMM YY') : "-" }

                        </td>
                        
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",maxWidth:'6em'  }}>
                         {item.courseFees ? ( <>&#8377;{formatNumberWithCommas(item.courseFees)}</>) : ( '-')}
                        </td>
                        <td style={{ fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",maxWidth:'6em' }}>
                          {item.tenure ? item.tenure : "-"}
                        </td>
                        <td style={{  fontSize: '0.9vw', textAlign: 'center',color:'#667085',fontFamily:"Inter",maxWidth:'6em' }}>
                          {item.processingFee ? <>&#8377;{item.processingFee}</> : "-"}
                        </td>
                        <td title={item.notes}>
                        <span style={{fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' ,width:'5em' }}>{item.status ? item.status : "-"}</span>  
                        </td>
                      </tr>
                      
                    
                    </tbody>
                  );
              })}
              </table>
              </div>
              {searchUser ? null : <>  {totalFetchRows ? ( filterDateFrom && filterDateTo && totalFetchRows <= 30 || selectedStatus &&  totalFetchRows <= 30?(
               <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{totalFetchRows}{' '}
               records
             </div>
            ):( <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage}{' '}
            records
          </div>)
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
            <PaginationTable
              startPage="1"
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              currStatus={selectedStatus}
            /></>}
              
           
            </>

           )}
            
            
          
          </div>
        </Card>
      );
    }
    return <div>....Loading</div>;
  };
  
  export default Reports;